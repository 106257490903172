import React, { useState } from 'react'

// Estilização
import { Container, Page } from './styles'

// Componente de card para novos projetos
import CardNovosProjetos from '../../../components/CardNovosProjetos'

// mascara

// icones
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { BsSortAlphaDown, BsSortAlphaUpAlt } from 'react-icons/bs'

// Chamadas da API
import { Badge } from '@material-ui/core'
import { useCallback } from 'react'
import SimpleBackdrop from '../../../components/Backdrop'
import { Pagination } from '../../../components/Pagination'
import { GET_PROJECTS } from '../../../services/endpoints'

const projectStatus = {
  paid: 'paid',
  waitingPayment: 'waiting_payment',
  refused: 'refused',
}

function NovosProjetos() {
  const [pages, setPages] = useState(0)
  const [rows, setRows] = useState(10)
  const [status, setStatus] = useState(projectStatus.paid)

  const [listprojects, setListProjects] = useState({})

  const [clientNameOrder, setClientNameOrder] = useState(null)
  const [dateOrder, setDateOrder] = useState('DESC')

  const [showbackdrop, setShowbackdrop] = useState(false)
  const [abaSelected, setAbaSelected] = useState(1)

  const [filter, setFilter] = useState({
    type: '',
    value: '',
    dig: '',
  })

  const [listidx, setListidx] = useState(6)

  const [order, setOrder] = useState('primeiros')

  const handleOrder = (type, value) => {
    if (type === 'name') {
      setClientNameOrder(value)
      setDateOrder(null)
    }
    if (type === 'date') {
      setDateOrder(value)
      setClientNameOrder(null)
    }
  }

  const handleChangeRow = useCallback((event) => {
    setRows(parseInt(event.target.value, 10))
    setPages(0)
  }, [])

  const handleChangePage = useCallback((_, newPage) => {
    setPages(newPage)
  }, [])

  React.useEffect(() => {
    GET_PROJECTS(setListProjects, setShowbackdrop, {
      pages,
      rows,
      status,
      dateOrder,
      clientNameOrder,
      hasDeadline: status === projectStatus.waitingPayment ? false : undefined,
      hasStages: status === projectStatus.waitingPayment ? false : undefined,
      hasResponsibles: status === projectStatus.waitingPayment ? false : undefined,
    })
  }, [status, rows, pages, dateOrder, clientNameOrder])

  return (
    <Container>
      <Page>
        <header>
          <h1>Novos Projetos</h1>
          <span>
            Confira os novos projetos, analise, visualize, aceite e delegue um
            responsável.
          </span>
        </header>
        <content>
          <div className="div__options__project">
            <Badge
              overlap="circular"
              badgeContent={
                status === projectStatus.paid &&
                Object.keys(listprojects).length !== 0
                  ? listprojects?.total
                  : undefined
              }
              style={{ marginTop: '-0.4rem' }}
              color="secondary"
            >
              <button
                className={
                  abaSelected === 1
                    ? 'button_project btn__aba_selected'
                    : 'button_project'
                }
                onClick={() => {
                  setStatus(projectStatus.paid)
                  setAbaSelected(1)
                }}
              >
                <AssignmentOutlinedIcon /> Projetos Pagos
              </button>
            </Badge>
            <Badge
              overlap="circular"
              badgeContent={
                status === projectStatus.waitingPayment &&
                Object.keys(listprojects).length !== 0
                  ? listprojects?.total
                  : undefined
              }
              style={{ marginTop: '-0.4rem' }}
              color="secondary"
            >
              <button
                className={
                  abaSelected === 2
                    ? 'button_project btn__aba_selected'
                    : 'button_project'
                }
                onClick={() => {
                  setStatus(projectStatus.waitingPayment)
                  setAbaSelected(2)
                }}
              >
                <AccessTimeIcon /> Aguardando Pagamento
              </button>
            </Badge>
            <Badge
              overlap="circular"
              badgeContent={
                status === projectStatus.refused &&
                Object.keys(listprojects).length !== 0
                  ? listprojects.total
                  : undefined
              }
              style={{ marginTop: '-0.4rem' }}
              color="secondary"
            >
              <button
                className={
                  abaSelected === 3
                    ? 'button_project btn__aba_selected'
                    : 'button_project'
                }
                onClick={() => {
                  setStatus(projectStatus.refused)
                  setAbaSelected(3)
                }}
              >
                <HighlightOffIcon /> Projetos Recusados
              </button>
            </Badge>
          </div>
          <p className="p__subtitle_aba_selected">
            {abaSelected === 1
              ? `Lista de todos os projetos pagos aguardando aprovação.`
              : abaSelected === 2
              ? 'Lista de todos os projetos lançados na plataforma aguardando pagamento.'
              : 'Lista de projetos recusados (pagos e não pagos).'}
          </p>
          <div className="div__fieldFilter">
            <div className="div__filters">
              {/* autocomplete */}

              {/* fim de autocomplete */}
            </div>
            <div className="div__order">
              <button
                className={order === 'AZ' ? 'btn__esq btn__ativ' : 'btn__esq'}
                onClick={() => {
                  order === 'AZ' ? setOrder('') : setOrder('AZ')
                  handleOrder('name', 'ASC')
                }}
              >
                <BsSortAlphaDown style={{ fontSize: '1.4rem' }} />
              </button>
              <button
                className={order === 'ZA' ? 'btn_meio btn__ativ' : 'btn_meio'}
                onClick={() => {
                  order === 'ZA' ? setOrder('') : setOrder('ZA')
                  handleOrder('name', 'DESC')
                }}
              >
                <BsSortAlphaUpAlt style={{ fontSize: '1.4rem' }} />
              </button>

              <button
                className={
                  order === 'primeiros' ? 'btn_meio btn__ativ' : 'btn_meio'
                }
                onClick={() => {
                  order === 'primeiros' ? setOrder('') : setOrder('primeiros')
                  handleOrder('date', 'DESC')
                }}
              >
                Recentes
              </button>
              <button
                className={
                  order === 'ultimos' ? 'btn__dir btn__ativ' : 'btn__dir'
                }
                onClick={() => {
                  order === 'ultimos' ? setOrder('') : setOrder('ultimos')
                  handleOrder('date', 'ASC')
                }}
              >
                Antigos
              </button>
            </div>
          </div>
          <div className="div__deck">
            {Object.keys(listprojects).length !== 0 &&
              listprojects?.data.map((proj, idx) => (
                <CardNovosProjetos
                  key={idx}
                  proj={proj}
                  idx={idx}
                  abaSelected={abaSelected}
                  atualizar={async () => {
                    GET_PROJECTS(setListProjects)
                  }}
                />
              ))}
          </div>
        </content>

        {!!listprojects?.data ? (
          <div
            className="div__viewMore"
            onClick={() => setListidx(listidx + 6)}
          >
            <Pagination
              page={pages}
              total={listprojects?.total}
              rowsPerPage={rows}
              handleChangeRowsPerPage={handleChangeRow}
              handleChangePage={handleChangePage}
            />
          </div>
        ) : listprojects?.total === 0 ? (
          <div style={{ margin: '2rem 1rem' }}>Nenhum projeto encontrado</div>
        ) : listprojects?.total === 0 && !filter.type ? (
          <div style={{ margin: '2rem 1rem' }}>Nenhum projeto cadastrado</div>
        ) : (
          <div style={{ margin: '2rem 1rem' }}>
            {status === projectStatus.refused
              ? 'Sem mais projetos recusados'
              : 'Sem mais novos projetos'}
          </div>
        )}
      </Page>
      {showbackdrop && <SimpleBackdrop />}
    </Container>
  )
}

export default NovosProjetos
