import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 4rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem 1rem;
`;

export const Page = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  /* border-radius: 0.5rem; */
  min-height: 200px;

  header {
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    position: relative;

    button {
      position: absolute;
      right: 2rem;
      bottom: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.25rem 0.75rem;
      border: 1px solid lightgray;
      border-radius: 0.25rem;
      color: gray;
      cursor: pointer;
      svg {
        margin-right: 0.5rem;
      }
      :hover {
        color: var(--primary2);
      }
    }

    h1 {
      font-weight: 800;
      font-size: 1.5rem;
      color: var(--primary2);
      text-transform: uppercase;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
    span {
      opacity: 0.9;
    }

    ::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 1rem;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    }
  }

  content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0rem 2rem;

    .div__oneRow {
      gap: 2rem;
      /* background-color: red; */
      /* align-items: center; */
      justify-content: center;
      display: flex;
      flex-direction: row;
      margin: 0rem 2rem;
      width: 100%;
      /* flex-wrap: wrap; */
    }
    .div__oneCol {
      gap: 2rem;
      /* background-color: red; */
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin: 1.75rem 0rem;
      flex-wrap: wrap;
      height: 100%;

      .div__graph_bar {
        position: relative;
        .top_button {
          position: absolute;
          top: 0rem;
          right: 0px;

          button {
            background-color: lightgray;
            color: var(--white);
            border: 0rem;
            cursor: pointer;

            :hover {
              background-color: var(--primary2);
              color: var(--white);
              box-shadow: 0px 10px 10px rgba(var(--black_rgb), 0.25);
            }
          }
          :hover {
            transition: 0.2s;
            top: -0.2rem;
          }
        }

        flex: 2;
        /* padding: 0rem 1rem; */

        /* background: rgba(var(--black_rgb), 0.05); */
        h3 {
          color: var(--primary2);
          font-weight: 400;
          text-align: left;
          margin: 1rem 1rem;
          margin-top: 0rem;
          /* background: red; */
          padding: 0rem;
        }
      }
    }
  }

  .div__conteudo {
    margin: 0rem 2rem;
    margin-bottom: 2rem;
    /* background: red; */

    .div__row {
      display: flex;
      flex-direction: row;

      strong {
        margin-right: 0.5rem;
      }
      small {
        margin: 0rem 0.5rem;
      }
    }

    h4 {
      /* background-color: red; */
      text-transform: uppercase;
      margin: 1rem 0rem;
      font-size: 0.8rem;
      color: rgba(var(--black_rgb), 0.5);
      font-weight: 400;
      width: 100%;
      /* padding: 0rem 2rem; */
      flex: 1;
      /* margin-left: 0rem; */
      text-align: left;

      ::after {
        display: block;
        content: '';
        margin: 0.5rem 0rem;
        border-bottom: 1px dotted rgba(var(--black_rgb), 0.5);
      }
    }

    p {
      flex: 1;
      margin: 0rem;
      font-size: 0.9rem;
    }
    label {
      font-weight: 600;
    }
    .div__oneRow {
      display: flex;
      text-align: left;
      justify-content: space-between;
      /* background: red; */
    }

    .btn__finish {
      background-image: linear-gradient(
        to bottom right,
        var(--primary2),
        var(--primary)
      );
      padding: 0.5rem 2rem;
      color: #fff;
      font-family: 'Poppins', sans-serif;
      border: 0rem;
      font-size: 0.8rem;
      float: left;
      margin: 1rem 0rem;
      cursor: pointer;
      border-radius: 2rem;

      :hover {
        background-image: linear-gradient(
          to bottom left,
          var(--primary2),
          var(--primary)
        );
      }
    }
  }
  @media screen and (max-width: 675px) {
    .div__oneCol {
      gap: 0rem !important;
      flex-direction: column !important;
      /* background: red; */
    }
    .div__oneRow {
      display: flex;
      margin: 0rem !important;
      padding: 0rem !important;
      flex-direction: column !important;
    }

    .div__graph_bar {
      margin-top: -4rem;
      margin-bottom: 3rem;
      padding: 1rem 0rem;
      width: 100%;
    }
  }
`;

export const Card1 = styled.div`
  min-height: 100px;
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 250px;
  min-width: 200px;
  justify-content: center;
  flex-direction: column;
  padding: 0.19rem;
  font-family: 'Poppins', sans-serif;
  position: relative;

  .top_button {
    position: absolute;
    top: -1rem;
    right: 0px;

    button {
      background-color: var(--primary2);
      color: var(--white);
      border: 0rem;
      cursor: pointer;

      :hover {
        box-shadow: 0px 10px 10px rgba(var(--black_rgb), 0.25);
      }
    }
    :hover {
      transition: 0.2s;
      top: -1.2rem;
    }
  }

  h4 {
    text-transform: uppercase;
    margin: 0rem;
    color: var(--white);
    /* color: ${(props) =>
      props.idx <= 2 ? 'var(--primary2)' : 'var(--white)'}; */
    font-size: 0.75rem;
    text-align: left;
    font-weight: 400;
  }
  p {
    font-weight: 600;
    margin: 0rem;
    font-size: 2.5rem;
    color: var(--white);
  }

  label {
    font-size: 0.7rem;
    color: var(--white);
    text-align: left;
    border-top: 1px solid rgba(var(--white_rgb), 0.2);
    padding: 0.2rem 0.75rem;
  }

  @media screen and (max-width: 675px) {
    width: 100%;
    max-width: 1000px;
  }
`;

export const Card2 = styled.div`
  /* min-height: 90px; */
  flex: 1;
  /* max-width: 150px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  padding: 0rem 0.5rem;
  width: 100%;
  min-width: 300px;
  min-height: 234px;
  background: var(--gelo);
  /* box-shadow: 0px 0px 5px rgba(var(--black_rgb), 0.1); */
  /* border: 1px solid rgba(var(--black_rgb), 0.05); */
  h4 {
    text-transform: uppercase;
    margin: 0rem;
    color: var(--primary2);
    /* color: ${(props) =>
      props.idx <= 2 ? 'var(--primary2)' : 'var(--white)'}; */
    font-size: 0.9rem;
    text-align: left;
    font-weight: 400;
    width: 100%;
    padding: 1rem;
    margin-bottom: -1rem;
  }
  p {
    font-weight: 600;
    margin: 0rem;
    font-size: 2.5rem;
    color: var(--white);
  }

  label {
    font-size: 0.7rem;
    color: var(--white);
    text-align: left;
    border-top: 1px solid rgba(var(--white_rgb), 0.2);
    padding: 0.2rem 0.75rem;
  }

  .div__conteudo {
    margin: 0.5rem 0rem;

    .graph {
      margin: auto;
      text-align: center;
      width: 100px;
      height: 100px;
      line-height: 60px;
      text-transform: uppercase;
      font-family: sans-serif;
      text-decoration: none;
      font-size: 14px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .div__legend {
      /* background: red; */
      text-align: left;
      flex: 1;

      ul {
        width: 100%;
        padding: 0rem;
        list-style: none;

        li {
          margin: 0rem;
          width: 100%;
          color: rgba(var(--primary2_rgb), 1);
          font-size: 0.7rem;
          margin-left: 0.8rem;
          font-family: 'Poppins', sans-serif;

          ::before {
            content: 'O ';
            color: var(--primary2);
            font-weight: bolder;
            display: inline-block;
            width: 1rem;
            margin-left: -1rem;
          }
          & + li {
            color: gray;
            ::before {
              content: 'O ';
              color: gray;
            }
          }
        }
      }
    }
    .div__in_graph {
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      color: var(--primary2);
      margin-bottom: -1rem;
      /* align-items: center; */
      /* margin: 0rem; */
      /* padding: 0rem; */
      /* background: red; */

      .counter {
        font-size: 2rem;
        font-weight: 600;
      }
      .total {
        font-size: 0.7rem;
        /* margin: 0rem !important; */
        margin-top: -2.5rem;
        margin-right: -0.75rem;
      }
    }
    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      fill: transparent;
      stroke-width: 0.75rem;
      stroke: lightgray;
    }
    .graph svg.cor {
      stroke: var(--primary2);
      stroke-dasharray: 251.32;
      stroke-dashoffset: calc(
        251.32 - 2.5132 * ${(props) => props.graphPercent}
      );
      transform: rotate(-90deg);
      transform-origin: center;
    }
  }

  @media screen and (max-width: 675px) {
    padding: 0rem 0.74rem;
    margin: 0rem;

    .div__conteudo {
      .graph svg.cor {
        stroke: var(--primary2);
        stroke-dasharray: 251.32;
        stroke-dashoffset: calc(
          251.32 - 2.5132 * ${(props) => props.graphPercent}
        );
        transform: rotate(-90deg);
        transform-origin: center;
      }
    }
  }
`;

export const Popmenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.3rem;
  gap: 0.25rem;
  button {
    padding: 0.5rem 0.5rem;
    background: var(--white);
    border: 1px solid rgba(var(--black_rgb), 0.1);
    box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
    color: green;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  .destaque {
    background-color: var(--primary2) !important;
    color: var(--white) !important;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    input,
    select {
      padding: 0.5rem 0.5rem;
      background: var(--white);
      border: 1px solid rgba(var(--black_rgb), 0.1);
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
      color: green;
      border-radius: 0.5rem;
      cursor: pointer;
      outline-color: var(--primary2);
    }
  }
`;
