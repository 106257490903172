import axios from 'axios';
import { TOKEN_KEY } from './Auth';

const api = axios.create({
  baseURL: 'https://api.tellarsolucoes.com.br',
});

if (localStorage.getItem(TOKEN_KEY)) {
  api.defaults.headers['Authorization'] = `Bearer ${
    JSON.parse(localStorage.getItem(TOKEN_KEY)).token
  }`;
}

export default api;
