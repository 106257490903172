import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  font-family: 'Poppins', sans-serif;

  h1 {
    /* text-transform: uppercase; */
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0rem;
    color: var(--primary2);
  }
  span {
    font-size: 0.9rem;
  }
  h4 {
    /* background-color: red; */
    text-transform: uppercase;
    margin: 1rem 0rem;
    font-size: 0.8rem;
    color: rgba(var(--black_rgb), 0.5);
    font-weight: 400;
    width: 100%;
    /* padding: 0rem 2rem; */
    flex: 1;
    /* margin-left: 0rem; */
    text-align: left;

    ::after {
      display: block;
      content: '';
      margin: 0.5rem 0rem;
      border-bottom: 1px dotted rgba(var(--black_rgb), 0.5);
    }
  }

  p {
    flex: 1;
    margin: 0rem;
    font-size: 0.9rem;
  }
  label {
    font-weight: 600;
  }
  .div__oneRow {
    display: flex;
  }

  input,
  select {
    outline-color: var(--primary2);
    padding: 0.75rem 0.5rem;
    border: 1px solid rgba(var(--black_rgb), 0.3);
    border-radius: 0.5rem;
    margin: 0.5rem 0.25rem;
    width: 100%;
    z-index: 0;
  }

  .MuiBadge-colorSecondary {
    color: #fff !important;
    background-color: #f50057;
  }

  @media screen and (max-width: 675px) {
    .div__oneRow {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const FielButtons = styled.div`
  display: flex;
  /* background: red; */
  align-items: center;

  .btn__retornar {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    width: 100%;
    max-width: 180px;
    height: 35px;
    background-color: transparent;
    border: 1px solid var(--primary2);
    border-radius: 1.4rem;
    color: var(--primary2);
    /* margin-top: 1rem; */
    margin-right: 0.75rem;
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
    }
  }
  .btn__register {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;

    width: 320px;
    height: 45px;
    background-color: var(--primary2);
    border: 0px;
    border-radius: 1.4rem;
    color: var(--white);
    /* margin-top: 1rem; */
    /* margin-bottom: 0.75rem; */
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
      filter: brightness(1.4);
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column-reverse;
    flex: 1;

    .btn__retornar,
    .btn__register {
      /* margin: auto; */
      width: 100% !important;
      max-width: none;
      margin: 0.5rem;
      font-size: 1rem;
    }
  }
`;

export const Conteudo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif !important;

  img {
    max-width: 100px;
    margin: 2rem auto;
  }
  .div__one_row {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 350px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    margin: 0.25rem auto;

    input {
      padding: 0.75rem 1rem;
      width: 100%;
      outline-color: var(--primary2);
      margin: 0rem auto !important;
      border: 0rem;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0rem;
      background-color: transparent;
      padding: 0rem 1rem;
      cursor: pointer;
      svg {
        font-size: 2rem;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  ul {
    margin: 1rem 2rem;
    /* background: red; */
    font-size: 0.85rem;
    flex: 3;
  }
  @media screen and (max-width: 675px) {
    ul {
      margin: 1rem 1rem;
      padding: 0rem;
      /* background: red; */
      font-size: 0.85rem;
    }
  }
`;
