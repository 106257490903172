import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  font-family: 'Poppins', sans-serif;

  h1 {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0rem;
    color: var(--primary2);
  }

  .filter_label {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .warning {
    padding: 0.4rem;
    vertical-align: top;
    text-align: justify;

    background-color: rgba(var(--warn_rgb), 0.4);
    border: 2px solid rgba(var(--warn_rgb), 1.0);
    border-radius: 5px;

    svg {
      font-size: 1.2rem;
      margin-bottom: -5px;
      margin-right: 4px;
    }
  }

  .filter_date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    
    .date_inputs {
      display: flex;
      justify-content: flex-start;
      margin: auto 0px;
      width: 100%;
      gap: 0.5rem;

     .date_input {
        display: flex;
        flex-wrap: nowrap;
        border: 1px solid rgba(var(--black_rgb), 0.3);
        border-radius: 0.5rem;
        overflow: hidden;

        input {
          outline-color: var(--primary2);
          padding: 0.75rem 0.5rem;
          border: none;
          width: 100%;
          z-index: 0;
          transition: all 0.15s ease;
          
          &:hover {
            opacity: 0.5;
          }
        }

        @media screen and (max-width: 675px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          margin-bottom: 1rem;
        }

        .pos_field {
          background-color: #e1e1e1;
          z-index: 1;
          padding: 0rem 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
        }
      }

    }
  }

  .filter_options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;

    .options {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
`;

export const FielButtons = styled.div`
  display: flex;
  align-items: center;

  .btn_return,
  .btn_download {
    outline: none;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    width: 100%;
    max-width: 180px;
    height: 35px;
    background-color: transparent;
    border: 1px solid var(--primary2);
    border-radius: 1.4rem;
    color: var(--primary2);
    margin-right: 0.75rem;
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
    }
  }
  
  .btn_download {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    background-color: var(--primary2);
    color: var(--white);
    transition: all 0.15s ease-in-out;

    &:disabled {
      opacity: 0.5;
      cursor: progress;
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column-reverse;
    flex: 1;

    .btn_return,
    .btn_download {
      width: 100% !important;
      max-width: none;
      margin: 0.5rem;
      font-size: 1rem;
    }
  }
`;
