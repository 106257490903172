import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { mask } from 'remask';

import { Container, FielButtons, Conteudo } from './styles';
import Imgtrocarsenha from '../../assets/images/icons/trocar_senha.png';

import api from '../../services/api';
import { toast } from 'react-toastify';

import { useUserData } from '../../context/UserDataContext';

import {
  SET_NEW_USERS_TELLAR,
  EDIT_USER_TELLAR,
} from '../../services/endpoints';

export default function Dialogonewuser(props) {
  const { company } = useUserData();
  const [open, setOpen] = React.useState(true);
  const [dados, setDados] = React.useState(
    props.dados
      ? props.dados
      : {
          cpf: '',
          full_name: '',
          role: '',

          email: '',
          password: '',
          confirm_password: '',

          is_client: false,
          is_integrator: false,

          is_tellar_admin: '',
        }
  );
  const [show, setShow] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const handleChange = (e) => {
    if (e.target.name === 'number') {
      setDados({ ...dados, [e.target.name]: mask(e.target.value, '9999999') });
    } else if (e.target.name === 'cpf') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '999.999.999-99'),
      });
    } else if (e.target.name === 'landline') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '(99) 9999-9999'),
      });
    } else if (e.target.name === 'telephone') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '(99) 99999-9999'),
      });
    } else if (e.target.name === 'cnpj') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '99.999.999/9999-­99'),
      });
    } else {
      setDados({ ...dados, [e.target.name]: e.target.value });
    }
  };

  const setNewuser = async (event) => {
    event.preventDefault();
    if (dados.password !== dados.confirm_password)
      return toast.warn(
        'Verifique se os campos referente a senha estão preenchidos corretamente.'
      );

    let dadosReq = {
      loginData: {
        email: dados.email,
        password: dados.password,
      },
      personData: {
        cpf: dados.cpf,
        full_name: dados.full_name,
        role: dados.role,
        is_client: false,
        is_integrator: false,
      },
      is_tellar_admin: dados.is_tellar_admin,
      company_id: company?.id,
    };

    await SET_NEW_USERS_TELLAR(
      dadosReq,
      props.setShowbackdrop,
      props.atualizar,
      handleClose
    );
  };

  const editUserTellar = async (event) => {
    event.preventDefault();

    let dadosReq = {
      user_id: dados?.user_id,
      fullname: dados?.full_name,
      cpf: dados?.cpf,
      role: dados?.role,
      email: dados?.email,
      is_tellar_admin: dados?.is_tellar_admin,
    };

    EDIT_USER_TELLAR(
      dadosReq,
      props.setShowbackdrop,
      props.atualizar,
      handleClose
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 99999,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={props.edit ? editUserTellar : setNewuser}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Container>
                <header>
                  <h1>
                    {props.edit ? 'Editar' : 'Novo'} Usuário{' '}
                    {props.edit ? props.dados.full_name : ''}
                  </h1>
                  <span>
                    Preencha corretamente os campos abaixo para
                    {props.edit
                      ? ' editar os dados do usuário'
                      : ' adicionar um novo usuário'}
                    .
                  </span>
                  <content>
                    <h4>Dados pessoais e acesso</h4>
                    <div className="div__oneRow">
                      <input
                        type="text"
                        name="full_name"
                        value={dados.full_name}
                        placeholder="Nome completo"
                        style={{
                          flex: 2,
                        }}
                        onChange={handleChange}
                        required
                      />
                      <input
                        type="text"
                        name="cpf"
                        value={dados.cpf}
                        placeholder="CPF"
                        style={{
                          flex: 1,
                        }}
                        onChange={handleChange}
                        required
                      />
                      <input
                        type="text"
                        name="role"
                        value={dados.role}
                        placeholder="Cargo"
                        style={{
                          flex: 1,
                        }}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="div__oneRow">
                      {/* <input
                      type="text"
                      name="telephone"
                      value={dados.telephone}
                      placeholder="Celular/Whatsapp"
                      style={{
                        flex: 1,
                      }}
                      onChange={handleChange}
                    /> */}

                      <input
                        type="text"
                        name="email"
                        value={dados.email}
                        placeholder="E-mail"
                        style={{
                          flex: 2,
                        }}
                        onChange={handleChange}
                        disabled={props.edit}
                        required
                      />
                      <select
                        required
                        name="is_tellar_admin"
                        style={{ flex: 1 }}
                        value={dados.is_tellar_admin}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Permissão
                        </option>
                        <option value={true}>Administrativo</option>
                        <option value={false}>Supervisor</option>
                      </select>
                    </div>

                    {!props.edit && (
                      <Conteudo>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 3,
                          }}
                        >
                          <img src={Imgtrocarsenha} />
                          <div className="div__one_row">
                            <input
                              autoFocus
                              type={show ? 'text' : 'password'}
                              placeholder="Digite a nova senha"
                              value={dados.password}
                              onChange={(a) =>
                                setDados({ ...dados, password: a.target.value })
                              }
                              required
                            />{' '}
                            <button
                              onClick={() => setShow(!show)}
                              type="button"
                            >
                              {!show ? (
                                <VisibilityOffIcon />
                              ) : (
                                <RemoveRedEyeIcon />
                              )}
                            </button>
                          </div>
                          <div className="div__one_row">
                            <input
                              type="password"
                              placeholder="Confirme a senha"
                              value={dados.confirm_password}
                              onChange={(a) =>
                                setDados({
                                  ...dados,
                                  confirm_password: a.target.value,
                                })
                              }
                              required
                            />{' '}
                          </div>
                        </div>
                        <ul>
                          <li>Senha deve conter no mínimo 6 caracteres</li>
                          <li>
                            Para uma senha forte, é aconselhável que contenha
                            números, letras e caracteres especiais
                          </li>
                          <li>
                            Sua senha e credenciais de acesso são de uso
                            pessoal, evite informar a terceiros
                          </li>
                        </ul>
                      </Conteudo>
                    )}
                  </content>
                </header>
              </Container>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <FielButtons>
              <button
                onClick={handleClose}
                className="btn__retornar"
                color="primary"
                type="button"
              >
                Cancelar
              </button>
              <button className="btn__register" type="submit">
                {props.edit ? 'Aplicar alterações' : 'Cadastrar novo usuário'}
              </button>
            </FielButtons>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
