import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LockIcon from '@material-ui/icons/Lock';

// config
import { SITE_NAME } from '../../config';

import { Link, useParams } from 'react-router-dom';

import Logo from '../../assets/images/logo/logo1.png';

// contexto
import { useAuth } from '../../context/AuthContext';

// person
import { useUserData } from '../../context/UserDataContext';

import { Conteudo } from './styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: window.innerWidth < 675 ? '100%' : drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: 9999,
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    display: window.innerWidth < 675 ? 'none' : 'block',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  customlink: {
    '& a': {
      color: 'rgba(0,0,0,1)',
      textDecoration: 'none',
      '&:hover': {
        color: 'var(--primary2)',
      },
    },
  },
  cabinfo: {
    padding: '0.9rem 1rem',
    flex: 1,
    float: 'right',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      background: 'rgba(255,255,255,0.1)',
      cursor: 'default',
    },
  },
  cablogoff: {
    // margin:'auto 1.5rem',
    padding: 'auto 1.5rem',
    width: '50px',
    float: 'right',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: 'var(--primary2) !important',
      color: 'var(--white)',
    },
  },
  desabilitado: {
    fontSize: '0.9rem',
    color: 'rgba(0,0,0,0.5)',
    marginBottom: '-0.1rem',
    float: 'right',
  },
  itemdomenu: {
    minHeight: window.innerWidth < 675 && '5rem',
    '& 	.MuiListItemText-primary': {
      fontSize: '14px ',
      fontFamily: "'Poppins', sans-serif !important",
    },
    '& .MuiListItemIcon-root': {
      '& svg': { width: '20px !important' },
      color: 'rgba(0,0,0, 0.3)',
      marginLeft: '0.5rem',
    },
    '&:hover': {
      borderLeft: '3px solid var(--primary2)',
      transition: '0.2s',
    },
  },
  selecionado: {
    borderLeft: '3px solid var(--primary2)',
    color: 'var(--primary2)',
    transition: '0.2s',
    minHeight: window.innerWidth < 675 && '5rem',
    '& 	.MuiListItemText-primary': {
      fontSize: '14px ',
    },
    '& .MuiListItemIcon-root': {
      '& svg': { width: '35px !important' },
      color: 'var(--primary2)',
    },
  },
}));

export default function MiniDrawer(props) {
  const { user, signout } = useAuth();
  const { person } = useUserData();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(
    window.innerWidth > 675 ? true : false
  );

  const handleDrawerOpen = () => {
    setOpen(true);
    props.abrir(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.abrir(false);
  };

  let { id2 } = useParams();

  return (
    <div className={classes.root} id="paginacaoMenu">
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{
          backgroundColor: '#f1f1f1',
          boxShadow: '0px 0px 10px rgba(0,0,0,0)',
          borderBottom: '1px dashed rgba(0,0,0,0.1)',
          borderWidth: '1px',
        }}
      >
        <Toolbar
          style={{
            padding: '0rem',
            minHeight: window.innerWidth < 675 && '5rem',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
            style={{
              margin: '0rem 0.8rem',
              backgroundColor: 'var(--primary2)',
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap={window.innerWidth > 675 ? false : true}
            style={{
              width: '100%',
              margin: '0rem 1rem',
              textAlign: 'left',
              color: 'var(--primary2)',
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            {SITE_NAME}
          </Typography>
          <div
            style={{
              width: '40%',
              // maxWidth: '300px',
              textAlign: 'right',
              display: `${window.innerWidth > 675 ? 'flex' : 'none'}`,
              flexDirection: 'row',
              // backgroundColor: '#245C9D !important',
              color: 'var(--primary2)',
            }}
          >
            <div
              className={classes.cabinfo}
              style={{
                backgroundImage:
                  'linear-gradient(to left, rgba(var(--black_rgb),0.00) , #f1f1f1)',
              }}
            >
              {person?.full_name}
              <br />
              {user?.email}
            </div>
            <div
              className={classes.cablogoff}
              onClick={() => signout()}
              style={{ backgroundColor: 'rgba(var(--black_rgb),0.05)' }}
            >
              <ExitToAppIcon />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Conteudo>
          <div
            className={classes.toolbar}
            style={{ minHeight: window.innerWidth < 675 && '5rem' }}
          >
            <a
              href="#teste"
              target="_blank"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '.25rem 0',
              }}
              rel="noreferrer"
            >
              <img
                src={Logo}
                style={{
                  display: window.innerWidth > 675 ? 'none' : 'block',
                  width: '70%',
                  transform: 'scale(1)',
                  marginRight: '-1rem',
                }}
              />
            </a>
            <IconButton onClick={handleDrawerClose}>
              {!open ? (
                <ChevronRightIcon style={{ color: 'transparent' }} />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <img
            src={Logo}
            style={{
              width: window.innerWidth > 675 ? (open ? '100%' : '0%') : '60%',
              // transform: 'scale(1.6)',
              marginBottom: '-1rem',
              display: window.innerWidth > 675 ? 'block' : 'none',
            }}
          />

          {/* <Divider /> */}
          <List className={classes.customlink}>
            {window.innerWidth > 675 ? (
              <ListItem>
                <ListItemText
                  secondary={
                    <div
                      style={{
                        opacity: '90%',
                        textAlign: 'center',
                        marginTop: !open && '1rem',
                      }}
                    >
                      Menu
                    </div>
                  }
                />
              </ListItem>
            ) : (
              <ListItem
                button
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <ListItemIcon>
                  <AccountCircleIcon
                    style={{ fontSize: '3rem', marginLeft: '0.5rem' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      <span style={{ opacity: '90%' }}>Seja bem vindo</span>
                      <br />
                      <b>{props.user.name}</b>
                      <br />
                      <button
                        onClick={() => signout()}
                        style={{
                          border: '0px',
                          width: '8rem',
                          height: '2.5rem',
                          margin: '1rem auto',
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: '1.1rem',
                        }}
                      >
                        Sair
                      </button>
                    </>
                  }
                />
              </ListItem>
            )}
            {/* <Divider /> */}

            <Link to={user.user.is_tellar_admin && '/dashboard'}>
              <ListItem
                button
                onClick={() => window.innerWidth < 675 && setOpen(!open)}
                className={
                  id2 === 'dashboard' ? classes.selecionado : classes.itemdomenu
                }
                style={{
                  filter: !user.user.is_tellar_admin && 'grayScale(1)',
                }}
              >
                <ListItemIcon>
                  <DashboardIcon />{' '}
                </ListItemIcon>
                <ListItemText>
                  Dashboard{' '}
                  {!user.user.is_tellar_admin && open && (
                    <LockIcon className={classes.desabilitado} />
                  )}
                </ListItemText>
              </ListItem>
            </Link>

            <Link to={'/integradores'}>
              <ListItem
                button
                onClick={() => window.innerWidth < 675 && setOpen(!open)}
                className={
                  id2 === 'integradores'
                    ? classes.selecionado
                    : classes.itemdomenu
                }
              >
                <ListItemIcon>
                  <BusinessIcon />{' '}
                </ListItemIcon>
                <ListItemText primary={'Integradores'} />
              </ListItem>
            </Link>

            <Link to={user.user.is_tellar_admin && '/novos-projetos'}>
              <ListItem
                button
                onClick={() => window.innerWidth < 675 && setOpen(!open)}
                className={
                  id2 === 'novos-projetos'
                    ? classes.selecionado
                    : classes.itemdomenu
                }
                style={{
                  filter: !user.user.is_tellar_admin && 'grayScale(1)',
                }}
              >
                <ListItemIcon>
                  <ListAltOutlinedIcon />
                  {/* <PostAddIcon /> */}
                  {/* <FiberNewOutlinedIcon /> */}
                </ListItemIcon>
                <ListItemText>
                  Novos Projetos{' '}
                  {!user.user.is_tellar_admin && open && (
                    <LockIcon className={classes.desabilitado} />
                  )}
                </ListItemText>
              </ListItem>
            </Link>

            <Link to={user.user.is_tellar_admin && '/novos-creditos'}>
              <ListItem
                button
                onClick={() => window.innerWidth < 675 && setOpen(!open)}
                className={
                  id2 === 'novos-creditos'
                    ? classes.selecionado
                    : classes.itemdomenu
                }
                style={{
                  filter: !user.user.is_tellar_admin && 'grayScale(1)',
                }}
              >
                <ListItemIcon>
                  <CreditCardIcon />
                  {/* <PostAddIcon /> */}
                  {/* <FiberNewOutlinedIcon /> */}
                </ListItemIcon>
                <ListItemText>
                  Novos créditos{' '}
                  {!user.user.is_tellar_admin && open && (
                    <LockIcon className={classes.desabilitado} />
                  )}
                </ListItemText>
              </ListItem>
            </Link>

            <Link to={user.user.is_tellar_admin && '/relatorios'}>
              <ListItem
                button
                onClick={() => window.innerWidth < 675 && setOpen(!open)}
                className={
                  id2 === 'relatorios'
                    ? classes.selecionado
                    : classes.itemdomenu
                }
                style={{
                  filter: !user.user.is_tellar_admin && 'grayScale(1)',
                }}
              >
                <ListItemIcon>
                  <FormatListBulletedIcon />{' '}
                </ListItemIcon>
                <ListItemText>
                  Relatórios{' '}
                  {!user.user.is_tellar_admin && open && (
                    <LockIcon className={classes.desabilitado} />
                  )}
                </ListItemText>
              </ListItem>
            </Link>

            <Link to={user.user.is_tellar_admin && '/orcamentos'}>
              <ListItem
                button
                onClick={() => window.innerWidth < 675 && setOpen(!open)}
                className={
                  id2 === 'orcamentos'
                    ? classes.selecionado
                    : classes.itemdomenu
                }
                style={{
                  filter: !user.user.is_tellar_admin && 'grayScale(1)',
                }}
              >
                <ListItemIcon>
                  <MonetizationOnOutlinedIcon />
                </ListItemIcon>
                <ListItemText>
                  Orçamentos{' '}
                  {!user.user.is_tellar_admin && open && (
                    <LockIcon className={classes.desabilitado} />
                  )}
                </ListItemText>
              </ListItem>
            </Link>

            <Link to={user.user.is_tellar_admin && '/cupons'}>
              <ListItem
                button
                onClick={() => window.innerWidth < 675 && setOpen(!open)}
                className={
                  id2 === 'cupons' ? classes.selecionado : classes.itemdomenu
                }
                style={{
                  filter: !user.user.is_tellar_admin && 'grayScale(1)',
                }}
              >
                <ListItemIcon>
                  <LocalOfferOutlinedIcon />
                </ListItemIcon>
                <ListItemText>
                  Cupons{' '}
                  {!user.user.is_tellar_admin && open && (
                    <LockIcon className={classes.desabilitado} />
                  )}
                </ListItemText>
              </ListItem>
            </Link>

            <Link to={user.user.is_tellar_admin && '/usuarios'}>
              <ListItem
                button
                onClick={() => window.innerWidth < 675 && setOpen(!open)}
                className={
                  id2 === 'usuarios' ? classes.selecionado : classes.itemdomenu
                }
                style={{
                  filter: !user.user.is_tellar_admin && 'grayScale(1)',
                }}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText>
                  {' '}
                  Usuários{' '}
                  {!user.user.is_tellar_admin && open && (
                    <LockIcon className={classes.desabilitado} />
                  )}
                </ListItemText>
              </ListItem>
            </Link>

            <Link to="/logins">
              <ListItem
                button
                onClick={() => window.innerWidth < 675 && setOpen(!open)}
                className={
                  id2 === 'logins'
                    ? classes.selecionado
                    : classes.itemdomenu
                }
              >
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary={<>Logins</>} />
              </ListItem>
            </Link>

            <Link to="/configuracoes">
              <ListItem
                button
                onClick={() => window.innerWidth < 675 && setOpen(!open)}
                className={
                  id2 === 'configuracoes'
                    ? classes.selecionado
                    : classes.itemdomenu
                }
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={<>Configurações</>} />
              </ListItem>
            </Link>
            <Divider />
          </List>
        </Conteudo>
      </Drawer>
    </div>
  );
}
