import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { Container, Page } from './styles'

// icones
import Excelicon from '../../../assets/images/icons/excel_icon.png'

// import config
import { listetapas } from '../../../config'

import SimpleBackdrop from '../../../components/Backdrop'
import { Pagination } from '../../../components/Pagination'
import ModalDownloadReport from '../../../components/ModalDownloadReport';
import { GET_PROJECTS } from '../../../services/endpoints'

function Reports() {
  const [showbackdrop, setShowbackdrop] = useState(false)
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(10)

  const [filterValues, setFilterValues] = useState({
    value: '',
    start_date: '',
    end_date: '',
  })

  // "new_projects","open_projects",  "completed_projects", "project_data_interval", "specific_project","specific_integrator", "proccess"
  const [filterType, setFilterType] = useState('')
  const [downloadReport, setDownloadReport] = useState(false);

  const [showData, setShowData] = useState([
    {
      id: 'client_name',
      name: 'Cliente',
      value: true,
    },
    {
      id: 'created_at',
      name: 'Data inicial',
      value: true,
    },
    {
      id: 'finished_at',
      name: 'Data final',
      value: true,
    },
    {
      id: 'situation',
      name: 'Situação',
      value: true,
    },
    {
      id: 'stage',
      name: 'Processo',
      value: true,
    },
    {
      id: 'description',
      name: 'Descrição',
      value: true,
    },
    {
      id: 'social_reason',
      name: 'Integrador',
      value: false,
    },
    {
      id: 'client_cpf',
      name: 'CPF',
      value: false,
    },
    {
      id: 'client_cnpj',
      name: 'CNPJ',
      value: false,
    },
    {
      id: 'dealer_state',
      name: 'Estado',
      value: false,
    },
    {
      id: 'dealer_name',
      name: 'Concessionária',
      value: false,
    },
    {
      id: 'breaker_type',
      name: 'Tipo de disjuntor',
      value: false,
    },
    {
      id: 'breaker_value',
      name: 'Valor do disjuntor',
      value: false,
    },
    {
      id: 'coordinates',
      name: 'Coordenadas',
      value: false,
    },
    {
      id: 'total_project_power',
      name: 'Potência',
      value: false,
    },
    {
      id: 'amount_panels',
      name: 'Qtd. de placas',
      value: false,
    },
    {
      id: 'panel_power',
      name: 'Pot. das placas',
      value: false,
    },
    {
      id: 'panel_model',
      name: 'Mod. das placas',
      value: false,
    },
  ])
  const [listProjects, setListProjects] = useState([])

  const handleChangeRow = useCallback((event) => {
    setRows(parseInt(event.target.value, 10))
    setPage(0)
  }, [])

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage)
  }, [])

  const handleReportType = useCallback((event) => {
    const value = event.target.value
    setFilterType(value)
  }, []);

  const handleFilterInputs = useCallback(() => {
    switch (filterType) {
      case 'new_projects':
        return {
          hasDeadline: false,
          hasStages: false,
          hasResponsibles: false,
        };
      case 'open_projects':
        return {
          hasDeadline: false,
          hasStages: true,
          hasResponsibles: true,
          isFinished: false,
        };
      case 'completed_projects':
        return {
          isFinished: true,
        };
      case 'project_data_interval':
        if (filterValues.start_date && filterValues.end_date) {
          return {
            startDate: filterValues.start_date,
            endDate: filterValues.end_date,
          };
        }
      // eslint-disable-next-line
      case 'specific_project':
        return {
          clientName: filterValues.value.toLowerCase(),
        };
      case 'specific_integrator':
        return {
          companyName: filterValues.value.toLowerCase(),
        };
      case 'proccess':
        return {
          stageTitle: filterValues.value.toLowerCase(),
        };
      default:
        return {
          status: null,
          clientNameOrder: null,
          dateOrder: null,
          hasDeadline: null,
          hasStages: null,
          hasResponsibles: null,
          isFinished: null,
          startDate: null,
          endDate: null,
          clientName: null,
          companyName: null,
          stageTitle: null,
        };
    }
  }, [filterType, filterValues])

  const handleFilterValue = useCallback((event) => {
    setFilterValues({
      ...filterValues,
      [event.target.name]: event.target.value,
    })
  }, [filterValues]);

  const recent = useCallback((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at)
  }, []);

  const changeFlexValue = useCallback((value) => {
    let aux = 1
    switch (value) {
      case 0:
        aux = 3
        break
      case 1:
        aux = 1
        break
      case 2:
        aux = 1
        break
      case 3:
        aux = 0.5
        break
      case 4:
        aux = 3
        break
      case 5:
        aux = 3
        break
      case 6:
        aux = 2
        break
      case 7:
        aux = 2
        break
      case 8:
        aux = 2
        break
      case 13:
        aux = 2
        break

      default:
        aux = 1
    }

    return aux
  }, []);

  const getStatusProgress = useCallback((value) => {
    switch (true) {
      case !!value?.toLowerCase().includes(listetapas[0]?.toLowerCase()):
        return ((1 * 100) / 6).toFixed(2)
      case !!value?.toLowerCase().includes(listetapas[1]?.toLowerCase()):
        return ((2 * 100) / 6).toFixed(2)
      case !!value?.toLowerCase().includes(listetapas[2]?.toLowerCase()):
        return ((3 * 100) / 6).toFixed(2)
      case !!value?.toLowerCase().includes(listetapas[3]?.toLowerCase()):
        return ((4 * 100) / 6).toFixed(2)
      case !!value?.toLowerCase().includes(listetapas[4]?.toLowerCase()):
        return ((5 * 100) / 6).toFixed(2)
      case !!value?.toLowerCase().includes(listetapas[5]?.toLowerCase()):
        return ((6 * 100) / 6).toFixed(2)
      default:
        return 0
    }
  }, [])

  const changeDataListValue = useCallback((project, value, index) => {
    let div
    switch (value) {
      case 'Cliente':
        div = (
          <div
            key={index}
            style={{ flex: changeFlexValue(index), textAlign: 'left' }}
          >
            {project?.client_name}
          </div>
        )
        break
      case 'Data inicial':
        div = (
          <div
            key={index}
            style={{ flex: changeFlexValue(index), textAlign: 'left' }}
          >
            {new Date(project?.created_at).toLocaleString('pt-BR', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })}
          </div>
        )
        break
      case 'Data final':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.finished_at
              ? new Date(project?.finished_at).toLocaleString('pt-BR', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })
              : ' - '}
          </div>
        )
        break
      case 'Situação':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {getStatusProgress(project?.stages.sort(recent)[0]?.title)}%
          </div>
        )
        break
      case 'Processo':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.stages.sort(recent)[0]?.title || ' - '}
          </div>
        )
        break
      case 'Descrição':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.stages.sort(recent)[0]?.status.sort(recent)[0]
              ?.description || ' - '}
          </div>
        )
        break
      case 'Integrador':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.company.social_reason || ' - '}
          </div>
        )
        break
      case 'CPF':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.client_cpf || ' - '}
          </div>
        )
        break
      case 'CNPJ':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.client_cnpj || ' - '}
          </div>
        )
        break
      case 'Estado':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.dealer?.state || ' - '}
          </div>
        )
        break
      case 'Concessionária':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.dealer?.name || ' - '}
          </div>
        )
        break
      case 'Tipo de disjuntor':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.breaker_type || ' - '}
          </div>
        )
        break
      case 'Valor do disjuntor':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.breaker_value || ' - '}
          </div>
        )
        break
      case 'Coordenadas':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            Lat {(project?.latitude).slice(0, 10) || ' - '}
            <br />
            Lng {project?.longitude.slice(0, 10) || ' - '}
          </div>
        )
        break
      case 'Potência':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.total_project_power || ' - '}kWp
          </div>
        )
        break
      case 'Qtd. de placas':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.amount_panels || ' - '}
          </div>
        )
        break
      case 'Pot. das placas':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.panel_power || ' - '}kWp
          </div>
        )
        break
      case 'Mod. das placas':
        div = (
          <div style={{ flex: changeFlexValue(index), textAlign: 'left' }}>
            {project?.panel_model || ' - '}
          </div>
        )
        break

      default:
        div = ''
    }
    return div
  }, [changeFlexValue, getStatusProgress, recent]);

  React.useEffect(() => {
    const filterObj = handleFilterInputs()

    GET_PROJECTS(setListProjects, setShowbackdrop, {
      ...filterObj,
      page,
      rows,
      dateOrder: 'DESC',
    })
  }, [filterType, filterValues, page, rows]) // eslint-disable-line

  return (
    <Container>
      <Page>
        <header>
          <h1>Relatórios</h1>
          <span>Gerencie os relatórios do sistema.</span>
        </header>
        <content>
          <form>
            <div className="div__fieldFilter">
              <div className="div__filters">
                <select
                  onChange={handleReportType}
                  name="type"
                  value={filterType}
                  style={{ maxWidth: 200 }}
                >
                  <option value="" selected>
                    {' '}
                    Nenhum filtro{' '}
                  </option>
                  <option value="new_projects">Novos projetos</option>
                  <option value="open_projects">Projetos em aberto</option>
                  <option value="completed_projects">
                    Projetos concluídos
                  </option>
                  <option value="project_data_interval">
                    Intervalo de datas
                  </option>
                  <option value="specific_integrator">
                    Buscar por integrador
                  </option>
                  <option value="specific_project">Buscar por cliente</option>
                  <option value="proccess">Etapa</option>
                </select>

                {filterType === 'proccess' && (
                  <select
                    onChange={handleFilterValue}
                    name="value"
                    value={filterValues.value}
                    style={{ maxWidth: 250 }}
                  >
                    <option value="" selected>
                      {' '}
                      Selecionar etapa{' '}
                    </option>
                    {listetapas.map((etapa) => (
                      <option value={etapa}>{etapa}</option>
                    ))}
                  </select>
                )}

                {filterType === 'project_data_interval' && (
                  <>
                    <input
                      type="date"
                      name="start_date"
                      value={filterValues.start_date}
                      onChange={handleFilterValue}
                      style={{ maxWidth: 150 }}
                      max={filterValues.end_date && filterValues.end_date}
                    />
                    <div className="pos_field">Início</div>
                    <input
                      type="date"
                      name="end_date"
                      value={filterValues.end_date}
                      onChange={handleFilterValue}
                      style={{ maxWidth: 150 }}
                      min={filterValues.start_date && filterValues.start_date}
                    />
                    <div className="pos_field">Fim</div>
                  </>
                )}

                {(filterType === 'specific_project' ||
                  filterType === 'specific_integrator') && (
                  <input
                    type="text"
                    name="value"
                    value={filterValues.value}
                    onChange={handleFilterValue}
                    style={{ maxWidth: 250 }}
                    placeholder={
                      filterType === 'specific_project'
                        ? 'Nome do cliente'
                        : filterType === 'specific_integrator'
                        ? 'Nome do integrador'
                        : '-'
                    }
                  />
                )}

                <input
                  placeholder={
                    filterType === 'name'
                      ? 'Digite o nome'
                      : filterType === 'cpf'
                      ? 'Digite o CPF'
                      : filterType === 'cnpj'
                      ? 'Digite o CNPJ'
                      : ' - '
                  }
                  value={filterValues.value}
                  disabled={!filterType ? true : false}
                  onChange={handleFilterValue}
                  style={{
                    display:
                      filterType === 'name' ||
                      filterType === 'cpf' ||
                      filterType === 'cnpj'
                        ? 'block'
                        : 'none',
                  }}
                />

              </div>
              <button
                type="button"
                className="btn__download"
                onClick={() => setDownloadReport(true)}
              >
                <img src={Excelicon} alt="Excel Icon" />
                Relatório
              </button>
            </div>
          </form>
          <details>
            <summary>Escolher ítens apresentados</summary>
            <div className="div__field_selected_info">
              {showData.map((showdt, idx) => (
                <label key={idx + showdt.name}>
                  <input
                    type="checkbox"
                    checked={showdt.value}
                    onChange={() => {
                      let aux = showData
                      let count = aux.filter((a) => a.value === true)
                      if (!showdt.value === true && count.length > 5)
                        toast.warn(
                          'No máximo 5 atributos podem ser selecionados'
                        )
                      if (!showdt.value === true && count.length > 5) return

                      aux[idx].value = !showdt.value
                      setShowData([...aux])
                    }}
                  />
                  {showdt.name}
                </label>
              ))}
            </div>
          </details>

          <div className="div__list_content">
            <div className="div__row_list_content">
              <div
                style={{
                  fontWeight: '600',
                  width: '60px',
                  textAlign: 'left',
                  wordWrap: 'break-word',
                }}
              >
                Controle
              </div>
              {showData.map(
                (showdt, idx) =>
                  showdt.value && (
                    <div
                      style={{
                        fontWeight: '600',
                        textAlign: 'left',
                        wordWrap: 'break-word',
                        flex: changeFlexValue(idx),
                      }}
                    >
                      {showdt.name}
                    </div>
                  )
              )}
            </div>

            {listProjects?.data?.map((project) => (
              <div key={project.id} className="div__row_list_content">
                <div
                  style={{
                    width: '60px',
                    textAlign: 'left',
                    wordWrap: 'break-word',
                  }}
                >
                  {project?.id}
                </div>

                {showData.map(
                  (showdt, idx2) =>
                    showdt.value &&
                    changeDataListValue(project, showdt.name, idx2)
                )}
              </div>
            ))}

            <div className="div__pagination">
              <Pagination
                page={page}
                total={listProjects?.total}
                rowsPerPage={rows}
                handleChangeRowsPerPage={handleChangeRow}
                handleChangePage={handleChangePage}
              />
            </div>
            {listProjects?.data?.total === 0 && (<div className="div__return_text_find">
              Nenhum projeto encontrado
            </div>)}
          </div>
        </content>
      </Page>
      {showbackdrop && <SimpleBackdrop />}
      {downloadReport && (
        <ModalDownloadReport close={() => setDownloadReport(false)} />
      )}
    </Container>
  )
}

export default Reports
