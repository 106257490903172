import { useCallback, useEffect, useState } from 'react'

import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { toast } from 'react-toastify'

import SimpleBackdrop from '../../../components/Backdrop'
import { Pagination } from '../../../components/Pagination'
import { GET_LOGIN_DATA } from '../../../services/endpoints'
import { useDebounce } from '../../../hooks';
import ModalEditLoginData from '../../../components/ModalEditLoginData';
import api from '../../../services/api';

import { Container, Page } from './styles'

export default function Logins() {
  const [showbackdrop, setShowbackdrop] = useState(false)
  const [editLoginData, setEditLoginData] = useState(null);
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10)
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search ?? "", 1000);

  const [loginData, setLoginData] = useState([])

  const handleChangeRow = useCallback((event) => {
    setRows(parseInt(event.target.value, 10))
    setPage(0)
  }, [setRows, setPage]);

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage)
  }, [setPage]);

  const handleSearchChange = useCallback((event) => {
    setSearch(event.target.value)
  }, [setSearch]);

  const handleEditLoginData = useCallback(async (loginDataIndex) => {
    const currentLoginData = loginData.data[loginDataIndex];
    if (currentLoginData) {
      if (!currentLoginData.validated_user) {
        setShowbackdrop(true);
        return api.put('/login-data/validate', { email: currentLoginData.email })
          .then(async (res) => {
            toast.success('Login validado com sucesso');
            loginData.data[loginDataIndex] = res.data;
          })
          .catch((error) => {
            console.error(error);
            if (error?.response?.data?.message) {
              toast.error(error.response.data.message);
              return;
            }

            toast.error('Erro ao validar login');
          })
          .finally(() => setShowbackdrop(false));
      }
      setEditLoginData({
        index: loginDataIndex,
        ...currentLoginData,
      });
    }
  }, [loginData]);

  const changeFlexValue = useCallback((value) => {
    switch (value) {
      case 0:
        return 3;
      case 1:
      case 2:
        return 1;
      case 3:
        return 0.5;
      case 4:
      case 5:
        return 3;
      case 6:
      case 7:
      case 8:
      case 13:
        return 2;
      default:
        return 1;
    }
  }, []);

  useEffect(() => {
    GET_LOGIN_DATA(setLoginData, setShowbackdrop, {
      search: debouncedSearch,
      page,
      rows,
    })
  }, [debouncedSearch, page, rows]) // eslint-disable-line

  return (
    <Container>
      <Page>
        <header>
          <h1>Logins</h1>
          <span>Gerencie os dados de login dos usuários do sistema.</span>
        </header>
        <content>
          <div className="div__fieldFilter">
            <div className="div__filters">
              <input
                type="text"
                name="value"
                value={search}
                onChange={handleSearchChange}
                style={{ maxWidth: 250 }}
                placeholder='Buscar por e-mail, nome ou CPF'
              />
            </div>
          </div>
          <div className="div__list_content">
            <div className="div__row_list_content">
              <div
                style={{
                  width: '60px',
                  textAlign: 'left',
                  wordWrap: 'break-word',
                }}
              >
                <strong>ID</strong>
              </div>

              <div
                style={{
                  flex: changeFlexValue(0),
                  textAlign: 'left',
                }}
              >
                <strong>E-mail</strong>
              </div>

              <div
                style={{
                  flex: changeFlexValue(0),
                  textAlign: 'left',
                }}
              >
                <strong>Nome</strong>
              </div>

              <div
                style={{
                  flex: changeFlexValue(0),
                  textAlign: 'left',
                }}
              >
                <strong>CPF</strong>
              </div>

              <div
                style={{
                  flex: changeFlexValue(0),
                  textAlign: 'left',
                }}
              >
                <strong>Status</strong>
              </div>

              <div
                style={{
                  flex: changeFlexValue(0),
                  textAlign: 'left',
                }}
              >
                <strong>Data</strong>
              </div>
              <div
                style={{
                  flex: 0,
                  textAlign: 'left',
                }}
              >
                <strong>Ações</strong>
              </div>
            </div>
            {loginData?.data?.map((data, index) => (
              <div key={data.id} className="div__row_list_content">
                <Tooltip title={data?.id} placement="right">
                  <div
                    style={{
                      width: '60px',
                      textAlign: 'left',
                      wordWrap: 'break-word',
                    }}
                  >
                    {data?.id?.slice(0, 4)}...
                  </div>
                </Tooltip>

                <div
                  style={{
                    flex: changeFlexValue(index + 1),
                    textAlign: 'left',
                  }}
                >
                  {data?.email}
                </div>

                <div
                  style={{
                    flex: changeFlexValue(index + 1),
                    textAlign: 'left',
                  }}
                >
                  {data?.user?.person?.full_name ? data?.user?.person?.full_name : ' - '}
                </div>

                <div
                  style={{
                    flex: changeFlexValue(index + 1),
                    textAlign: 'left',
                  }}
                >
                  {data?.user?.person?.cpf ? data?.user?.person?.cpf : ' - '}
                </div>

                <div
                  style={{
                    flex: changeFlexValue(index + 1),
                    textAlign: 'left',
                    color: data.validated_user ? 'var(--success)' : 'var(--warn)',
                  }}
                >
                  {data?.validated_user ? 'Validado' : 'Não Validado'}
                </div>

                <div
                  style={{
                    flex: changeFlexValue(index + 1),
                    textAlign: 'left',
                  }}
                >
                  {new Date(data?.created_at).toLocaleString('pt-BR', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })}
                </div>

                <Tooltip
                  title={loginData?.data[index]?.validated_user ? 'Editar' : 'Validar'}
                  placement="left"
                  style={{
                    flex: 0,
                    textAlign: 'left',
                    cursor: 'pointer',
                    marginLeft: '0.5rem',
                    marginRight: '0.5rem',
                  }}
                  onClick={() => handleEditLoginData(index)}
                >
                  <div>
                    {loginData?.data[index]?.validated_user ? <EditIcon /> : <CheckIcon />}
                  </div>
                </Tooltip>
              </div>
            ))}

            <div className="div__pagination">
              <Pagination
                page={page}
                total={loginData?.total}
                rowsPerPage={rows}
                handleChangeRowsPerPage={handleChangeRow}
                handleChangePage={handleChangePage}
              />
            </div>
            {loginData?.total === 0 && (<div className="div__return_text_find">
              Nenhum dado de login encontrado
            </div>)}
          </div>
        </content>
      </Page>
      {showbackdrop && <SimpleBackdrop />}
      {editLoginData && (
        <ModalEditLoginData
          loginData={editLoginData}
          close={() => setEditLoginData(null)}
          onChangeLoginData={(updatedLoginData) => {
            loginData.data[editLoginData?.index] = updatedLoginData;
          }}
        />
      )}
    </Container>
  )
}
