import { useCallback, useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { toast } from 'react-toastify'
import WarningIcon from '@material-ui/icons/Warning';

import api from '../../services/api';

import { Loading } from '../../icons';

import { Container, FielButtons } from './styles';
import { format } from 'date-fns';

export default function ModalDownloadReport(props) {
  const [open, setOpen] = useState(true);
  const initialStartDate = useMemo(() => {
    const today = new Date();
    const lastYear = today.getFullYear() - 1;
    const lastMonth = today.getMonth() - 1;

    if (lastMonth < 0) {
      today.setMonth(11);
      today.setFullYear(lastYear);
      return today;
    }

    today.setMonth(lastMonth);
    return today;
  }, []);
  const initialEndDate = useMemo(() => new Date(), []);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [startDate, setStartDate] = useState(format(initialStartDate, 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(initialEndDate, 'yyyy-MM-dd'));

  const [reportOptions, setReportOptions] = useState([
    {
      id: 'client_name',
      name: 'Cliente',
      value: true,
    },
    {
      id: 'created_at',
      name: 'Data inicial',
      value: true,
    },
    {
      id: 'finished_at',
      name: 'Data final',
      value: true,
    },
    {
      id: 'situation',
      name: 'Situação',
      value: true,
    },
    {
      id: 'stage',
      name: 'Processo',
      value: true,
    },
    {
      id: 'description',
      name: 'Descrição',
      value: true,
    },
    {
      id: 'social_reason',
      name: 'Integrador',
      value: false,
    },
    {
      id: 'client_cpf',
      name: 'CPF',
      value: false,
    },
    {
      id: 'client_cnpj',
      name: 'CNPJ',
      value: false,
    },
    {
      id: 'dealer_state',
      name: 'Estado',
      value: false,
    },
    {
      id: 'dealer_name',
      name: 'Concessionária',
      value: false,
    },
    {
      id: 'breaker_type',
      name: 'Tipo de disjuntor',
      value: false,
    },
    {
      id: 'breaker_value',
      name: 'Valor do disjuntor',
      value: false,
    },
    {
      id: 'coordinates',
      name: 'Coordenadas',
      value: false,
    },
    {
      id: 'total_project_power',
      name: 'Potência',
      value: false,
    },
    {
      id: 'amount_panels',
      name: 'Quantidade de placas',
      value: false,
    },
    {
      id: 'panel_power',
      name: 'Potência das placas',
      value: false,
    },
    {
      id: 'panel_model',
      name: 'Modelo das placas',
      value: false,
    },
  ])

  const handleReportDownload = useCallback(() => {
    if (!startDate || !endDate) {
      toast.error('Selecione uma data inicial e uma data final');
      return;
    }

    if (new Date(startDate).getTime() >= new Date(endDate).getTime()) {
      toast.error('Data inicial não pode ser maior que a data final');
      return;
    }

    setIsDownloadingReport(true);

    const params = new URLSearchParams();
    params.append('start_date', startDate);
    params.append('end_date', endDate);
    reportOptions.forEach(({ id, value }) => {
      params.append(id, value);
    });

    const url = `${api.defaults.baseURL}/projects/report?${params}`;
    fetch(url, { headers: api.defaults.headers })
      .then(async (res) => {
        const filename = res.headers.get('content-disposition')
          ?.split(';')
          ?.find(n => n.includes('filename='))
          ?.replace('filename=', '')
          ?.replaceAll('"', '')
          ?.trim();

        return {
          blob: new Blob([await res.blob()]),
          filename: filename ? decodeURI(filename) : 'Relatório.xlsx',
        };
      })
      .then(({ blob, filename }) => {
        const fileUrl = window.URL.createObjectURL(blob);
        const anchorTag = document.createElement('a');
        anchorTag.href = fileUrl;
        anchorTag.download = filename;
        document.body.appendChild(anchorTag);
        anchorTag.click();
        anchorTag.remove();
      })
      .catch(() => toast.error('Erro ao gerar relatório'))
      .finally(() => setIsDownloadingReport(false));
  }, [reportOptions]); // eslint-disable-line

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props?.close(), 100);
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="sm"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        style={{ zIndex: 9999991 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <h1>Baixar Relatório</h1>
              <div className='warning'>
                <WarningIcon />
                A velocidade de download depende da quantidade de projetos.
                Se a quantidade de relatórios for <strong>muito grande</strong> pode haver falha no download.
                A quantidade de projetos depende do intervalo de datas escolhido.
              </div>
              <div className='filter_date'>
                <label className="filter_label">Selecione o intervalo de datas do relatório</label>
                <div className="date_inputs">
                  <div className="date_input">
                    <input
                      type="date"
                      name="start_date"
                      value={startDate}
                      onChange={({ target }) => setStartDate(target.value)}
                      style={{ maxWidth: 150, color: !startDate ? 'var(--delete)' : undefined }}
                      max={endDate && endDate}
                    />
                    <div className="pos_field">Início</div>
                  </div>

                  <div className="date_input">
                    <input
                      type="date"
                      name="end_date"
                      value={endDate}
                      onChange={({ target }) => setEndDate(target.value)}
                      style={{ maxWidth: 150, color: !endDate ? 'var(--delete)' : undefined }}
                      min={startDate && startDate}
                    />
                    <div className="pos_field">Fim</div>
                  </div>
                </div>
              </div>
              <div className="filter_options">
                <label className="filter_label">Selecione os dados do relatório</label>
                <div className="options">
                  {reportOptions.map((reportOption, index) => (
                    <label key={index + reportOption.name}>
                      <input
                        type="checkbox"
                        checked={reportOption.value}
                        onChange={() => {
                          const newReportOptions = reportOptions
                          newReportOptions[index].value = !reportOption.value
                          setReportOptions([...newReportOptions])
                        }}
                      />
                      {reportOption.name}
                    </label>
                  ))}
                </div>
              </div>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleReportDownload}
              className="btn_download"
              color="primary"
              style={{
                minWidth: '100px',
              }}
              disabled={isDownloadingReport}
            >
              {isDownloadingReport && (<Loading
                width="20px"
                height="20px"
                style={{ margin: "0" }}
                circlecolor='white'
              />)}
              Baixar
            </button>
            <button
              onClick={handleClose}
              className="btn_return"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Voltar
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
