import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { Container, FielButtons } from './styles';

import Viewer, { Worker, defaultLayout } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

export default function Dialogoverificaralteracao(props) {
  const [open, setOpen] = React.useState(true);

  const [documento, setDocumento] = React.useState(
    'C:/Users/felip/Downloads/historico_398233.pdf',
  );

  // setDocumento('C:/Users/felip/Downloads/historico_398233.pdf');

  const renderToolbar = (toolbarSlot) => {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            flexShrink: 1,
            justifyContent: 'center',
          }}
        >
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.previousPageButton}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
          </div>
          <div style={{ padding: '0 2px' }}>{toolbarSlot.nextPageButton}</div>
          <div style={{ padding: '0 2px' }}>{toolbarSlot.zoomOutButton}</div>
          <div style={{ padding: '0 2px' }}>{toolbarSlot.zoomPopover}</div>
          <div style={{ padding: '0 2px' }}>{toolbarSlot.zoomInButton}</div>
          <div style={{ padding: '0 2px' }}>{toolbarSlot.downloadButton}</div>
        </div>
      </div>
    );
  };

  const layout = (isSidebarOpened, container, main, toolbar, sidebar) => {
    return defaultLayout(
      isSidebarOpened,
      container,
      main,
      toolbar(renderToolbar),
      sidebar,
    );
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 9999999991,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>Visualizar Arquivo</h1>
                <span>Confira abaixo o arquivo anexado.</span>
              </header>

              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                <div
                  style={{
                    height: '70vh',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {documento ? (
                    <Viewer
                      fileUrl={props.fileUrl}
                      defaultScale={window.innerWidth > 675 ? '1' : '0.5'}
                      layout={layout}
                    />
                  ) : (
                    <div style={{ margin: 'auto', opacity: '80%' }}>
                      <h5>Documento inexistente</h5>
                    </div>
                  )}
                </div>
              </Worker>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Voltar
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
