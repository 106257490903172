import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { mask } from 'remask';

import { Container, FielButtons } from './styles';

// icons
import DeleteIcon from '@material-ui/icons/Delete';

// mensagem
import { toast } from 'react-toastify';

// api
import api from '../../services/api';
import apiCEP from '../../services/apiCEP';
import apiCNPJ from '../../services/apiCNPJ';
import { useUserData } from '../../context/UserDataContext';

export default function Alterardados({ fechar }) {
  const {
    person,
    addressPerson,
    company,
    addressCompany,
    getDadosUsuario,
    numbersContact,
  } = useUserData();
  const [open, setOpen] = React.useState(true);

  const [personFake, setPersonFake] = useState({ ...person });
  const [addressPersonFake, setAddressPersonFake] = useState(
    addressPerson
      ? { ...addressPerson }
      : {
          street: '',
          number: '',
          district: '',
          city: '',
          state: '',
          zip_code: '',
          complement: '',
        }
  );
  const [companyFake, setCompanyFake] = useState({ ...company });
  const [addressCompanyFake, setAddressCompanyFake] = useState(
    addressCompany
      ? { ...addressCompany }
      : {
          street: '',
          number: '',
          district: '',
          city: '',
          state: '',
          zip_code: '',
          complement: '',
        }
  );
  const [numbersContactFake, setNumbersContactFake] = useState([
    ...numbersContact,
  ]);

  const handleChangePerson = (e) => {
    setPersonFake({
      ...personFake,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeAddressPerson = (e) => {
    if ([e.target.name][0] === 'zip_code') {
      setAddressPersonFake({
        ...addressPersonFake,
        zip_code: mask(e.target.value, '99999-999'),
      });
    } else {
      setAddressPersonFake({
        ...addressPersonFake,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeCompany = (e) => {
    setCompanyFake({
      ...companyFake,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeAddressCompany = (e) => {
    if ([e.target.name][0] === 'zip_code') {
      setAddressCompanyFake({
        ...addressCompanyFake,
        zip_code: mask(e.target.value, '99999-999'),
      });
    } else {
      setAddressCompanyFake({
        ...addressCompanyFake,
        [e.target.name]: e.target.value,
      });
    }
  };

  const getZipcode = async (e, type) => {
    if (e.target.value.length <= 8) {
      return;
    }

    await apiCEP
      .get(`/${e.target.value.replace(/\D/g, '')}/json`)
      .then((res) => {
        if (type === 'company') {
          setAddressCompanyFake({
            ...addressCompanyFake,
            street: res.data.logradouro.toLowerCase(),
            district: res.data.bairro.toLowerCase(),
            city: res.data.localidade.toLowerCase(),
            state: res.data.uf.toUpperCase(),
            complement: res.data.complemento.toLowerCase(),
          });
        } else {
          setAddressPersonFake({
            ...addressPersonFake,
            street: res.data.logradouro.toLowerCase(),
            district: res.data.bairro.toLowerCase(),
            city: res.data.localidade.toLowerCase(),
            state: res.data.uf.toUpperCase(),
            complement: res.data.complemento.toLowerCase(),
          });
        }
      })
      .catch((error) => {
        if (type === 'company') {
          setAddressCompanyFake({
            ...addressCompanyFake,
            street: '',
            district: '',
            city: '',
            state: '',
            complement: '',
          });
        } else {
          setAddressPersonFake({
            ...addressPersonFake,
            street: '',
            district: '',
            city: '',
            state: '',
            complement: '',
          });
        }
        return toast.warn('CEP inválido!');
      });
  };

  const getCnpjData = async (e) => {
    let aux = window
      .encodeURIComponent(
        e.target.value
          .replace('.', '')
          .replace('.', '')
          .replace('/', '')
          .replace('-', '')
      )
      .replace('%C2%AD', '');

    if (aux.length > 13) {
      await apiCNPJ
        .get(aux)
        .then((response) => {
          setCompanyFake({
            ...companyFake,
            fantasy_name: response.data.fantasia,
            social_reason: response.data.nome,
          });

          setAddressCompanyFake({
            ...addressCompanyFake,
            street: response.data.logradouro,
            number: response.data.numero,
            district: response.data.bairro,
            city: response.data.municipio,
            state: response.data.uf,
            zip_code: response.data.cep,
            complement: response.data.complemento,
          });
        })
        .catch((error) => {
          console.log('Erro: ', error);
          toast.error(
            'Falha ao validar CNPJ. Mas você pode preencher os dados abaixo normalmente'
          );
        });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => fechar(), 100);
  };

  const onSubmitUpdateData = () => {
    if (numbersContactFake.length > 0 && !numbersContactFake[0].type_number)
      return toast.warn('Tipo do número é necessário');

    const dadosReq = {
      addressDataPerson:
        addressPersonFake && addressPersonFake.zip_code
          ? addressPersonFake
          : null,
      personData: personFake,
      companyData: companyFake,
      addressDataCompany:
        addressCompanyFake && addressCompanyFake.zip_code
          ? addressCompanyFake
          : null,
      numbersContactData: numbersContactFake[0],
    };

    api
      .put('/user-updated', dadosReq)
      .then((res) => {
        getDadosUsuario();
        fechar();
      })
      .catch((error) => {
        return toast.warn(error.response.data.error);
      });
  };

  const handleChangeNumbersContact = (e, index) => {
    let newNumber = numbersContactFake[index];

    let newNumbers = numbersContactFake.map((number, i) => {
      if (index === i) {
        if ([e.target.name][0] === 'number') {
          return {
            ...newNumber,
            number: mask(e.target.value, ['(99) 99999-9999']),
          };
        } else if ([e.target.name][0] === 'is_whatsapp') {
          return {
            ...newNumber,
            is_whatsapp: !number.is_whatsapp,
          };
        } else {
          return {
            ...newNumber,
            [e.target.name]: e.target.value,
          };
        }
      } else {
        return number;
      }
    });

    setNumbersContactFake([...newNumbers]);
  };

  const handleChangeDeleteNumberContact = (index) => {
    let numbers = numbersContactFake;

    numbers.splice(index, 1);
    setNumbersContactFake([...numbers]);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 999999,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>Alterar dados</h1>
                <span>
                  Preencha corretamente os campos abaixo para alterar suas
                  informações.
                </span>
                <content>
                  <h4>Dados pessoais</h4>
                  <div className="div__oneRow">
                    <input
                      type="text"
                      name="full_name"
                      value={personFake?.full_name}
                      placeholder="Nome completo"
                      style={{
                        flex: 2,
                      }}
                      onChange={handleChangePerson}
                      required
                    />
                    <input
                      type="text"
                      name="cpf"
                      value={personFake?.cpf}
                      placeholder="CPF"
                      style={{
                        flex: 1,
                      }}
                      disabled
                      onChange={handleChangePerson}
                      required
                    />
                  </div>

                  <h4>Dados de contatos</h4>
                  <div className="div__oneRow">
                    <table className="tableContact">
                      <thead>
                        <tr>
                          <th className="title_type">Tipo de Número</th>
                          <th className="title_number">Número</th>
                          {/* <th className="title_wpp">É whatsapp?</th> */}
                          <th className="title_actions">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {numbersContactFake.length > 0 &&
                          numbersContactFake.map((number, index) => (
                            <tr>
                              <td className="item_type">
                                <select
                                  required
                                  name="type_number"
                                  value={number.type_number}
                                  onChange={(e) =>
                                    handleChangeNumbersContact(e, index)
                                  }
                                >
                                  <option value="" disabled selected>
                                    Tipo de Número*
                                  </option>
                                  <option value="fixo">Fixo</option>
                                  <option value="celular">Celular</option>
                                </select>
                              </td>
                              <td className="item_number">
                                <input
                                  type="text"
                                  name="number"
                                  value={number.number}
                                  placeholder="Número"
                                  onChange={(e) =>
                                    handleChangeNumbersContact(e, index)
                                  }
                                  required
                                />
                              </td>
                              <td className="item_wpp">
                                <input
                                  type="checkbox"
                                  name="is_whatsapp"
                                  id="is_whatsapp"
                                  value={number.is_whatsapp}
                                  checked={number.is_whatsapp}
                                  onChange={(e) =>
                                    handleChangeNumbersContact(e, index)
                                  }
                                  required
                                />{' '}
                                <label htmlFor="is_whatsapp">É whatsapp?</label>
                              </td>
                              <td className="item_actions">
                                <DeleteIcon
                                  onClick={() =>
                                    handleChangeDeleteNumberContact(index)
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      {numbersContactFake.length === 0 && (
                        <div className="containerTableButton">
                          <button
                            onClick={() => {
                              if (numbersContactFake.length < 1) {
                                const newNumbers = [...numbersContactFake];

                                let newNumber = {
                                  type_number: '',
                                  number: '',
                                  is_whatsapp: false,
                                };
                                newNumbers.push(newNumber);

                                setNumbersContactFake(newNumbers);
                              }
                            }}
                          >
                            adicionar número
                          </button>
                        </div>
                      )}
                    </table>
                  </div>

                  <h4>Endereço</h4>
                  <div className="div__oneRow">
                    <input
                      type="text"
                      name="zip_code"
                      value={addressPersonFake?.zip_code}
                      placeholder="CEP"
                      style={{
                        flex: 2,
                      }}
                      onChange={handleChangeAddressPerson}
                      onKeyUp={(e) => getZipcode(e, 'person')}
                      required
                    />

                    <input
                      type="text"
                      name="state"
                      value={addressPersonFake?.state}
                      placeholder="Estado"
                      style={{
                        flex: 1,
                      }}
                      onChange={handleChangeAddressPerson}
                      required
                    />

                    <input
                      type="text"
                      name="city"
                      value={addressPersonFake?.city}
                      placeholder="Cidade"
                      style={{
                        flex: 4,
                      }}
                      onChange={handleChangeAddressPerson}
                      required
                    />

                    <input
                      type="text"
                      name="street"
                      value={addressPersonFake?.street}
                      placeholder="Rua"
                      style={{
                        flex: 4,
                      }}
                      onChange={handleChangeAddressPerson}
                      required
                    />
                  </div>
                  <div className="div__oneRow">
                    <input
                      type="text"
                      name="number"
                      value={addressPersonFake?.number}
                      placeholder="Número"
                      style={{
                        flex: 1,
                      }}
                      onChange={handleChangeAddressPerson}
                      required
                    />
                    <input
                      type="text"
                      name="district"
                      value={addressPersonFake?.district}
                      placeholder="Bairro"
                      style={{
                        flex: 4,
                      }}
                      onChange={handleChangeAddressPerson}
                      required
                    />

                    <input
                      type="text"
                      name="complement"
                      value={addressPersonFake?.complement}
                      placeholder="Complemento"
                      style={{
                        flex: 4,
                      }}
                      onChange={handleChangeAddressPerson}
                      required
                    />
                  </div>
                  <div className="div__oneRow"></div>

                  <h4>Minhas atribuições na empresa</h4>
                  <div className="div__oneRow">
                    <input
                      type="text"
                      name="role"
                      value={personFake?.role}
                      placeholder="Cargo"
                      style={{
                        flex: 1,
                        maxWidth: window.innerWidth > 675 && '280px',
                      }}
                      onChange={handleChangePerson}
                      required
                    />
                  </div>
                </content>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
            >
              Cancelar
            </button>
            <button
              onClick={() => onSubmitUpdateData()}
              className="btn__register"
            >
              Aplicar alterações
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
