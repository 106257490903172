// logo da tellar
import Logotellar from '../assets/images/bgs/header-img.png';

// mascara de dinheiro
const currency = function (number) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(number);
};

export default function Printorcamento(dados) {
  var tela_impressao = window.open('about:blank');
  tela_impressao.document.write(`
  <!DOCTYPE html>
<html lang="pt-BR">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">
  <meta name="description" content="Impressão de orçamento" />
  <title>Imprimir Orçamento</title>

  <style type="text/css">

  @media print  
  {
    @page { margin: 0.5cm;  -webkit-print-color-adjust: exact;}

      
      div{
          page-break-inside: avoid;
      }
      
      -webkit-print-color-adjust: exact;
  }

    * {
      margin: 0;
      padding: 0;
      font-family: Arial, Helvetica, sans-serif;
    }

    body {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    .cabecalho,
    .content_1 {
      max-width: 210mm;
      width: 100%;
    }
    .cabecalho img {
      max-width: 210mm;
    }

    /* corpo  */
    .content_1 .div__oneRow {
      display: flex;


    }

    .content_1 .div__oneColumn {
      display: flex;
      flex-direction: column;
      margin: 2rem;
    }

    h4 {
      margin: 0.5rem 1rem;
    }

    p {
      margin: 0rem 1rem;
    }

    h3 {
      margin: 0.25rem 1rem;
      color: #078C15;
    }
  </style>
</head>

<body>
  <div class="cabecalho">
    <img src=${Logotellar} />
  </div>
  <div class="content_1">
    <h3>Proposta: ${
      new Date(dados.created_at).toLocaleString('pt-BR', {
        year: 'numeric',
      }) +
      '-' +
      dados.id
    }</h3>
    <h4>Integrador</h4>
    <p>${dados.full_name} - ${dados.email}</p>
    <h4>Descrição Geral</h4>
    <p>Elaboração de projeto fotovoltaico completo com homologação na distribuidora de energia.</p>
    <h4>Dados do orçamento</h4>
    <div class="div__oneRow" style="margin: 0rem !important">
      <div class="div__oneColumn"><b>Estado</b><span>${dados.state}</span></div>
      <div class="div__oneColumn"><b>Distribuidora de energia</b><span>${
        dados.dealer
      }</span></div>
      <div class="div__oneColumn"><b>Potência</b><span>${
        dados.power
      } kWp</span></div>
      <div class="div__oneColumn"><b>Categoria</b><span>${
        dados.power <= 75 ? 'Microgeração' : 'Minigeração'
      }</span></div>
    </div>
    <div class="div__oneColumn" style=" margin-top: -0.5rem;">
      <h4>Itens inclusos no projeto</h4>
      <ul>
        <li>Toda a documentação necessária para homologar o sistema*</li>
        <li>Todo o processo de homologação na distribuidora de energia</li>
        <li>Elaboração do projeto elétrico, cálculo das proteções, aterramento, especificação da configuração e montagem
          da string box</li>
        <li>Apoio técnico de uma equipe de engenheiros com grande conhecimento em energia solar**</li>
        <li>Taxa de ART de projeto</li>
      </ul>
      <span><small>*Exceto documentos e estudos que não constem no módulo 3 do Prodist.</small>
      </span>
      <span>
        <small>**Não serão prestados suportes técnicos para projetos que não foram desenvolvidos pela TELLAR.</small>
      </span>
    </div>
    <div class="div__oneColumn" style=" margin-top: -0.5rem;">
      <h4>Itens não inclusos no projeto</h4>
      <ul>
        <li>Custo de viagem do engenheiro para comissionamento no local</li>
        <li>Vistoria técnica do engenheiro</li>
        <li>Comissionamento do sistema conforme NBR 16274 e demais normas vigentes</li>
        <li>Geração da ART de execução</li>
        <li>Taxa de ART de execução</li>
      </ul>
    </div>
    <div class="div__oneColumn" style=" margin: 0rem; margin-top: -0.5rem;">
      <h4 style="margin: 0rem 3rem;">Exemplo de documentos entregues no projeto</h4>
      <div class="div__oneRow" style="margin: 0rem !important;">
        <div class="div__oneColumn">
          <ul>
            <li>Modelagem 3D do sistema <br/>(somente
              projetos acima de 20 kWp)
            </li>
            <li>ART de projeto</li>
            <li>Memorial descritivo</li>
            <li>Diagrama unifilar e funcional</li>
            <li>Diagrama de blocos</li>
            <li>String Box</li>
            <li>Instalação do inversor</li>
            <li>Planta de situação</li>
          </ul>
        </div>
        <div class="div__oneColumn">
          <ul>
            <li>Formulário de solicitação de acesso
            </li>
            <li>Formulário de registro da central geradora</li>
            <li>Datasheet do módulo</li>
            <li>Datasheet do inversor</li>
            <li>Registro do Inmetro do módulo</li>
            <li>Registro do Inmetro do inversor</li>
            <li>Formulário de compensação <br/>(se necessário) </li>

          </ul>
        </div>
      </div>
    </div>
    <div class="div__oneColumn" style=" margin-top: -0.75rem;">
      <h4>Valor</h4>
      <p>Projeto - Homologação - ART de projeto</p>
      <h3>${currency(dados.price)}</h3>
    </div>
    <p style="font-size: 0.8rem">
      Gerado em ${new Date(dados.created_at).toLocaleString('pt-BR', {
        day: '2-digit',
        month: 'long',
        year: '2-digit',
      })}.
    </p>

  </div>
</body>


</html>`);

  setTimeout((e) => tela_impressao.window.print(), 200);
}
