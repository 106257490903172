import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

// icones
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { Container, FielButtons } from './styles';
import Dialogoconfirmacao from '../Dialogoconfirmacao';
import {
  DELETE_CHANGE_REQUESTS,
  PATCH_CHANGE_REQUESTS,
} from '../../services/endpoints';

export default function Dialogoverificaralteracao(props) {
  const [open, setOpen] = React.useState(true);
  const [dados, setDados] = React.useState(props.dados);

  const [showconf, setShowconf] = React.useState({
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente deletar este pedido de alteração?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
    temp: '',
  });

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  React.useEffect(() => {
    props?.dados && setDados(props?.dados);
  }, []);

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      maxWidth="md"
      scroll="body"
      fullScreen={window.innerWidth > 675 ? false : true}
      fullWidth
      style={{
        zIndex: 9999,
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Container suspended={false}>
            <header>
              <h1>Alterações do Projeto</h1>
              <span>
                Confira abaixo os pedidos de alterações com mais detalhes.
              </span>
              <div className="div__listpendencias">
                {dados
                  ?.sort((a, b) => {
                    return (
                      new Date(b.created_at).getTime() -
                      new Date(a.created_at).getTime()
                    );
                  })
                  .map((pendencia, idx) => (
                    <div key={idx}>
                      <div className="div__pendencias">
                        {pendencia.title === 'Suspender projeto' ||
                        pendencia.title === 'Retomar projeto' ? (
                          <ReportProblemOutlinedIcon className="icone_suspend" />
                        ) : pendencia.resolved ? (
                          <CheckCircleOutlineIcon className="icone_success" />
                        ) : (
                          <ErrorOutlineIcon className="icone_warn" />
                        )}
                        <div className="div__info" style={{ flex: 10 }}>
                          <h5
                            className={
                              pendencia.title === 'Suspender projeto' ||
                              pendencia.title === 'Retomar projeto'
                                ? 'title_suspend'
                                : ''
                            }
                          >
                            {pendencia.title}
                            {/* <span>{pendencia.type}</span> */}
                          </h5>

                          <p>{pendencia.description}</p>
                          <ul>
                            <li>
                              - Solicitado em{' '}
                              {new Date(
                                pendencia.created_at
                              ).toLocaleDateString('pt-BR', {
                                day: '2-digit',
                                month: 'long',
                                year: 'numeric',
                              })}
                            </li>
                            {pendencia.resolved && (
                              <li>
                                - Aceita em{' '}
                                {new Date(
                                  pendencia.updated_at
                                ).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                })}
                              </li>
                            )}
                          </ul>
                        </div>
                        <div
                          className="div__options"
                          style={{
                            flex: 1,
                            display: 'flex',

                            justifyContent: 'flex-end',
                          }}
                        >
                          {!props.dados_proj.suspended && !pendencia.resolved && (
                            <button
                              onClick={() =>
                                props.edit({
                                  status: true,
                                  dados: props.dados_proj,
                                  dados_edit: pendencia,
                                })
                              }
                            >
                              <ChevronRightOutlinedIcon />
                              {/* <EditOutlinedIcon /> */}
                              {/* Editar */}
                            </button>
                          )}
                          <br />

                          {/* </Tooltip> */}
                        </div>
                      </div>

                      {!pendencia.resolved && (
                        <FielButtons>
                          <button
                            // onClick={handleClose}
                            onClick={() =>
                              setShowconf({
                                ...showconf,
                                status: true,
                                dados: pendencia,
                              })
                            }
                            className="btn__retornar"
                            color="primary"
                            style={{
                              minWidth: '100px',
                            }}
                          >
                            Descartar
                          </button>
                          <button
                            onClick={() =>
                              setShowconf({
                                ...showconf,
                                status: true,
                                width: 'sm',
                                title: 'Confirmar',
                                description: `Deseja Confirmar a edição do projeto de ${props.dados_proj.client_name}?`,
                                btn_conf: 'Sim',
                                btn_cancel: 'Não',
                                delete: false,
                                dados: pendencia,
                              })
                            }
                            className="btn__register"
                          >
                            Confirmar alterações
                          </button>
                        </FielButtons>
                      )}
                    </div>
                  ))}
                {!dados.length && <h4>Sem pedidos de alteração</h4>}
              </div>
            </header>
          </Container>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <FielButtons>
          <button
            onClick={handleClose}
            className="btn__retornar"
            color="primary"
            style={{
              minWidth: '100px',
            }}
          >
            Voltar
          </button>
        </FielButtons>
      </DialogActions>

      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({
              status: false,
              width: 'sm',
              title: 'Confirmar',
              description: 'Deseja realmente deletar este pedido de alteração?',
              btn_conf: 'Sim',
              btn_cancel: 'Não',
              delete: true,
              dados: '',
            })
          }
          confirmar={async () => {
            if (showconf.delete) {
              let aux = await dados?.filter(
                (dado) => showconf.dados.id !== dado.id
              );

              await DELETE_CHANGE_REQUESTS(showconf.dados.id, props.atualizar);
              setDados(aux);
            } else {
              await PATCH_CHANGE_REQUESTS(
                showconf.dados.id,
                props.atualizar,
                setDados
              );
            }
          }}
        />
      )}
    </Dialog>
  );
}
