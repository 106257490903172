import styled from 'styled-components';

export const Container = styled.div``;

export const FielButtons = styled.div`
  display: flex;
  /* background: red; */
  align-items: center;

  .btn__retornar {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    width: 100%;
    max-width: 180px;
    height: 35px;
    background-color: transparent;
    border: ${(props) =>
      props.delete ? '1px solid var(--delete)' : '1px solid var(--primary2)'};
    border-radius: 1.4rem;
    color: ${(props) => (props.delete ? 'var(--delete)' : 'var(--primary2)')};
    /* margin-top: 1rem; */
    margin-right: 0.75rem;
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
    }
  }
  .btn__register {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;

    width: 320px;
    height: 45px;
    background-color: ${(props) =>
      props.delete ? 'var(--delete)' : 'var(--primary2)'};
    border: 0px;
    border-radius: 1.4rem;
    color: var(--white);
    /* margin-top: 1rem; */
    /* margin-bottom: 0.75rem; */
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
      filter: brightness(1.4);
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column-reverse;
    flex: 1;

    .btn__retornar,
    .btn__register {
      /* margin: auto; */
      width: 100% !important;
      max-width: none;
      margin: 0.5rem;
      font-size: 1rem;
    }
  }
`;
