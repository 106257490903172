import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(var(--black_rgb), 0.1);
  font-family: 'Poppins', sans-serif;
  background-color: ${(props) =>
    props.abaSelected === 3 ? 'rgba(var(--black_rgb), 0.05)' : 'white'};

  border: ${(props) =>
    props.abaSelected === 3 && '1px solid rgba(var(--black_rgb), 0.05)'};

  h4 {
    margin: 0rem;

    svg {
      font-size: 1rem;
      margin-bottom: -0.17rem;
    }
  }

  .div__options {
    text-align: center;
    margin: 1rem 0rem;
    button {
      border-radius: 50%;
      width: 35px;
      margin: 0.2rem;
      height: 35px;
      justify-content: center;
      align-items: center;
      border: 0px solid rgba(var(--black_rgb), 0.3);
      background-color: transparent;
      border: 2px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;

      svg {
        font-size: 1.2rem;
        color: rgba(var(--black_rgb), 0.3);
        margin-bottom: -0.2rem;
      }

      :hover {
        border: 2px solid var(--primary2);
        svg {
          color: var(--primary2);
        }
      }
    }

    .button_delete {
      :hover {
        border: 2px solid var(--delete);
        svg {
          color: var(--delete);
        }
      }
    }
  }

  .div__name {
    padding: 1rem;
    flex: 2;
    text-align: center;
    display: flex;
    flex-direction: column;

    h4 {
      color: ${(props) =>
        props.abaSelected === 1
          ? 'var(--primary2)'
          : props.abaSelected === 2
          ? 'var(--waitingColor)'
          : 'var(--delete)'};
    }
    span {
      color: rgba(0, 0, 0, 0.7);
    }
  }
  .div__power {
    padding: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;

    h4 {
      margin: 0.5rem;
    }

    .div__sub_power {
      display: flex;
      flex-direction: column;
      span {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 300;
      }
      p {
        margin: auto;
      }
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column;
    margin-bottom: 1rem;

    .div__name {
      padding: 2rem;
      padding-bottom: 0rem;
      width: 100%;
      flex: 2;
      text-align: center;
      display: flex;
      flex-direction: column;
      span {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
`;
