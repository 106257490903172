import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(var(--black_rgb), 0.1);
  font-family: 'Poppins', sans-serif;
  background-color: ${(props) =>
    props.suspended ? 'rgba(var(--black_rgb), 0.05)' : 'white'};
  border: ${(props) =>
    props.suspended && '1px solid rgba(var(--black_rgb), 0.05)'};

  h4 {
    margin: 0rem;

    svg {
      font-size: 1rem;
      margin-bottom: -0.17rem;
    }
  }

  .div__viewMoreInf {
    width: 100%;
    border: 1px solid rgba(var(--black_rgb), 0.1);
    text-align: center;
    padding: 1rem;
    cursor: pointer;
    font-weight: 500;
    color: rgba(var(--black_rgb), 0.4);

    :hover {
      box-shadow: 0px -10px 20px rgba(var(--black_rgb), 0.1);
    }
  }

  .div__options {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 1rem 0rem;
    button {
      border-radius: 50%;
      width: 35px;
      margin: 0rem 0.2rem;
      height: 35px;
      justify-content: center;
      align-items: center;
      /* border: 0px solid rgba(var(--black_rgb), 0.3); */
      background-color: transparent;

      border: 2px solid var(--primary2);
      cursor: pointer;

      svg {
        font-size: 1.2rem;
        /* color: rgba(var(--black_rgb), 0.3); */
        color: var(--primary2);
        margin-bottom: -0.2rem;
      }

      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        background: var(--primary2);
        svg {
          color: var(--white);
        }
      }
    }

    .button_delete {
      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--delete);
        svg {
          color: var(--delete);
        }
      }
    }
  }

  .div__content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-top: 0rem;
    text-align: left;
    width: 100%;
    /* text-align: center; */

    h4 {
      color: ${(props) => !props.suspended && 'var(--primary2)'};
    }
    span {
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .div__first {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
  }

  .div__discount {
    display: flex;
    text-align: right;
    /* align-items: center; */
    justify-content: space-around;
    /* text-align: flex-end; */

    span {
      width: 100%;
    }

    .btn__consultar {
      color: rgba(var(--black_rgb), 0.5);
      border: 1px solid rgba(var(--black_rgb), 0.3);
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      background: var(--white);
      cursor: pointer;

      :hover {
        border: 1px solid var(--primary2);
        color: var(--primary2);
      }
    }

    p {
      color: green;
      margin: 0;
      padding: 0rem;
      font-size: 2rem;
      font-weight: bolder;
    }
  }

  .div__info {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-top: -3rem;

    details {
      summary {
        cursor: pointer;
        color: var(--primary2);
      }
      div {
        background: var(--white);
        position: absolute;
        box-shadow: 0px 0px 10px rgba(var(--black_rgb), 0.3);
        border-radius: 0.5rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        margin-top: 0.5rem;
        flex: 1;
        b {
          color: var(--primary2);
        }
      }
    }
  }

  .div__description {
    font-size: 0.7rem;
    padding: 1rem 0.5rem;
    color: rgba(var(--black_rgb), 0.8);
    position: relative;
    top: -2rem;
  }

  @media screen and (max-width: 675px) {
    flex-direction: column;
    margin-bottom: 1rem;

    .div__info {
      margin-top: 0rem;
    }

    .div__name {
      padding: 1rem;
      width: 100%;
      flex: 2;
      text-align: center;
      display: flex;
      flex-direction: column;
      padding-top: 0rem;
      span {
        color: rgba(0, 0, 0, 0.7);
      }
    }
    .div__first {
      grid-template-columns: 1fr;
    }
  }
`;
