import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 4rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem 1rem;
`;

export const Page = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  /* border-radius: 0.5rem; */
  min-height: 200px;

  header {
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
    font-family: 'Poppins', sans-serif;

    h1 {
      font-weight: 800;
      font-size: 1.5rem;
      color: var(--primary2);
      text-transform: uppercase;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
    span {
      opacity: 0.9;
    }

    ::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 1rem;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    }
  }

  .div__conteudo {
    margin: 0rem 2rem;
    margin-bottom: 2rem;
    /* background: red; */

    .div__row {
      display: flex;
      flex-direction: row;

      strong {
        margin-right: 0.5rem;
      }
      small {
        margin: 0rem 0.5rem;
      }
    }

    h4 {
      /* background-color: red; */
      text-transform: uppercase;
      margin: 1rem 0rem;
      font-size: 0.8rem;
      color: rgba(var(--black_rgb), 0.5);
      font-weight: 400;
      width: 100%;
      /* padding: 0rem 2rem; */
      flex: 1;
      /* margin-left: 0rem; */
      text-align: left;

      ::after {
        display: block;
        content: '';
        margin: 0.5rem 0rem;
        border-bottom: 1px dotted rgba(var(--black_rgb), 0.5);
      }
    }

    p {
      flex: 1;
      margin: 0rem;
      font-size: 0.9rem;
    }
    label {
      font-weight: 600;
    }
    .div__oneRow {
      display: flex;
      text-align: left;
      justify-content: space-between;
      /* background: red; */
    }

    .btn__finish {
      background-image: linear-gradient(
        to bottom right,
        var(--primary2),
        var(--primary)
      );
      padding: 0.5rem 2rem;
      color: #fff;
      font-family: 'Poppins', sans-serif;
      border: 0rem;
      font-size: 0.8rem;
      float: left;
      margin: 1rem 0rem;
      cursor: pointer;
      border-radius: 2rem;

      :hover {
        background-image: linear-gradient(
          to bottom left,
          var(--primary2),
          var(--primary)
        );
      }
    }

    @media screen and (max-width: 675px) {
      .div__oneRow {
        display: flex;
        flex-direction: column;
      }
    }
  }

  content {
    details {
      summary {
        text-align: left;
        margin: 0rem 2rem;
        color: rgba(var(--black_rgb), 0.8);
        border-bottom: 1px dotted rgba(var(--black_rgb), 0.4);
        padding: 0.25rem;
        font-size: 0.8rem;
        cursor: default;
        list-style-type: '';
        :after {
          background: rgba(var(--primary2_rgb), 0.8);
          content: '+';
          color: #fff;
          float: left;
          /* font-weight: bold; */
          padding: 0;
          text-align: center;
          width: 15px;
          height: 15px;
          left: 20px;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .div__field_selected_info {
        text-align: left;
        margin: 0.25rem 2rem;
        max-width: 700px;
        display: flex;
        flex-wrap: wrap;
      }
    }
    details[open] summary:after {
      content: '-';
    }

    .div__pagination {
      border: 1px solid rgba(var(--black_rgb), 0.1);
      text-align: center;
      padding: 1rem;
      border-radius: 0rem 0rem 0.5rem 0.5rem;
      font-weight: 500;
      color: rgba(var(--black_rgb), 0.4);
    }

    .div__return_text_find {
      color: rgba(var(--black_rgb), 0.5);
      margin: 2rem 1rem;
    }

    .div__list_content {
      display: flex;
      flex-direction: column;
      margin: 2rem;
      gap: 0.15rem;
      .div__row_list_content {
        display: flex;
        /* justify-content: space-around; */
        gap: 1rem;
        border: 1px solid rgba(var(--black_rgb), 0.1);
        padding: 0.5rem;
        align-items: center;

        div {
          font-size: 0.8rem;
        }
      }
    }

    .btn__download {
      background-image: linear-gradient(
        to bottom right,
        rgba(var(--excelcolor_rgb), 1),
        rgba(var(--excelcolor_rgb), 0.7)
      );

      border: 0rem;
      padding: 0.25rem 1.25rem;
      color: #fff;
      font-family: 'Poppins', sans-serif;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 2rem;
      outline: none;

      img {
        max-width: 30px;
        margin-right: 0.5rem;
      }
      :hover {
        background-image: linear-gradient(
          to bottom left,
          rgba(var(--excelcolor_rgb), 1),
          rgba(var(--excelcolor_rgb), 0.7)
        );
      }
    }
    .btn__finish {
      background-image: linear-gradient(
        to bottom right,
        var(--primary2),
        var(--primary)
      );
      padding: 0.25rem 1rem;
      color: #fff;
      font-family: 'Poppins', sans-serif;
      border: 0rem;
      font-size: 0.9rem;
      float: right;
      margin: 0rem 1rem;
      cursor: pointer;
      border-radius: 2rem;

      :hover {
        background-image: linear-gradient(
          to bottom left,
          var(--primary2),
          var(--primary)
        );
      }
    }

    .pos_field {
      background-color: #e1e1e1;
      z-index: 1;
      padding: 0rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0rem 0.5rem 0.5rem 0rem;
      margin-left: -0.7rem;
      border: 1px solid rgba(var(--black_rgb), 0.3);
    }

    .div__fieldFilter {
      margin: 0rem 2rem;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;

      .div__filters {
        display: flex;
        justify-content: flex-start;
        margin: auto 0px;
        width: 100%;
        input,
        select {
          outline-color: var(--primary2);
          padding: 0.75rem 0.5rem;
          border: 1px solid rgba(var(--black_rgb), 0.3);
          border-radius: 0.5rem;
          margin: 0rem 0.25rem;
          width: 100%;
          /* margin: auto; */
          max-width: 350px;
          z-index: 0;
        }
        @media screen and (max-width: 675px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          margin-bottom: 1rem;
        }
      }

      .div__order {
        /* background: red; */
        margin: auto;
        display: flex;
        button {
          border: 1px solid rgba(var(--black_rgb), 0.2);
          padding: 0.2rem 0.5rem;
          color: var(--primary2);
          cursor: pointer;

          :hover {
            background-color: var(--primary2) !important;
            color: var(--white);
          }
        }
      }

      .btn__esq {
        background-color: transparent !important;
        border-radius: 0.5rem 0rem 0rem 0.5rem;
      }

      .btn__dir {
        background-color: transparent !important;
        border-radius: 0rem 0.5rem 0.5rem 0rem;
        border-left: 0px;
      }

      .btn__ativ {
        background-color: var(--primary2) !important;
        color: var(--white);
      }

      .btn_meio {
        background: var(--white);
        border-left: 0px;
      }

      @media screen and (max-width: 675px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 675px) {
    .div__list_content {
      max-width: 100% !important;

      overflow-x: scroll !important;

      .div__row_list_content {
        min-width: 800px;
      }
    }
  }
`;

export const Breadcrumb = styled.div``;
