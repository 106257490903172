import './App.css';
import React, { useState, useEffect } from 'react';
import { KEY_ACCESS } from './services/Auth';

// contextos
import { useAuth } from './context/AuthContext';

// React router dom
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

// notificações
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// rotas públicas
import Login from './pages/public/Login';

// rotas privadas
import Menu from './components/Menu';
import Dashboard from './pages/private/Dashboard';
import Configuracoes from './pages/private/Configuracoes';
import NovosProjetos from './pages/private/NovosProjetos';
import Novoscreditos from './pages/private/Novoscreditos';
import Integradores from './pages/private/Integradores';
import Users from './pages/private/Users';
import Logins from './pages/private/Logins';
import Reports from './pages/private/Reports';
import Projetos from './pages/private/Projetos';
import Orcamentos from './pages/private/Orcamentos';
import Cupons from './pages/private/Cupons';

function App() {
  const { user } = useAuth();

  const [abrir, setAbrir] = useState(true);
  const [open, setOpen] = useState(true);

  function logoff() {}

  // console.log('Dados de user: ', user);

  return (
    <div className="App">
      <Router>
        {user && (
          <Route
            path="/:id2"
            children={
              <Menu
                user={user}
                logoff={() => logoff()}
                abrir={(a) => setOpen(a)}
              />
            }
          />
        )}
        {user ? (
          <div
            style={{
              paddingLeft:
                window.innerWidth < 675 ? '0rem' : open ? '15rem' : '4.5rem',
              paddingTop: window.innerWidth < 675 && '0.75rem',
              transition: '0.25s',
            }}
          >
            <Switch>
              {user.user.is_tellar_admin && (
                <Route
                  exact
                  path={'/dashboard'}
                  children={<Dashboard user={user} />}
                />
              )}
              {user.user.is_tellar_admin && (
                <Route
                  exact
                  path={'/novos-projetos'}
                  children={<NovosProjetos user={user} />}
                />
              )}
              {user.user.is_tellar_admin && (
                <Route
                  exact
                  path={'/novos-creditos'}
                  children={<Novoscreditos user={user} />}
                />
              )}
              <Route
                exact
                path={'/integradores'}
                children={<Integradores user={user} />}
              />
              <Route
                exact
                path={'/integradores/projetos/:id'}
                children={<Projetos user={user} />}
              />
              {user.user.is_tellar_admin && (
                <Route
                  exact
                  path={'/usuarios'}
                  children={<Users user={user} />}
                />
              )}
              <Route
                exact
                path={'/configuracoes'}
                children={<Configuracoes user={user} />}
              />
              {user.user.is_tellar_admin && (
                <Route
                  exact
                  path={'/logins'}
                  children={<Logins />}
                />
              )}
              {user.user.is_tellar_admin && (
                <Route
                  exact
                  path={'/relatorios'}
                  children={<Reports user={user} />}
                />
              )}
              {user.user.is_tellar_admin && (
                <Route
                  exact
                  path={'/orcamentos'}
                  children={<Orcamentos user={user} />}
                />
              )}
              {user.user.is_tellar_admin && (
                <Route
                  exact
                  path={'/cupons'}
                  children={<Cupons user={user} />}
                />
              )}
              <Route exact path={'*'}>
                <Redirect to="/integradores" />
              </Route>
            </Switch>
          </div>
        ) : (
          <Switch>
            <Route path={'/login'} children={<Login />} />

            <Route path={'*'}>
              <Redirect to="/login" />
            </Route>
          </Switch>
        )}
      </Router>
      <ToastContainer
        style={{ zIndex: '9999999' }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
