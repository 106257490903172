import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { Card } from './styles';

// dica
import Tooltip from '@material-ui/core/Tooltip';

// icones
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import GroupIcon from '@material-ui/icons/Group';

// fucoes
import Modaleditarintegrador from '../Modaleditarintegrador';

// confirmacao
import Dialogoconfirmacao from '../Dialogoconfirmacao';
import { toast } from 'react-toastify';

// chatbox
// import Chatbox from '../Chatbox';
import api from '../../services/api';

// backdrop
import Backdrop from '../Backdrop';

function CardIntegradores(props) {
  let history = useHistory();

  const [dados, setDados] = useState(props?.dados);

  const [showconf, setShowconf] = useState({
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente suspender este projeto?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
  });

  const [showpoints, setShowpoints] = useState(false);
  const [points, setPoints] = useState(0);
  const [showbackdrop, setShowbackdrop] = useState(false);

  const [editIntegrador, seteditIntegrador] = useState({
    status: false,
    dados: '',
  });

  useEffect(() => {
    dados && setDados(props?.dados);
  }, [props.dados]);

  // Mudar para endpoints e refatorar
  const getPoints = async (id_company) => {
    setShowbackdrop(true);
    await api
      .get(`receipts/discount/${id_company}`)
      .then((res) => {
        setPoints(res.data.percentage_recorrency);
        setShowpoints(true);
        setShowbackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
        setShowbackdrop(setShowbackdrop);
      });
  };

  return (
    <Card>
      <div className="div__content">
        <div className="div__options">
          <Tooltip
            placement="bottom-start"
            title="Visualizar informações do integrador"
          >
            <button
              onClick={() => {
                seteditIntegrador({ status: true, dados: dados });
              }}
            >
              <VisibilityOutlinedIcon />
            </button>
          </Tooltip>
          <Tooltip placement="bottom-start" title="Visualizar projetos">
            <button
              onClick={() => {
                history.push(`/integradores/projetos/${dados.id}`);
              }}
            >
              <AssignmentIcon />
            </button>
          </Tooltip>
          {/* <Tooltip placement="bottom-start" title="Visualizar usuários">
            <button
              onClick={() => {
                history.push('/usuarios');
              }}
            >
              <GroupIcon />
            </button>
          </Tooltip> */}
        </div>

        <div className="div__first">
          <div className="div__info">
            <h4>
              <BusinessIcon /> {dados?.social_reason}
            </h4>
            {dados?.cnpj ? (
              <span>
                <b>CNPJ:</b> {dados.cnpj}
              </span>
            ) : (
              <>
                {dados?.owner?.cpf && (
                  <span>
                    <b>CPF do usuário:</b> <br />
                    {dados?.owner?.cpf}
                  </span>
                )}
              </>
            )}

            {dados?.owner?.user?.login_data?.email && (
              <span>
                <b>Email:</b> {dados?.owner?.user?.login_data?.email}
              </span>
            )}
            {dados?.owner?.numbers_contact[0]?.number && (
              <span>
                <b>Telefone:</b> {dados.owner.numbers_contact[0].number}
              </span>
            )}

            <div
              style={{
                display: 'block',
                width: '95%',
                height: '2px',
                marginTop: '1rem',
                marginBottom: '1rem',
                boxShadow: '0px 0px 1px rgb(var(--primary2_rgb), 1)',
              }}
            ></div>
            <details>
              <summary>Ver mais</summary>
              <div>
                <span>
                  <b>{dados?.total_projects}</b> projetos solicitados
                </span>
                <span>
                  <b>{dados?.open_projects}</b> projetos em aberto
                </span>
                <span>
                  <b>{dados?.fineshed_projects}</b> projetos finalizados
                </span>
                <span>
                  <b>{dados?.suspended_projects}</b> projetos suspensos
                </span>
              </div>
            </details>
          </div>
          <div className="div__discount">
            {dados?.percentage_discount && (
              <span>
                Desconto <br /> acumulado:{' '}
                {showpoints ? (
                  <p>{points} %</p>
                ) : (
                  <button
                    className="btn__consultar"
                    onClick={() => getPoints(dados.id)}
                    type="button"
                  >
                    Consultar
                  </button>
                )}
              </span>
            )}
          </div>
        </div>
      </div>

      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({
              status: false,
              width: 'sm',
              title: 'Confirmar',
              description: 'Deseja realmente suspender este projeto?',
              btn_conf: 'Sim',
              btn_cancel: 'Não',
              delete: true,
              dados: '',
            })
          }
          confirmar={() => {
            if (showconf.dados.suspended) {
              toast.success(
                `Projeto ${showconf.dados.name} retomado com sucesso`,
              );
            } else {
              toast.success(
                `Projeto de ${showconf.dados.name} suspenso com sucesso`,
              );
            }
          }}
        />
      )}

      {editIntegrador.status && (
        <Modaleditarintegrador
          dados={editIntegrador.dados}
          atualizar={() => props.atualizar()}
          fechar={() => seteditIntegrador({ status: false, dados: '' })}
        />
      )}

      {showbackdrop && <Backdrop />}
    </Card>
  );
}

export default CardIntegradores;
