// configurações de end point

// api's de teste
// export const API_URL = 'https://api-tellar.luandeveloper.online';
// export const API_URL = 'http://192.168.0.145:3333';
// export const API_URL = 'http://25.56.136.32:3333';
export const API_URL = process.env.REACT_APP_API_URL;

// api principal
// export const API_URL = 'https://api.tellarsolucoes.com.br';

// configurações de sistema
export const SITE_NAME = "Admin Tellar Soluções";

export const listetapas = [
  // '1 - Aguardando pagamento',
  "Análise de documentos pela equipe Tellar",
  "Projeto em elaboração",
  "Análise de documentação",
  "Análise técnica",
  "Projeto Aprovado",
  "Projeto Reprovado",
];

export const listStates = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];
