import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

// icones
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { Container, FielButtons } from './styles';
import { toast } from 'react-toastify';
import Dialogoconfirmacao from '../Dialogoconfirmacao';
import { Tooltip } from '@material-ui/core';
import {
  DELETE_CHANGE_REQUESTS,
  PATCH_CHANGE_REQUESTS,
} from '../../services/endpoints';

// mascara
import { mask } from 'remask';
import api from '../../services/api';
import Backdrop from '../Backdrop';

export default function Dialogoverificaralteracao(props) {
  const [open, setOpen] = React.useState(true);

  const [showbackdrop, setShowbackdrop] = React.useState(false);

  const [dados, setDados] = React.useState({
    code: '',
    type: 'percentage',
    value: null,
    percentage: '',
    times_can_be_used: '',
    expired_at: new Date(),
  });

  const handleChange = (input) => {
    if (input.target.name === 'code') {
      setDados({
        ...dados,
        [input.target.name]: input.target.value.toUpperCase(),
      });
    } else if (input.target.name === 'percentage') {
      if (input.target.value >= 0 && input.target.value <= 100) {
        setDados({
          ...dados,
          [input.target.name]: mask(input.target.value, '999'),
        });
      } else {
        toast.warn('O valor deve ser entre 0 e 100.');
      }
    } else if (input.target.name === 'times_can_be_used') {
      if (input.target.value >= 0 && input.target.value <= 100) {
        setDados({
          ...dados,
          [input.target.name]: mask(input.target.value, '999'),
        });
      } else {
        toast.warn('Deve ser entre 0 e 100 unidades');
      }
    } else {
      setDados({ ...dados, [input.target.name]: input.target.value });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const setNewCupom = async (event) => {
    event.preventDefault();

    await api
      .post(`/coupons`, dados)
      .then((res) => {
        toast.success('Cupom adicionado com sucesso!');
        props.atualizar();
        handleClose();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setShowbackdrop(false);
      });
  };

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      maxWidth="md"
      scroll="body"
      fullScreen={window.innerWidth > 675 ? false : true}
      fullWidth
      style={{
        zIndex: 9999,
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={setNewCupom}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container suspended={false}>
              <header>
                <h1>Adicionar cupom</h1>
                <span>
                  Confira abaixo os pedidos de alterações com mais detalhes.
                </span>
              </header>
              <content>
                <div className="div__oneRow">
                  <input
                    type="text"
                    name="code"
                    value={dados.code}
                    placeholder="Código do cupom"
                    style={{
                      flex: 2,
                    }}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="number"
                    name="percentage"
                    value={dados.percentage}
                    placeholder="Porcentagem"
                    style={{
                      flex: 2,
                    }}
                    max={100}
                    min={0}
                    onChange={handleChange}
                    required
                  />
                  <div className="pos_field">%</div>
                  <input
                    type="number"
                    name="times_can_be_used"
                    value={dados.times_can_be_used}
                    placeholder="Qtd. de unidades"
                    style={{
                      flex: 2,
                    }}
                    max={100}
                    min={0}
                    onChange={handleChange}
                    required
                  />
                  <div className="pos_field">Unid.</div>
                  <input
                    type="date"
                    name="expired_at"
                    value={dados.expired_at}
                    placeholder="Data limite"
                    style={{
                      flex: 2,
                      height: '2.4rem',
                    }}
                    min={new Date()
                      .toLocaleDateString('pt-BR', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })
                      .split('/')
                      .reverse()
                      .join('-')}
                    onChange={handleChange}
                    required
                  />
                </div>
              </content>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              type="button"
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Voltar
            </button>
            <button
              type="submit"
              className="btn__register"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Adicionar
            </button>
          </FielButtons>
        </DialogActions>
        {showbackdrop && <Backdrop />}
      </form>
    </Dialog>
  );
}
