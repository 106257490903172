import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { mask } from 'remask';
import Dialogoencontrarlocalizacao from '../Dialogoencontrarlocalizacao';
import Modalpdf from '../Modalpdf';

// icones
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import BackupIcon from '@material-ui/icons/Backup';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import LabelIcon from '@material-ui/icons/Label';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { Container, FielButtons } from './styles';
import { toast } from 'react-toastify';
import Dialogoconfirmacao from '../Dialogoconfirmacao';
import {
  DELETE_DOCUMENT_BY_ID,
  DELETE_INVERTERS,
  DELETE_PENDENCIES,
  GET_DEALER_BY_STATE,
  PATCH_DOCUMENT_APROVE,
  PATCH_PENDENCIES,
  POST_INVERTERS,
  POST_NEW_DOCUMENT,
  POST_PENDENCIES,
  PUT_INVERTERS_BY_ID,
  PUT_PROJECTS,
} from '../../services/endpoints';
import SimpleBackdrop from '../Backdrop';
import { listStates } from '../../config';

export default function Alterardados(props) {
  const [showbackdrop, setShowbackdrop] = React.useState(false);

  // Dialogo do PDF
  const [showPDF, setShowPDF] = React.useState({
    open: false,
    file_pdf: '',
  });

  const [open, setOpen] = React.useState(true);

  // Dados do Projeto
  const [dados, setDados] = React.useState({ ...props.dados });
  const [reset, setReset] = React.useState(props.dados);
  // Novo Arquivo
  const [newArchive, setNewArchive] = React.useState([]);

  // Documento
  const [document, setDocument] = React.useState({
    open: false,
    type: '',
    other_type: '',
    document_additional: '',
  });

  // Dialogo de exclusão de arquivos
  const [showconf, setShowconf] = React.useState({
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente remover este arquivo?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
    temp: '',
  });

  // Quantidade de arquivos a serem mostrados
  const [listidx, setListidx] = React.useState(3);

  // Pendencias
  const [listpendencias, setListpendencias] = React.useState(
    props.dados?.pendencies,
  );
  const [openNewPendencia, setOpenNewPendencia] = React.useState(false);
  const [newPendencia, setNewPendencia] = React.useState({
    project_id: dados?.id,
    type: '',
    title: '',
    description: '',
  });

  // Inversores
  const [listinverters, setListinverters] = React.useState(
    props.dados?.inverters,
  );

  const [newInverters, setNewInverters] = React.useState({
    new: false,
    project_id: dados?.id,
    amount: '',
    power_kwp: '',
    model: '',
  });

  // Arquivos Vinculados
  const [listarchives, setListarchives] = React.useState(props.dados.documents);

  // Listagem das concessionarias
  const [listConcess, setListConcess] = React.useState(null);

  // Listagem dos dijuntores
  const [listdisjuntores] = React.useState([
    '2 A',
    '4 A',
    '6 A',
    '10 A',
    '16 A',
    '20 A',
    '25 A',
    '32 A',
    '40 A',
    '50 A',
    '63 A',
    '70 A',
    '80 A',
    '100 A',
    '125 A',
    'Outro',
  ]);

  const [showcoord, setShowcoord] = React.useState(false);

  // Estado para confirmação para edição dos dados
  const [editar, setEditar] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const [checkeds, setCheckeds] = React.useState({
    check_panels: false,
    check_inverters: false,
  });

  const onInputChange = (e) => {
    if (e.target.name === 'other_value_disjuntor') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '999'),
      });
    } else if (e.target.name === 'latitude' || e.target.name === 'longitude') {
      setDados({
        ...dados,
        [e.target.name]: e.target.value,
      });
    } else {
      setDados({ ...dados, [e.target.name]: e.target.value });
    }
  };

  const onInputChangeClient = (e) => {
    if (e.target.name === 'client_cpf') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '999.999.999-99'),
      });
    } else if (e.target.name === 'client_cnpj') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '99.999.999/9999-99'),
      });
    } else {
      setDados({
        ...dados,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onInputChangeDealer = (e) => {
    if (e.target.name === 'state') {
      setDados({
        ...dados,
        dealer: {
          ...dados.dealer,
          [e.target.name]: e.target.value,
        },
      });
      GET_DEALER_BY_STATE(e.target.value, setListConcess, setShowbackdrop);
    } else if (e.target.name === 'dealer_id') {
      setDados({
        ...dados,
        [e.target.name]: Number(e.target.value),
      });
    }
  };

  const onInputChangeSpecifications = (e) => {
    setDados({
      ...dados,
      [e.target.name]:
        e.target.name === 'panel_model'
          ? e.target.value
          : Number(mask(e.target.value, '999')),
    });
  };

  React.useEffect(() => {
    props?.dados_edit ? setEditar(false) : setEditar(true);
    props?.dados && setDados({
      ...props?.dados,
      deadline: props?.dados?.deadline ?
                  new Date(props?.dados?.deadline).toISOString().split('T')[0]
                  :
                  null
    });
    GET_DEALER_BY_STATE(dados?.dealer?.state, setListConcess, setShowbackdrop);
  }, []);

  async function handleSubmitEditProject(event) {
    event.preventDefault();
    setReset(dados);
    // Ajustar a quantidade de atualização no put de inverters
    listinverters.forEach(
      async (inverter) =>
        await PUT_INVERTERS_BY_ID(inverter.id, inverter, setShowbackdrop),
    );
    await PUT_PROJECTS(
      dados.id,
      dados,
      props.atualizar,
      setDados,
      setShowbackdrop,
    );
    setEditar((editar) => !editar);
  }

  function deletePendencia() {
    setTimeout(() => {
      setListpendencias((pendencia) =>
        pendencia.filter((pend) => pend.id !== showconf.dados.id),
      );

      setShowconf({
        ...showconf,
        dados: '',
        status: false,
        temp: '',
      });

      DELETE_PENDENCIES(showconf.dados.id, props.atualizar, setShowbackdrop);
    }, 100);
  }

  function confirmarPendencia() {
    setTimeout(() => {
      setListpendencias((pendencia) =>
        pendencia.map((pend) =>
          pend.id === showconf.dados.id
            ? { ...pend, resolved: !pend.resolved }
            : pend,
        ),
      );

      setShowconf({
        ...showconf,
        dados: '',
        status: false,
        temp: '',
      });

      PATCH_PENDENCIES(showconf.dados, props.atualizar, setShowbackdrop);
    }, 100);
  }

  function submitNewArchive() {
    let arquiveData = new FormData();

    arquiveData.append('file', document.document_additional);
    arquiveData.append(
      'title',
      document?.other_type ? document.other_type : document.type,
    );
    arquiveData.append('project_id', dados.id);

    POST_NEW_DOCUMENT(
      arquiveData,
      setListarchives,
      props.atualizar,
      setShowbackdrop,
    );

    setDocument({
      open: false,
      type: '',
      other_type: '',
      document_additional: '',
    });
  }

  function deleteArquivoVinculado() {
    setTimeout(async () => {
      setDocument({
        ...document,
        document_additional: '',
      });

      setListarchives((arquive) =>
        arquive.filter((doc) => doc.id !== showconf.dados.id),
      );

      setShowconf({
        ...showconf,
        dados: '',
        status: false,
        temp: '',
      });

      await DELETE_DOCUMENT_BY_ID(
        showconf.dados.id,
        props.atualizar,
        setShowbackdrop,
      );
    }, 100);
  }

  function confirmarArquivo() {
    setTimeout(async () => {
      setDocument({
        ...document,
        document_additional: '',
      });

      setListarchives((arquive) =>
        arquive.map((doc) =>
          doc.id === showconf.dados.id
            ? { ...doc, is_approved: !doc.is_approved }
            : doc,
        ),
      );

      setShowconf({
        ...showconf,
        dados: '',
        status: false,
        temp: '',
      });

      await PATCH_DOCUMENT_APROVE(
        showconf.dados,
        props.atualizar,
        setShowbackdrop,
      );
    }, 100);
  }

  function removerArquivo() {
    setTimeout(() => {
      setDocument({
        ...document,
        document_additional: '',
      });

      setShowconf({
        ...showconf,
        dados: '',
        status: false,
        temp: '',
      });

      // toast.success(`Arquivo ${showconf.dados.title} removido com sucesso`);
    }, 100);
  }

  function deleteInverters() {
    setTimeout(() => {
      setListinverters((inverter) =>
        inverter.filter((inv) => inv.id !== showconf.dados.id),
      );

      setShowconf({
        ...showconf,
        dados: '',
        status: false,
        temp: '',
      });

      DELETE_INVERTERS(showconf.dados.id, props.atualizar, setShowbackdrop);
    }, 100);
  }

  async function handleSubmitNewInverters(event) {
    event.preventDefault();

    await POST_INVERTERS(
      newInverters,
      props.atualizar,
      setListinverters,
      setShowbackdrop,
    );

    setNewInverters({
      new: false,
      project_id: dados?.id,
      amount: '',
      power_kwp: '',
      model: '',
    });
  }

  async function handleSubmitPendencia(event) {
    event.preventDefault();
    let body = new FormData();
    body.append('project_id', newPendencia.project_id);
    newPendencia.type === 'arquivo' &&
      body.append('file', document.document_additional);
    body.append('title', newPendencia.title);
    body.append('description', newPendencia.description);

    await POST_PENDENCIES(
      body,
      props.atualizar,
      setListpendencias,
      setShowbackdrop,
    );

    setNewPendencia({
      project_id: dados?.id,
      type: '',
      title: '',
      description: '',
    });

    removerArquivo();
    setOpenNewPendencia(false);
  }

  async function resetDados() {
    GET_DEALER_BY_STATE(
      props?.dados?.dealer?.state,
      setListConcess,
      setShowbackdrop,
    );

    setDados(reset);

    setListinverters(dados?.inverters);

    setNewInverters({
      new: false,
      project_id: dados?.id,
      amount: '',
      power_kwp: '',
      model: '',
    });
  }

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 9999,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container suspended={dados.suspended}>
              <header>
                <h1>Dados do Projeto</h1>
                <span>
                  Confira abaixo os dados do projeto com mais detalhes.
                </span>

                {props?.dados_edit && (
                  <div
                    className="div__listpendencias"
                    style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  >
                    <div className="div__pendencias">
                      <ErrorOutlineIcon className="icone_warn" />
                      <div className="div__info" style={{ flex: 10 }}>
                        <h5>
                          {props?.dados_edit.title}{' '}
                          {/* <span>{props?.dados_edit.type}</span> */}
                        </h5>

                        <p>{props?.dados_edit.description}</p>
                      </div>
                    </div>
                  </div>
                )}

                {editar && (
                  <div className="div__moreFiles">
                    {!props.newproject &&
                      !dados.suspended &&
                      !dados.finished_at && (
                        <button
                          type="button"
                          className="btn__newFile"
                          onClick={() => setEditar(!editar)}
                        >
                          Editar Projeto
                        </button>
                      )}
                  </div>
                )}

                <content>
                  <h4>Informações da Concessionária</h4>
                  {editar ? (
                    <div className="div__oneRow">
                      <p>
                        <label>Estado: </label> {dados.dealer?.state}
                      </p>
                      <p>
                        <label>Concessionária: </label>{' '}
                        {
                          listConcess?.find(
                            (concess) => concess.id === dados.dealer_id,
                          )?.name
                        }
                      </p>
                    </div>
                  ) : (
                    <div className="div__oneRow">
                      <select
                        required
                        name="state"
                        style={{ flex: 1 }}
                        value={dados.dealer?.state}
                        onChange={onInputChangeDealer}
                      >
                        <option value="" disabled selected>
                          Estado
                        </option>
                        {listStates.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        required
                        disabled={dados?.dealer?.state ? false : true}
                        name="dealer_id"
                        style={{ flex: 3 }}
                        value={dados.dealer_id}
                        onChange={onInputChangeDealer}
                      >
                        {dados?.dealer && (
                          <option value="" disabled selected>
                            Nome da concessionária
                          </option>
                        )}
                        {/* {dados.dealer && (
                          <option
                            value={dados.dealer?.name}
                            disabled
                            selected
                            style={{ textTransform: 'cap' }}
                          >
                            {dados.dealer?.name}
                          </option>
                        )} */}
                        {listConcess?.length > 0 &&
                          listConcess.map((concess, idx) => (
                            <option key={idx} value={concess.id}>
                              {concess.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}

                  <h4>Informações do Cliente</h4>
                  {editar ? (
                    <div className="div__oneRow">
                      {dados?.client_cpf ? (
                        <p>
                          <label>CPF: </label> {dados?.client_cpf}{' '}
                        </p>
                      ) : (
                        <p>
                          <label>CNPJ: </label> {dados?.client_cnpj}
                        </p>
                      )}

                      <p>
                        <label>Nome: </label> {dados?.client_name}
                      </p>
                    </div>
                  ) : (
                    <div
                      className="div__oneRow"
                      style={{ maxWidth: dados?.client_cpf ? '' : ' ' }}
                    >
                      <input
                        type="text"
                        placeholder={dados?.client_cpf ? 'CPF' : 'CNPJ'}
                        value={
                          dados?.client_cpf
                            ? dados?.client_cpf
                            : dados?.client_cnpj
                        }
                        onChange={onInputChangeClient}
                        name={dados?.client_cpf ? 'client_cpf' : 'client_cnpj'}
                        style={{ flex: 1 }}
                        required
                      />

                      <input
                        type="text"
                        placeholder={
                          dados?.client_cpf ? 'Nome completo' : 'Razão social'
                        }
                        value={dados?.client_name}
                        onChange={onInputChangeClient}
                        name="client_name"
                        style={{ flex: 3 }}
                        required
                      />
                    </div>
                  )}

                  <h4>Informações da Instalação - Unidade Geradora</h4>
                  {editar ? (
                    <div className="div__oneRow">
                      <p>
                        <label>Disjuntor: </label> {dados.breaker_type}
                      </p>
                      <p>
                        <label>Valor: </label> {dados.breaker_value}
                      </p>
                      <p>
                        <label>Coordenadas: </label> {dados.latitude}
                        {' , '}
                        {dados.longitude}
                      </p>
                    </div>
                  ) : (
                    <>
                      <div
                        className="div__oneRow"
                        // className="div__multiColumn"
                        style={{ maxWidth: '690px' }}
                      >
                        <select
                          required
                          name="breaker_type"
                          style={{ flex: 1 }}
                          value={dados.breaker_type}
                          onChange={onInputChange}
                        >
                          <option value="" disabled selected>
                            Tipo de disjuntor
                          </option>
                          <option value="monofásico">Monofásico</option>
                          <option value="bifásico">Bifásico</option>
                          <option value="trifásico">Trifásico</option>
                        </select>

                        <select
                          required
                          name="breaker_value"
                          style={{ flex: 2 }}
                          value={dados.breaker_value}
                          onChange={onInputChange}
                        >
                          <option value="" disabled selected>
                            Valor do disjuntor
                          </option>
                          {listdisjuntores.map((disj, idx) => (
                            <option value={disj} key={idx}>
                              {disj}
                            </option>
                          ))}
                        </select>

                        <input
                          type="text"
                          name="other_value_disjuntor"
                          value={dados.other_value_disjuntor}
                          placeholder="Valor do disjuntor (ex. 16 A)"
                          style={{
                            flex: 2,
                            visibility:
                              dados.value_disjuntor === 'Outro'
                                ? 'visible'
                                : 'hidden',
                          }}
                          onChange={onInputChange}
                          required={
                            dados.value_disjuntor === 'Outro' ? true : false
                          }
                        />
                        <div
                          className="pos_field"
                          style={{
                            visibility:
                              dados.value_disjuntor === 'Outro'
                                ? 'visible'
                                : 'hidden',
                          }}
                        >
                          A
                        </div>
                      </div>

                      <div
                        // className="div__multiColumn"
                        className="div__oneRow"
                        style={{ maxWidth: '530px' }}
                      >
                        <input
                          type="number"
                          placeholder={'Latitude do projeto'}
                          value={dados.latitude}
                          // step={'0.000001'}
                          onChange={onInputChange}
                          name={'latitude'}
                          style={{ flex: 1 }}
                          required
                        />
                        <input
                          type="number"
                          placeholder={'Longitude do projeto'}
                          value={dados.longitude}
                          onChange={onInputChange}
                          // step={'0.000001'}
                          name={'longitude'}
                          style={{ flex: 1 }}
                          required
                        />

                        <div className="div__moreOptions">
                          <button
                            type="button"
                            className="btn__newDocument"
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                            }}
                            onClick={() => setShowcoord(!showcoord)}
                          >
                            <SearchIcon /> Buscar
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  <h4>
                    Especificações do Projeto
                    {!dados?.finished_at && editar && !dados.suspended && (
                      <div className="div__moreOptions">
                        <button
                          type="button"
                          className="btn__newDocument"
                          onClick={() => {
                            // aux.push({ document_rateio: '' });
                            // console.log(aux);
                            setNewInverters((inverters) => ({
                              ...inverters,
                              new: true,
                            }));
                          }}
                        >
                          + Adicionar Inversor
                        </button>
                      </div>
                    )}
                  </h4>

                  {editar ? (
                    <>
                      <div className="div__oneRow">
                        <p>
                          <label>Potência Total do Projeto: </label>{' '}
                          {dados?.total_project_power} kWP
                        </p>
                      </div>
                      <div className="div__oneRow">
                        <p>
                          <label>Qtd. de Placas: </label> {dados?.amount_panels}
                        </p>
                        <p>
                          <label>Potência: </label> {dados?.panel_power} KWP
                        </p>
                        <p>
                          <label>Modelo: </label> {dados?.panel_model}
                        </p>
                      </div>
                      <br />

                      {newInverters.new && (
                        <form onSubmit={handleSubmitNewInverters}>
                          <div
                            className="div__oneRow"
                            style={{ maxWidth: '100%' }}
                          >
                            <input
                              type="text"
                              name="amount"
                              value={newInverters.amount}
                              placeholder="Qtd. de inversores"
                              style={{
                                flex: 1,
                                maxWidth: window.innerWidth > 675 && '202px',
                              }}
                              onChange={(e) => {
                                setNewInverters((inv) => ({
                                  ...inv,
                                  [e.target.name]: Number(
                                    mask(e.target.value, '999'),
                                  ),
                                }));
                              }}
                              required
                            />
                            <div className="pos_field">Inversor(es)</div>

                            <input
                              type="text"
                              name="power_kwp"
                              value={newInverters.power_kwp}
                              placeholder="Potência"
                              style={{
                                flex: 1,
                                maxWidth: window.innerWidth > 675 && '202px',
                              }}
                              onChange={(e) => {
                                setNewInverters((inv) => ({
                                  ...inv,
                                  [e.target.name]: Number(
                                    mask(e.target.value, '999'),
                                  ),
                                }));
                              }}
                              required
                            />
                            <div className="pos_field">kWp</div>

                            <input
                              type="text"
                              name="model"
                              value={newInverters.model}
                              placeholder="Modelo dos inversores"
                              style={{ flex: 2 }}
                              onChange={(e) => {
                                setNewInverters((inv) => ({
                                  ...inv,
                                  [e.target.name]: e.target.value,
                                }));
                              }}
                              required
                            />
                          </div>
                          <div
                            className="div__moreFiles"
                            style={{ marginTop: '1rem' }}
                          >
                            <button
                              type="button"
                              className="btn__cancel"
                              onClick={() =>
                                setNewInverters({
                                  new: false,
                                  project_id: dados?.id,
                                  amount: '',
                                  power_kwp: '',
                                  model: '',
                                })
                              }
                            >
                              Cancelar
                            </button>

                            <button type="submit" className="btn__newFile">
                              Adicionar
                            </button>
                          </div>
                        </form>
                      )}

                      {listinverters.length > 0
                        ? listinverters?.map((inverter) => (
                            <fieldset
                              key={inverter.id}
                              style={{ marginBottom: '1rem' }}
                              className="div__oneRow"
                            >
                              <p>
                                <label>Qtd. de Inversores: </label>{' '}
                                {inverter.amount}
                              </p>
                              <p>
                                <label>Potência: </label> {inverter.power_kwp}{' '}
                                kWP
                              </p>
                              <p>
                                <label>Modelo: </label> {inverter.model}
                              </p>

                              <legend>
                                <HighlightOffIcon
                                  style={{
                                    color: 'var(--delete)',
                                    cursor: 'pointer',
                                    zIndex: '99991',
                                  }}
                                  onClick={() => {
                                    setShowconf({
                                      ...showconf,
                                      status: true,
                                      dados: inverter,
                                      delete: true,
                                      pendencia: false,
                                      arquivoVinculado: false,
                                      removeDocument: false,
                                      removeInverter: true,
                                      width: 'sm',
                                      title: 'Confirmar',
                                      description: `Deseja realmente remover o inversor do projeto ?`,
                                      btn_conf: 'Sim',
                                      btn_cancel: 'Não',
                                    });
                                  }}
                                />
                              </legend>
                            </fieldset>
                          ))
                        : !newInverters.new && (
                            <div className="div__nada">
                              Nenhum inversor cadastrado
                            </div>
                          )}
                    </>
                  ) : (
                    <>
                      <div
                        className="div__oneRow"
                        style={{ maxWidth: '700px', width: '100%' }}
                      >
                        <input
                          type="text"
                          name="total_project_power"
                          value={dados?.total_project_power}
                          placeholder="Potência total do projeto em kWp"
                          style={{
                            flex: 1,
                            maxWidth: window.innerWidth > 675 && '280px',
                          }}
                          onChange={onInputChangeSpecifications}
                          max="1000"
                          min="1"
                          disabled
                          required
                        />
                        <div className="pos_field">kWp</div>
                      </div>
                      <div className="div__oneRow" style={{ maxWidth: '100%' }}>
                        <input
                          type="text"
                          name="amount_panels"
                          value={dados?.amount_panels}
                          placeholder="Qtd. de placas"
                          style={{ flex: 1.19 }}
                          onChange={onInputChangeSpecifications}
                          required
                        />
                        <div className="pos_field">Placa(s)</div>

                        <input
                          type="text"
                          name="panel_power"
                          value={dados?.panel_power}
                          placeholder="Potência"
                          style={{ flex: 1 }}
                          onChange={onInputChangeSpecifications}
                          required
                        />

                        <div className="pos_field">Wp</div>

                        <input
                          type="text"
                          name="panel_model"
                          value={dados.panel_model}
                          placeholder="Modelo das placas"
                          style={{ flex: 2 }}
                          onChange={onInputChangeSpecifications}
                        />
                      </div>

                      {listinverters?.map((inverter, idx) => (
                        <div
                          key={inverter.id}
                          className="div__oneRow"
                          style={{ maxWidth: '100%' }}
                        >
                          <input
                            type="text"
                            name="amount"
                            value={inverter.amount}
                            placeholder="Qtd. de inversores"
                            style={{
                              flex: 1,
                              maxWidth: window.innerWidth > 675 && '202px',
                            }}
                            onChange={(e) => {
                              setListinverters(
                                listinverters.map((inv, id) => {
                                  if (id === idx) {
                                    return {
                                      ...inv,
                                      [e.target.name]: e.target.value,
                                    };
                                  } else {
                                    return { ...inv };
                                  }
                                }),
                              );
                            }}
                            required
                          />
                          <div className="pos_field">Inversor(es)</div>

                          <input
                            type="text"
                            name="power_kwp"
                            value={inverter.power_kwp}
                            placeholder="Potência"
                            style={{
                              flex: 1,
                              maxWidth: window.innerWidth > 675 && '202px',
                            }}
                            onChange={(e) => {
                              setListinverters(
                                listinverters.map((inv, id) => {
                                  if (id === idx) {
                                    return {
                                      ...inv,
                                      [e.target.name]: e.target.value,
                                    };
                                  } else {
                                    return { ...inv };
                                  }
                                }),
                              );
                            }}
                            required
                          />
                          <div className="pos_field">kWp</div>

                          <input
                            type="text"
                            name="model"
                            value={inverter.model}
                            placeholder="Modelo dos inversores"
                            style={{ flex: 2 }}
                            onChange={(e) => {
                              setListinverters(
                                listinverters.map((inv, id) => {
                                  if (id === idx) {
                                    return {
                                      ...inv,
                                      [e.target.name]: e.target.value,
                                    };
                                  } else {
                                    return { ...inv };
                                  }
                                }),
                              );
                            }}
                          />
                        </div>
                      ))}
                    </>
                  )}

                  {editar ? (
                    <div className="">
                      {!dados?.finished_at ? (
                        <h4>Previsão de Conclusão</h4>
                      ) : (
                        <h4>Finalizado em</h4>
                      )}
                      {!dados.suspended ? (
                        dados.deadline || dados.finished_at ? (
                          <p style={{ margin: '0rem' }}>
                            {new Date(
                              dados.finished_at
                                ? dados.finished_at
                                : dados.deadline,
                            ).toLocaleDateString('pt-BR')}
                          </p>
                        ) : (
                          <p>--/--/----</p>
                        )
                      ) : (
                        <p>--/--/----</p>
                      )}
                    </div>
                  ) : (
                    <div>
                      {!dados?.finished_at ? (
                        <h4>Previsão de Conclusão</h4>
                      ) : (
                        <h4>Finalizado em</h4>
                      )}                      
                        
                      <input
                        id="deadline"
                        name="deadline"
                        type="date"
                        min={new Date().toISOString().split('T')[0]}
                        value={dados?.deadline}
                          onChange={(e) => {
                            console.log(e.target.value)
                            setDados({
                              ...dados, deadline: e.target.value
                            })
                          }}
                        style={{maxWidth:'18.75rem'}}
                        disabled={!!dados?.finished_at}
                      />
                    </div>
                  )}

                  {!editar && !dados.suspended && !dados?.finished_at && (
                    <div
                      className="div__moreFiles"
                      style={{ marginTop: '1rem' }}
                    >
                      <button
                        type="button"
                        className="btn__cancel"
                        onClick={() => {
                          resetDados();
                          setEditar((edit) => !edit);
                        }}
                      >
                        Cancelar
                      </button>

                      <button
                        type="button"
                        className="btn__newFile"
                        onClick={handleSubmitEditProject}
                      >
                        Salvar edição
                      </button>
                    </div>
                  )}
                  <br />
                  <h4>
                    Pendências
                    {!dados?.finished_at && editar && !dados.suspended && (
                      <div className="div__moreOptions">
                        <button
                          type="button"
                          className="btn__newDocument"
                          onClick={() => setOpenNewPendencia(true)}
                        >
                          + Adicionar nova pendência
                        </button>
                      </div>
                    )}
                  </h4>
                  {openNewPendencia &&
                    (!dados?.finished_at || !dados?.suspended) && (
                      <form
                        method="POST"
                        action=""
                        onSubmit={handleSubmitPendencia}
                      >
                        <div
                          className="div__oneRow"
                          style={{ maxWidth: '100%' }}
                        >
                          <input
                            type="text"
                            name="title"
                            placeholder="Título da pendência"
                            value={newPendencia.title}
                            onChange={(event) => {
                              setNewPendencia((pendencia) => ({
                                ...pendencia,
                                [event.target.name]: event.target.value,
                              }));
                            }}
                            style={{ flex: 1 }}
                            required
                          />
                          <select
                            required
                            name="type"
                            style={{ flex: 1 }}
                            value={newPendencia.type}
                            onChange={(event) => {
                              setNewPendencia((pendencia) => ({
                                ...pendencia,
                                [event.target.name]: event.target.value,
                              }));
                              removerArquivo();
                            }}
                          >
                            <option value="" disabled selected>
                              Tipo de pendência
                            </option>
                            <option value="mensagem">Informação</option>
                            <option value="arquivo">Arquivo</option>
                          </select>
                        </div>
                        <div className="div__oneRow">
                          {/* <label for="note">Nota do processo</label> */}
                          <textarea
                            id="description"
                            name="description"
                            placeholder="Nota de pendência"
                            value={newPendencia.description}
                            onChange={(event) => {
                              setNewPendencia((pendencia) => ({
                                ...pendencia,
                                [event.target.name]: event.target.value,
                              }));
                            }}
                            required
                          />
                        </div>

                        {newPendencia.type === 'arquivo' ? (
                          <>
                            <input
                              type="file"
                              id={`document_additional`}
                              name={`document_additional`}
                              required
                              onChange={(event) => {
                                if (event.target.files[0]) {
                                  if (
                                    event.target.files[0].type ===
                                      'application/pdf' ||
                                    event.target.files[0].type ===
                                      'image/png' ||
                                    event.target.files[0].type === 'image/jpeg'
                                  ) {
                                    setDocument({
                                      ...document,
                                      document_additional:
                                        event.target.files[0],
                                    });
                                  } else {
                                    toast.error(
                                      'Tipo de arquivo inválido, insira .pdf, .png ou .jpeg',
                                    );
                                  }
                                }
                              }}
                            />

                            <label
                              className={
                                document.document_additional
                                  ? 'div__newfieldarchive div__selectedFieldArchive'
                                  : 'div__newfieldarchive'
                              }
                              htmlFor={
                                document.document_additional
                                  ? ''
                                  : `document_additional`
                              }
                              onClick={() => {
                                document.document_additional &&
                                  setShowconf({
                                    ...showconf,
                                    status: true,
                                    dados: document,
                                    temp: `document_additional`,
                                    removeDocument: true,
                                    pendencia: false,
                                  });
                              }}
                            >
                              <div className="div__newfieldarchive_in">
                                <div className="div__dataArchive">
                                  <AttachFileIcon />
                                  <div className="div__info">
                                    <p style={{ textTransform: 'uppercase' }}>
                                      <LabelIcon
                                        style={{
                                          fontSize: '1rem',
                                          marginBottom: '-0.2rem',
                                        }}
                                      />{' '}
                                      {document.type
                                        ? document.type
                                        : ' . . . '}{' '}
                                      {document.other_type
                                        ? `(${document.other_type})`
                                        : ''}
                                    </p>
                                    <p>
                                      <b>Nome: </b>
                                      {document.document_additional
                                        ? document.document_additional.name
                                        : '...'}
                                    </p>
                                    <p>
                                      <b>Tamanho: </b>
                                      {document.document_additional
                                        ? (
                                            document.document_additional.size /
                                            (1024 * 1024)
                                          ).toFixed(2) + 'Mb'
                                        : '...'}
                                    </p>
                                    <p>
                                      <b>Tipo de arquivo: </b>
                                      {document.document_additional
                                        ? document.document_additional.type.split(
                                            '/',
                                          )[1]
                                        : '...'}
                                    </p>
                                  </div>
                                </div>

                                <div className="div__env_archive">
                                  {document.document_additional ? (
                                    <div>
                                      <DeleteIcon
                                        style={{ fontSize: '1.5rem' }}
                                      />{' '}
                                      Remover arquivo
                                      <br /> selecionado
                                    </div>
                                  ) : (
                                    <div>
                                      <BackupIcon /> Clique para anexar o
                                      arquivo
                                      <br />
                                      (.png .jpeg .pdf)
                                    </div>
                                  )}
                                </div>
                              </div>
                            </label>

                            <div className="div__moreFiles">
                              <button
                                type="button"
                                className="btn__cancel"
                                onClick={() => {
                                  setNewPendencia({
                                    project_id: dados?.id,
                                    type: '',
                                    title: '',
                                    description: '',
                                  });

                                  removerArquivo();
                                  setOpenNewPendencia(false);
                                }}
                              >
                                Cancelar
                              </button>
                              <button
                                type="submit"
                                className="btn__newFile"
                                style={{
                                  pointerEvents:
                                    !document.document_additional && 'none',
                                  filter:
                                    !document.document_additional &&
                                    'grayscale(1)',
                                }}
                              >
                                Cadastrar nova pendência
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="div__moreFiles">
                            <button
                              type="button"
                              className="btn__cancel"
                              onClick={() => {
                                setNewPendencia({
                                  project_id: dados?.id,
                                  type: '',
                                  title: '',
                                  description: '',
                                });
                                setOpenNewPendencia(false);
                              }}
                            >
                              Cancelar
                            </button>
                            <button
                              type="submit"
                              className="btn__newFile"
                              style={{
                                marginTop: '1rem',
                              }}
                            >
                              Cadastrar nova pendência
                            </button>
                          </div>
                        )}
                      </form>
                    )}
                  <div className="div__listpendencias">
                    {listpendencias?.length > 0
                      ? listpendencias?.map((pendencia, idx) => (
                          <div key={idx} className="div__pendencias">
                            <div className="div__info" style={{ flex: 10 }}>
                              <h5>
                                {pendencia.resolved ? (
                                  <CheckCircleOutlineIcon className="icone_success" />
                                ) : (
                                  <ErrorOutlineIcon className="icone_warn" />
                                )}
                                {pendencia.title}{' '}
                                <span>
                                  {pendencia.file ? 'Arquivo' : 'Informação'}
                                </span>
                              </h5>

                              <p>{pendencia.description}</p>
                              {pendencia.customer_comment && (
                                <div className="div__respost_pendence">
                                  <div className="div__comment">
                                    <strong>R:</strong>{' '}
                                    {pendencia.customer_comment}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div
                              className="div__options_pendencias"
                              style={{
                                flex: 2,
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              {' '}
                              {!pendencia.resolved ? (
                                !dados?.finished_at &&
                                !dados.suspended && (
                                  <button
                                    onClick={() => {
                                      setShowconf({
                                        ...showconf,
                                        status: true,
                                        dados: pendencia,
                                        btn_cancel: 'Voltar',
                                        description:
                                          'Deseja confirmar à resolução da pendência?',
                                        delete: false,
                                        arquivoVinculado: false,
                                        pendencia: true,
                                      });
                                    }}
                                  >
                                    <CheckIcon />
                                  </button>
                                )
                              ) : (
                                <p>Resolvido</p>
                              )}
                              {pendencia.file && (
                                <button
                                  onClick={() => {
                                    let aux = pendencia.file.split('.');
                                    // console.log('Teste: ', aux);
                                    if (aux[1] === 'pdf') {
                                      setShowPDF({
                                        ...showPDF,
                                        open: true,
                                        file_pdf: pendencia.file_url,
                                      });
                                    } else {
                                      window.open(pendencia.file_url);
                                    }
                                  }}
                                >
                                  {/* <GetAppIcon /> */}
                                  <VisibilityOutlinedIcon />
                                </button>
                              )}
                              <button
                                className="button_delete"
                                onClick={() => {
                                  setShowconf({
                                    ...showconf,
                                    status: true,
                                    dados: pendencia,
                                    description:
                                      'Deseja realmente remover esta pendência?',
                                    delete: true,
                                    arquivoVinculado: false,
                                    pendencia: true,
                                  });
                                }}
                              >
                                <DeleteOutlineOutlined />
                              </button>
                            </div>
                          </div>
                        ))
                      : !openNewPendencia && (
                          <div className="div__nada">
                            Nenhuma pendência cadastrada
                          </div>
                        )}
                  </div>

                  <br />
                  <h4>
                    Arquivos vinculados
                    {!dados?.finished_at && editar && !dados.suspended && (
                      <div className="div__moreOptions">
                        <button
                          type="button"
                          className="btn__newDocument"
                          style={{
                            display: document.open ? 'none' : 'initial',
                          }}
                          onClick={() =>
                            setDocument({
                              ...document,
                              open: true,
                              type: 'Novo arquivo',
                            })
                          }
                        >
                          + Novo arquivo
                        </button>
                      </div>
                    )}
                  </h4>

                  {document.open && (
                    <div className="div__field_moreFiles">
                      <div
                        style={{
                          // flex: 1,
                          // width: '100%',
                          textAlign: 'left',
                          margin: '0rem 2rem',
                        }}
                      >
                        Novo documento
                      </div>
                    </div>
                  )}

                  {document.open && (
                    <input
                      type="file"
                      id={`document_additional`}
                      name={`document_additional`}
                      required
                      onChange={(event) => {
                        if (event.target.files[0]) {
                          if (
                            event.target.files[0].type === 'application/pdf' ||
                            event.target.files[0].type === 'image/png' ||
                            event.target.files[0].type === 'image/jpeg'
                          ) {
                            setDocument({
                              ...document,
                              document_additional: event.target.files[0],
                            });
                          } else {
                            toast.error(
                              'Tipo de arquivo inválido, insira .pdf, .png ou .jpeg',
                            );
                          }
                        }
                      }}
                    />
                  )}

                  {document.type && (
                    <div className="div__remove_field_arch">
                      <button
                        type="button"
                        className={
                          document.document_additional
                            ? 'btn__remove_field btn__remove_field_disabled'
                            : 'btn__remove_field'
                        }
                        onClick={() =>
                          setDocument({
                            open: false,
                            type: '',
                            other_type: '',
                            document_additional: '',
                          })
                        }
                      >
                        Remover campo
                      </button>
                    </div>
                  )}

                  {document.type && (
                    <label
                      className={
                        document.document_additional
                          ? 'div__newfieldarchive div__selectedFieldArchive'
                          : 'div__newfieldarchive'
                      }
                      htmlFor={
                        document.document_additional
                          ? ''
                          : `document_additional`
                      }
                      onClick={(event) => {
                        document.document_additional &&
                          setDocument({
                            ...document,
                            open: false,
                          });

                        document.document_additional &&
                          setTimeout(
                            () =>
                              setDocument({
                                ...document,
                                open: true,
                                document_additional: '',
                              }),
                            100,
                          );
                      }}
                    >
                      <div className="div__newfieldarchive_in">
                        <div className="div__dataArchive">
                          <AttachFileIcon />
                          <div className="div__info">
                            <p style={{ textTransform: 'uppercase' }}>
                              <LabelIcon
                                style={{
                                  fontSize: '1rem',
                                  marginBottom: '-0.2rem',
                                }}
                              />{' '}
                              {document.type ? document.type : ' . . . '}{' '}
                              {document.other_type
                                ? `(${document.other_type})`
                                : ''}
                            </p>
                            <p>
                              <b>Nome: </b>
                              {document.document_additional
                                ? document.document_additional.name
                                : '...'}
                            </p>
                            <p>
                              <b>Tamanho: </b>
                              {document.document_additional
                                ? (
                                    document.document_additional.size /
                                    (1024 * 1024)
                                  ).toFixed(2) + 'Mb'
                                : '...'}
                            </p>
                            <p>
                              <b>Tipo de arquivo: </b>
                              {document.document_additional
                                ? document.document_additional.type.split(
                                    '/',
                                  )[1]
                                : '...'}
                            </p>
                          </div>
                        </div>

                        <div className="div__env_archive">
                          {document.document_additional ? (
                            <div>
                              <DeleteIcon style={{ fontSize: '1.5rem' }} />{' '}
                              Remover arquivo
                              <br /> selecionado
                            </div>
                          ) : (
                            <div>
                              <BackupIcon /> Clique para anexar a conta de
                              energia
                              <br />
                              (.png .jpeg .pdf)
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  )}
                  {document.type && (
                    <div className="div__moreFiles">
                      <button
                        type="button"
                        className="btn__newFile"
                        style={{
                          pointerEvents:
                            !document.document_additional && 'none',
                          filter:
                            !document.document_additional && 'grayscale(1)',
                        }}
                        onClick={() => submitNewArchive()}
                      >
                        Enviar novo arquivo
                      </button>
                    </div>
                  )}

                  <div className="div__listArchives">
                    {listarchives?.length > 0 ? (
                      listarchives?.slice(0, listidx)?.map((archive, idx) => (
                        <div key={idx} className="div__rowArchive">
                          <div className="div__icon">
                            <DescriptionIcon />
                          </div>
                          <div className="div__data" style={{ flex: 4 }}>
                            <p>
                              <strong>Título: </strong> {archive.title}
                            </p>
                            <p>
                              <strong>Arquivo: </strong> {archive.file}
                            </p>
                            <p>
                              Envio em{' '}
                              {new Date(archive.created_at).toLocaleDateString(
                                'pt-br',
                                {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                },
                              )}
                            </p>
                          </div>

                          <div className="div__options" style={{ flex: 2 }}>
                            {!archive.is_approved ? (
                              !dados?.finished_at &&
                              !dados?.suspended && (
                                <button
                                  onClick={() => {
                                    setShowconf({
                                      ...showconf,
                                      status: true,
                                      dados: archive,
                                      description:
                                        'Deseja confirmar à aprovação deste arquivo?',
                                      delete: false,
                                      pendencia: false,
                                      arquivoVinculado: true,
                                    });
                                  }}
                                >
                                  <CheckIcon />
                                </button>
                              )
                            ) : (
                              <p>Aprovado</p>
                            )}

                            <button
                              onClick={() => {
                                let aux = archive.file.split('.');

                                if (aux[1] === 'pdf') {
                                  setShowPDF({
                                    ...showPDF,
                                    open: true,
                                    file_pdf: archive.file_url,
                                  });
                                } else {
                                  window.open(archive.file_url);
                                }
                              }}
                            >
                              <VisibilityOutlinedIcon />
                            </button>

                            {!dados?.finished_at && !dados.suspended && (
                              <button
                                className="button_delete"
                                onClick={() => {
                                  setShowconf({
                                    ...showconf,
                                    status: true,
                                    dados: archive,
                                    delete: true,
                                    pendencia: false,
                                    arquivoVinculado: true,
                                  });
                                }}
                              >
                                <DeleteOutlineOutlined />
                              </button>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="div__nada">
                        Nenhuma arquivo cadastrado
                      </div>
                    )}

                    {listarchives?.length > listidx && (
                      <div
                        className="div__viewMore"
                        onClick={() => setListidx(listidx + 3)}
                      >
                        Ver mais
                      </div>
                    )}
                  </div>
                </content>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Voltar
            </button>
            {/* <button onClick={handleClose} className="btn__register">
              Aplicar alterações
            </button> */}
          </FielButtons>
        </DialogActions>
      </Dialog>

      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({ ...showconf, dados: '', status: false, temp: '' })
          }
          confirmar={() => {
            if (showconf.dados) {
              {
                if (showconf.pendencia) {
                  !showconf.delete ? confirmarPendencia() : deletePendencia();
                } else if (showconf.arquivoVinculado) {
                  !showconf.delete
                    ? confirmarArquivo()
                    : deleteArquivoVinculado();
                } else if (showconf.removeDocument) {
                  removerArquivo();
                } else if (showconf.removeInverter) {
                  deleteInverters();
                }
              }
            }
          }}
        />
      )}

      {showcoord && (
        <Dialogoencontrarlocalizacao
          fechar={() => setShowcoord(false)}
          dados={dados}
          confirmar={(a) =>
            setDados({
              ...dados,
              longitude: a.lng,
              latitude: a.lat,
            })
          }
        />
      )}

      {showPDF?.open && (
        <Modalpdf
          fechar={() => setShowPDF({ open: false, file_pdf: '' })}
          fileUrl={showPDF.file_pdf}
        />
      )}

      {showbackdrop && <SimpleBackdrop />}
    </div>
  );
}
