import { useState } from 'react';

import { Container, FielButtons } from './styles';

import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import {
  POST_STAGE,
  PUT_STAGE,
  PATCH_FINISHED_PROJECT,
} from '../../services/endpoints';
import { listetapas } from '../../config';
import SimpleBackdrop from '../Backdrop';

function Chatbox(props) {
  const [showbackdrop, setShowbackdrop] = useState(false);

  const [dados, setDados] = useState({
    stage_process: '',
    new_stage_process: '',
    deadline: '',
    description: '',
  });
  const maisatual = (a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  };

  let auxStage = props.dados.stages.sort(maisatual);
  let auxStatus = auxStage[0]?.status.sort(maisatual);

  // console.log('PROPS: ', props);
  // console.log('auxStage: ', auxStage);
  // console.log('auxStatus: ', auxStatus);

  const [edit, setEdit] = useState(false);

  function formatDate(value) {
    let data = new Date(value);

    function adicionaZero(numero) {
      if (numero <= 9) return '0' + numero;
      else return numero;
    }

    let newDate =
      adicionaZero(data.getFullYear().toString()) +
      '-' +
      adicionaZero((data.getMonth() + 1).toString()) +
      '-' +
      adicionaZero(data.getDate().toString());

    return newDate;
  }

  function handleChange({ target }) {
    setDados({ ...dados, [target.name]: target.value });
  }

  const handleSubmitFase = async (event) => {
    event.preventDefault();
    let auxDate = dados?.deadline
      ? new Date(dados.deadline + ' 23:59').toISOString()
      : new Date().toISOString();
    await setTimeout(async () => {
      if (edit) {
        await PUT_STAGE(
          auxStage[0].id,
          {
            position: auxStage[0].position,
            title:
              dados.stage_process === 'outro'
                ? dados.new_stage_process
                : dados.stage_process,
            deadline: auxDate,
          },
          props.atualizar,
          setShowbackdrop,
        );
        props.fechar();
      } else {
        await POST_STAGE(
          {
            project_id: props.dados.id,
            position: 1,
            title:
              dados.stage_process === 'outro'
                ? dados.new_stage_process
                : dados.stage_process,
            deadline: auxDate,
          },
          dados.description,
          props.atualizar,
          setShowbackdrop,
          auxStage[0].id,
        );

        if (
          dados.stage_process === '6 - Projeto Aprovado' ||
          dados.stage_process === '7 - Projeto Reprovado'
        ) {
          await PATCH_FINISHED_PROJECT(
            props.dados.id,
            { finished_at: new Date().toISOString() },
            props.atualizar,
            setShowbackdrop,
          );
        }

        props.fechar();
      }
    }, 100);
  };

  return (
    <Container>
      <h3>Etapas do Projeto</h3>
      <form action="" method="POST" onSubmit={handleSubmitFase}>
        <div className="div__corpo">
          <label
            htmlFor="name_project"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            Projeto
          </label>
          <span>
            {' '}
            <AssignmentOutlinedIcon /> {props.dados.client_name}
          </span>
          <label htmlFor="stage_process">Etapa do projeto</label>
          <select
            id="stage_process"
            name="stage_process"
            value={!edit ? dados.stage_process : auxStage[0].title}
            onChange={handleChange}
            disabled={edit}
          >
            <option value="" disabled selected>
              Selecione o etapa
            </option>
            {listetapas?.map((item, idx) => (
              <option key={idx} value={item}>
                {item}
              </option>
            ))}
            {listetapas.every(
              (fase) =>
                fase !== dados.stage_process && dados.stage_process !== '',
            ) && (
              <option value={dados.stage_process}>{dados.stage_process}</option>
            )}
            {/* {dados.stage_process !== 'outro' && (
              <option value="outro">outro</option>
            )} */}
          </select>

          {listetapas.every(
            (fase) =>
              fase !== dados.stage_process && dados.stage_process !== '',
          ) &&
            !edit && (
              <>
                <label htmlFor="stage_process">Nova etapa do projeto</label>
                <input
                  id="new_stage_process"
                  name="new_stage_process"
                  value={dados.new_stage_process}
                  onChange={handleChange}
                />
              </>
            )}

          {dados.stage_process !== '6 - Projeto Aprovado' &&
            dados.stage_process !== '7 - Projeto Reprovado' && (
              <>
                <label htmlFor="deadline">Data limite</label>
                <input
                  id="deadline"
                  name="deadline"
                  type="date"
                  min={new Date().toISOString().split('T')[0]}
                  value={dados.deadline}
                  onChange={handleChange}
                />
              </>
            )}

          <label htmlFor="description">Descrição da etapa</label>
          <textarea
            id="description"
            name="description"
            value={dados.description}
            onChange={handleChange}
            disabled={edit}
          />

          {!edit && (
            <div className="div__moreOptions">
              <button
                type="button"
                className="btn__newDocument"
                onClick={() => {
                  setEdit(true);
                  setDados(() => {
                    let dataFormatada = formatDate(auxStage[0]?.deadline);

                    return {
                      stage_process: auxStage[0]?.title,
                      new_stage_process: '',
                      deadline: dataFormatada,
                      description: auxStatus[0]?.description,
                    };
                  });
                }}
              >
                Editar etapa atual
              </button>
            </div>
          )}

          <FielButtons>
            <button
              type="button"
              className="btn__retornar"
              onClick={() => {
                if (edit) {
                  setEdit(false);
                  setDados({
                    stage_process: '',
                    new_stage_process: '',
                    deadline: '',
                    description: '',
                  });
                } else {
                  props.fechar();
                }
              }}
            >
              Cancelar
            </button>
            <button type="submit" className="btn__register">
              {edit ? 'Editar' : 'Cadastrar'}
            </button>
          </FielButtons>
        </div>
      </form>
      {showbackdrop && <SimpleBackdrop />}
    </Container>
  );
}

export default Chatbox;
