import { useCallback, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { toast } from 'react-toastify'

import api from '../../services/api';

import { Loading } from '../../icons';

import { Container, FielButtons, Form } from './styles';

export default function ModalEditLoginData(props) {
  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => props?.close(), 100);
  }, [setOpen, props]);

  const [isValidatingLoginData, setIsValidatingLoginData] = useState(false);

  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleChangeNewEmail = useCallback((event) => {
    setNewEmail(event?.target?.value ?? '');
  }, [setNewEmail]);

  const handleChangeNewPassword = useCallback((event) => {
    setNewPassword(event?.target?.value ?? '');
  }, [setNewPassword]);

  const handleValidateLoginData = useCallback(() => {
    setIsValidatingLoginData(true);

    api.put(
      '/login-data/validate',
      {
        email: props?.loginData?.email,
        newEmail: !!newEmail ? newEmail : undefined,
        newPassword: !!newPassword ? newPassword : undefined,
      },
    )
      .then(async (res) => {
        toast.success('Dados de login atualizados com sucesso');
        props?.onChangeLoginData(res.data);
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
          return;
        }

        toast.error('Erro ao editar dados de login');
      })
      .finally(() => setIsValidatingLoginData(false));
  }, [newEmail, newPassword, props?.onChangeLoginData, handleClose]); // eslint-disable-line

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="sm"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        style={{ zIndex: 9999991 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <h1>Editar dados de login</h1>
              <div className='div__oneRow'>
                <strong>E-mail:</strong> {props?.loginData?.email}
              </div>
              {props?.loginData?.user?.person?.full_name && (
                <div className='div__oneRow'>
                  <strong>Nome:</strong> {props?.loginData?.user?.person?.full_name}
                </div>
              )}
              {props?.loginData?.user?.person?.cpf && (
                <div className='div__oneRow'>
                  <strong>CPF:</strong> {props?.loginData?.user?.person?.cpf}
                </div>
              )}
              <div className='div__oneRow'>
                <strong>Status:</strong>
                <span style={{ color: props?.loginData?.validated_user ? 'var(--success)' : 'var(--warn)' }}>
                  {' '}{props?.loginData?.validated_user ? 'Validado' : 'Não Validado'}
                </span>
              </div>
              <Form>
                <input
                  type="text"
                  name="newEmail"
                  value={newEmail}
                  onChange={handleChangeNewEmail}
                  style={{ maxWidth: 250 }}
                  placeholder='Novo e-mail'
                />
                <input
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  onChange={handleChangeNewPassword}
                  style={{ maxWidth: 250 }}
                  placeholder='Nova senha'
                />
              </Form>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleValidateLoginData}
              className="btn_download"
              color="primary"
              style={{
                minWidth: '100px',
                cursor: (!newEmail && !newPassword) ? 'not-allowed' : 'pointer'
              }}
              disabled={isValidatingLoginData || (!newEmail && !newPassword)}
            >
              {isValidatingLoginData && (<Loading
                width="20px"
                height="20px"
                style={{ margin: "0" }}
                circlecolor='white'
              />)}
              Salvar
            </button>
            <button
              onClick={handleClose}
              className="btn_return"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Voltar
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
