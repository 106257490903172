import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { mask } from 'remask';
import { listStates } from '../../config';

import { Container, FielButtons } from './styles';
import {
  POST_COMPANY_ADDRESS,
  PUT_COMPANIES,
  SHOW_ADDRESS,
} from '../../services/endpoints';
// import apiIBGECidades from '../../services/apiIBGECidades';
// import { toast } from 'react-toastify';
import SimpleBackdrop from '../Backdrop';

export default function Alterardados(props) {
  const [open, setOpen] = React.useState(true);
  const [dados, setDados] = React.useState({ ...props.dados });
  const [listAddress, setListAddress] = React.useState(null);
  // const [listCites, setListCites] = React.useState(null);
  const [editar, setEditar] = React.useState(false);
  const [newAddress, setNewAddress] = React.useState({
    status: false,
    address: {
      street: '',
      number: '',
      district: '',
      city: '',
      state: '',
      zip_code: '',
      complement: '',
    },
  });

  const [showbackdrop, setShowbackdrop] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const [updateChange, setUpdateChange] = React.useState({
    company: false,
    address: false,
  });

  const handleChange = (e) => {
    setUpdateChange({ ...updateChange, company: true });
    if (e.target.name === 'number') {
      setDados({ ...dados, [e.target.name]: mask(e.target.value, '9999999') });
    } else if (e.target.name === 'cpf') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '999.999.999-99'),
      });
    } else if (e.target.name === 'landline') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '(99) 9999-9999'),
      });
    } else if (e.target.name === 'telephone') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '(99) 99999-9999'),
      });
    } else if (e.target.name === 'cnpj') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '99.999.999/9999-99'),
      });
    } else {
      setDados({ ...dados, [e.target.name]: e.target.value });
    }
  };

  const handleChangeNew = (e) => {
    if (e.target.name === 'number') {
      setNewAddress({
        ...newAddress,
        address: {
          ...newAddress.address,
          [e.target.name]: mask(e.target.value, '9999999'),
        },
      });
    } else if (e.target.name === 'zip_code') {
      setNewAddress({
        ...newAddress,
        address: {
          ...newAddress.address,
          [e.target.name]: mask(e.target.value, '99999-999'),
        },
      });
    } else if (e.target.name === 'state') {
      // apiIBGECidades
      //   .get(`/${e.target.value}/municipios`)
      //   .then((res) => {
      //     setListCites(res.data.map((city) => city.nome));
      //   })
      //   .catch((error) => {
      //     toast.error(error.response.data.messages);
      //   });
      setNewAddress({
        ...newAddress,
        address: {
          ...newAddress.address,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setNewAddress({
        ...newAddress,
        address: {
          ...newAddress.address,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const handleChangeAddress = (e) => {
    setUpdateChange({ ...updateChange, address: true });
    if (e.target.name === 'number') {
      setListAddress({
        ...listAddress,
        [e.target.name]: mask(e.target.value, '9999999'),
      });
    } else if (e.target.name === 'zip_code') {
      setListAddress({
        ...listAddress,
        [e.target.name]: mask(e.target.value, '99999-999'),
      });
    } else {
      setListAddress({ ...listAddress, [e.target.name]: e.target.value });
    }
  };

  async function handleSubmitNew(event) {
    event.preventDefault();
    let body = { company_id: dados?.id, ...newAddress.address };
    await POST_COMPANY_ADDRESS(body, setDados, setListAddress, setShowbackdrop);
    props.atualizar();

    setNewAddress({
      status: false,
      address: {
        street: '',
        number: '',
        district: '',
        city: '',
        state: '',
        zip_code: '',
        complement: '',
      },
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    let edit = true;
    let body = {
      company_id: dados?.id,
      street: listAddress?.street,
      number: listAddress?.number,
      district: listAddress?.district,
      city: listAddress?.city,
      state: listAddress?.state,
      zip_code: listAddress?.zip_code,
      complement: listAddress?.complement,
    };
    updateChange.company && (await PUT_COMPANIES(dados.id, dados, handleClose));
    updateChange.address &&
      (await POST_COMPANY_ADDRESS(
        body,
        setDados,
        setListAddress,
        setShowbackdrop,
        edit,
      ));
    setUpdateChange({ company: false, address: false });
    props.atualizar();
    setEditar((edit) => !edit);
  }

  React.useEffect(() => {
    props.dados?.address_id &&
      SHOW_ADDRESS(props.dados?.address_id, setListAddress, setShowbackdrop);
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 9999,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <h1>Dados do Integrador</h1>
              <span>
                Visualize e altere os dados do Integrador. <br />
                Preencha corretamente os campos de edição para alterar as
                informações.
              </span>
              {!editar ? (
                <>
                  <div className="div__moreFiles">
                    <button
                      type="button"
                      className="btn__newFile"
                      onClick={() => {
                        setEditar((edit) => !edit);
                      }}
                    >
                      Editar Dados
                    </button>
                  </div>

                  <h4>
                    Dados da empresa
                    {!dados?.address_id && !newAddress.status && (
                      <div className="div__moreOptions">
                        <button
                          type="button"
                          className="btn__newDocument"
                          onClick={() => {
                            setNewAddress((address) => ({
                              ...address,
                              status: !address.status,
                            }));
                          }}
                        >
                          + Adicionar Endereço
                        </button>
                      </div>
                    )}
                  </h4>

                  <div className="div__oneRow">
                    <p>
                      <label>CNPJ: </label> {dados?.cnpj}
                    </p>
                  </div>
                  <div className="div__oneRow">
                    <p>
                      <label>Nome fantasia: </label> {dados?.fantasy_name}
                    </p>
                  </div>
                  <div className="div__oneRow">
                    <p>
                      <label>Razão social: </label> {dados?.social_reason}
                    </p>
                  </div>
                  <div className="div__oneRow">
                    <p>
                      <label>Registro Estadual: </label>{' '}
                      {dados?.state_registration
                        ? dados?.state_registration
                        : ' - '}
                    </p>
                    <p>
                      <label>Registro Municipal: </label>{' '}
                      {dados?.municipal_registration
                        ? dados?.municipal_registration
                        : ' - '}
                    </p>
                  </div>
                  <br />
                  {dados?.address_id ? (
                    <>
                      <div className="div__oneRow">
                        <p>
                          <label>Estado: </label> {listAddress?.state}
                        </p>
                        <p>
                          <label>Cidade: </label> {listAddress?.city}
                        </p>
                      </div>
                      <div className="div__oneRow">
                        <p>
                          <label>Rua: </label> {listAddress?.street}
                        </p>
                        <p>
                          <label>Nº: </label> {listAddress?.number}
                        </p>
                      </div>
                      <div className="div__oneRow">
                        <p>
                          <label>Bairro: </label> {listAddress?.district}
                        </p>
                        <p>
                          <label>CEP: </label>{' '}
                          {listAddress?.zip_code
                            ? listAddress?.zip_code
                            : ' - '}
                        </p>
                      </div>
                      <div className="div__oneRow">
                        <p>
                          <label>Complemento: </label>{' '}
                          {listAddress?.complement
                            ? listAddress?.complement
                            : ' - '}
                        </p>
                      </div>
                    </>
                  ) : (
                    newAddress.status && (
                      <form onSubmit={handleSubmitNew}>
                        <div className="div__oneRow">
                          <select
                            type="text"
                            name="state"
                            value={newAddress?.address?.state}
                            style={{
                              flex: 1,
                            }}
                            onChange={handleChangeNew}
                            required
                          >
                            <option value="" disabled selected>
                              Estado
                            </option>
                            {listStates?.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>

                          <input
                            type="text"
                            name="city"
                            value={newAddress?.address?.city}
                            placeholder="Cidade"
                            style={{
                              flex: 4,
                            }}
                            onChange={handleChangeNew}
                            required
                          />

                          {/* <select
                              type="text"
                              name="city"
                              value={newAddress?.address?.city}
                              style={{
                                flex: 4,
                              }}
                              onChange={handleChangeNew}
                              disabled={
                                newAddress?.address?.state ? false : true
                              }
                              required
                            >
                              <option value="" disabled selected>
                                Cidade
                              </option>
                              {listCites?.map((city) => (
                                <option key={city} value={city}>
                                  {city}
                                </option>
                              ))}
                            </select> */}
                          <input
                            type="text"
                            name="street"
                            value={newAddress?.address?.street}
                            placeholder="Rua"
                            style={{
                              flex: 4,
                            }}
                            onChange={handleChangeNew}
                            required
                          />
                        </div>
                        <div className="div__oneRow">
                          <input
                            type="number"
                            name="number"
                            value={newAddress?.address?.number}
                            placeholder="Número"
                            style={{
                              flex: 1,
                            }}
                            onChange={handleChangeNew}
                            required
                          />
                          <input
                            type="text"
                            name="district"
                            value={newAddress?.address?.district}
                            placeholder="Bairro"
                            style={{
                              flex: 4,
                            }}
                            onChange={handleChangeNew}
                            required
                          />

                          <input
                            type="text"
                            name="zip_code"
                            value={newAddress?.address?.zip_code}
                            placeholder="CEP"
                            style={{
                              flex: 4,
                            }}
                            onChange={handleChangeNew}
                            required
                          />
                        </div>
                        <div
                          className="div__oneRow"
                          style={{
                            gap: '1rem',
                          }}
                        >
                          <input
                            type="text"
                            name="complement"
                            value={newAddress?.address?.complement}
                            placeholder="Complemento"
                            style={{
                              flex: 1,
                            }}
                            onChange={handleChangeNew}
                            required
                          />
                        </div>
                        <div
                          className="div__moreFiles"
                          style={{ marginTop: '1rem' }}
                        >
                          <button
                            type="button"
                            className="btn__cancel"
                            onClick={() =>
                              setNewAddress({
                                status: false,
                                address: {
                                  street: '',
                                  number: '',
                                  district: '',
                                  city: '',
                                  state: '',
                                  zip_code: '',
                                  complement: '',
                                },
                              })
                            }
                          >
                            Cancelar
                          </button>

                          <button type="submit" className="btn__newFile">
                            Cadastrar Endereço
                          </button>
                        </div>
                      </form>
                    )
                  )}

                  <h4>Dados pessoais</h4>
                  <div className="div__oneRow">
                    <p>
                      <label>Nome: </label> {dados?.owner?.full_name}
                    </p>
                    <p>
                      <label>CPF: </label> {dados?.owner?.cpf}
                    </p>
                  </div>
                </>
              ) : (
                <header>
                  <content>
                    <h4>Dados do integrador</h4>
                    <form onSubmit={handleSubmit}>
                      <div className="div__oneRow">
                        <input
                          type="text"
                          // name={dados.cnpj ? 'cnpj' : 'cpf'}
                          // value={dados.cnpj ? dados.cnpj : dados.cpf}
                          // placeholder={dados.cnpj ? 'CNPJ' : 'CPF'}
                          name="cnpj"
                          value={dados.cnpj}
                          placeholder="CNPJ"
                          style={{
                            flex: 1,
                            maxWidth: window.innerWidth > 675 && '280px',
                          }}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="div__oneRow">
                        <input
                          type="text"
                          name="fantasy_name"
                          value={dados.fantasy_name}
                          placeholder="Nome Fantasia"
                          style={{
                            flex: 1,
                          }}
                          onChange={handleChange}
                        />
                        <input
                          type="text"
                          name="social_reason"
                          value={dados.social_reason}
                          placeholder="Razão Social"
                          style={{
                            flex: 1,
                          }}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="div__oneRow">
                        <input
                          type="text"
                          name="state_registration"
                          value={dados.state_registration}
                          placeholder="Registro estadual"
                          style={{
                            flex: 1,
                            maxWidth: window.innerWidth > 675 && '280px',
                          }}
                          onChange={handleChange}
                        />
                        <input
                          type="text"
                          name="municipal_registration"
                          value={dados.municipal_registration}
                          placeholder="Registro municipal"
                          style={{
                            flex: 1,
                            maxWidth: window.innerWidth > 675 && '280px',
                          }}
                          onChange={handleChange}
                        />
                      </div>

                      {dados?.address_id && (
                        <>
                          <div className="div__oneRow">
                            {/* <input
                              type="text"
                              name="state"
                              value={listAddress?.state}
                              placeholder="Estado"
                              style={{
                                flex: 1,
                              }}
                              onChange={handleChangeAddress}
                              required
                            /> */}

                            <select
                              type="text"
                              name="state"
                              value={listAddress?.state}
                              style={{
                                flex: 1,
                              }}
                              onChange={handleChangeAddress}
                              required
                            >
                              <option value="" disabled selected>
                                Estado
                              </option>
                              {listStates?.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>

                            <input
                              type="text"
                              name="city"
                              value={listAddress?.city}
                              placeholder="Cidade"
                              style={{
                                flex: 4,
                              }}
                              onChange={handleChangeAddress}
                              required
                            />
                            <input
                              type="text"
                              name="street"
                              value={listAddress?.street}
                              placeholder="Rua"
                              style={{
                                flex: 4,
                              }}
                              onChange={handleChangeAddress}
                              required
                            />
                          </div>

                          <div className="div__oneRow">
                            <input
                              type="text"
                              name="number"
                              value={listAddress?.number}
                              placeholder="Número"
                              style={{
                                flex: 1,
                              }}
                              onChange={handleChangeAddress}
                              required
                            />
                            <input
                              type="text"
                              name="district"
                              value={listAddress?.district}
                              placeholder="Bairro"
                              style={{
                                flex: 4,
                              }}
                              onChange={handleChangeAddress}
                              required
                            />

                            <input
                              type="text"
                              name="zip_code"
                              value={listAddress?.zip_code}
                              placeholder="CEP"
                              style={{
                                flex: 4,
                              }}
                              onChange={handleChangeAddress}
                              required
                            />
                          </div>
                          <div className="div__oneRow">
                            <input
                              type="text"
                              name="complement"
                              value={listAddress?.complement}
                              placeholder="Complemento"
                              style={{
                                flex: 1,
                              }}
                              onChange={handleChangeAddress}
                              required
                            />
                          </div>
                        </>
                      )}

                      <div
                        className="div__moreFiles"
                        style={{ marginTop: '1rem' }}
                      >
                        <button
                          type="button"
                          className="btn__cancel"
                          onClick={() => {
                            // resetDados();
                            setEditar((edit) => !edit);
                          }}
                        >
                          Cancelar Edição
                        </button>

                        <button type="submit" className="btn__newFile">
                          Aplicar alterações
                        </button>
                      </div>
                    </form>
                  </content>
                </header>
              )}
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              type="button"
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
              style={{ width: '10rem' }}
            >
              Voltar
              {/* Cancelar */}
            </button>
            {/* <button
                type="submit"
                onClick={handleSubmit}
                className="btn__register"
              >
                Aplicar alterações
              </button> */}
          </FielButtons>
        </DialogActions>

        {showbackdrop && <SimpleBackdrop />}
      </Dialog>
    </div>
  );
}
