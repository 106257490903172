import { createContext, useState, useContext, useEffect } from 'react';
import api from '../services/api';
import { TOKEN_KEY } from '../services/Auth';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { CodeSharp } from '@material-ui/icons';
import { SET_TOKEN } from '../services/endpoints';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const history = useHistory();

  // função de login
  const signin = async (newUser, remember) => {
    await api
      .post('/session', newUser)
      .then((response) => {
        if (!response.data.user.is_tellar) {
          toast.warn('Usuário não autorizado');
          localStorage.removeItem(TOKEN_KEY);
          return history.push('/login');
        }
        setUser(response.data);
        remember &&
          localStorage.setItem(TOKEN_KEY, JSON.stringify(response.data));
        SET_TOKEN(response.data.token);
        api.defaults.headers['Authorization'] = `Bearer ${response.data.token}`;
        isAuthenticated();
        return history.push('/integradores');
      })
      .catch((err) => {
        return toast.error(err.response.data.message);
      });
  };

  // função de logout
  const signout = () => {
    setUser(null);
    localStorage.removeItem(TOKEN_KEY);
    window.location.reload();
    return history.push('/login');
  };

  const isAuthenticated = async () => {
    let data =
      localStorage.getItem(TOKEN_KEY) !== null
        ? localStorage.getItem(TOKEN_KEY)
        : false;
    data &&
      (await api
        .post('/validate-admin', { token: JSON.parse(data).token })
        .then((res) => {
          // console.log('[LOGIN] Login=> ', res.data);
          data && setUser(JSON.parse(data));
        })
        .catch(async (error) => {
          console.log('[ERROR] => ', error.data);
          localStorage.removeItem(TOKEN_KEY);
          signout();
        }));

    !data && (await history.push('/login'));
  };

  let value = { user, signin, signout, isAuthenticated };

  useEffect(() => {
    isAuthenticated();
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const auth = useContext(AuthContext);

  return auth;
}
