import styled from 'styled-components';

export const Conteudo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif !important;

  img {
    max-width: 100px;
    margin: 2rem;
  }
  .div__one_row {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 350px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    margin: 0.25rem 0rem;
    input {
      padding: 0.75rem 1rem;
      width: 100%;
      outline-color: var(--primary2);
      margin: 0rem;
      border: 0rem;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0rem;
      background-color: transparent;
      padding: 0rem 1rem;
      cursor: pointer;
      svg {
        font-size: 2rem;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  ul {
    margin: 1rem 2rem;
    /* background: red; */
    font-size: 0.85rem;
  }
  @media screen and (max-width: 675px) {
    ul {
      margin: 1rem 1rem;
      padding: 0rem;
      /* background: red; */
      font-size: 0.85rem;
    }
  }
`;
