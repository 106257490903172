import React, { useState } from 'react';

import { Container } from './styles';

import ModalRecoverPassword from '../../../components/ModalRecoverPassword';

import { KEY_ACCESS } from '../../../services/Auth';

// images
import Logo1 from '../../../assets/images/logo/logo1.png';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../context/AuthContext';
import { toast } from 'react-toastify';

function Login(props) {
  const { signin } = useAuth();
  const [recoverPassword, setRecoverPassword] = useState(false);

  const [dados, setDados] = useState({
    email: '',
    password: '',
    remember: false,
  });

  const handleLogin = (event) => {
    event.preventDefault();

    // para produção
    const requestBody = {
      email: dados.email,
      password: dados.password,
    };

    signin(requestBody, dados.remember);
  };

  return (
    <Container>
      <div className="div__gradient">
        <form onSubmit={handleLogin}>
          <img
            alt="Logo Tellar Soluções"
            src={Logo1}
            title="Logo Tellar"
            className="img__logo_mobile"
          />
          <h1>Sistema administrativo</h1>
          <span>
            Realize login para entrar no sistema administrativo da empresa
          </span>
          <input
            type="text"
            placeholder="E-mail"
            autoFocus
            onChange={(e) => setDados({ ...dados, email: e.target.value })}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={(e) => setDados({ ...dados, password: e.target.value })}
          />
          <div className="div__functions">
            <label>
              <input
                type="checkbox"
                checked={dados.remember}
                onChange={() =>
                  setDados({ ...dados, remember: !dados.remember })
                }
              />
              Lembrar-me
            </label>
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => setRecoverPassword(true)}
            >
              Recuperar senha
            </a>
          </div>
          <button className="btn__login" type="submit">
            Login
          </button>
          <div className="div__line" />
          <div className="div__subtext">
            <label>
              Novo por aqui? Envie mensagem a equipe responsável e solicite seu
              acessso
            </label>
          </div>
          <Link to="/cadastro" style={{ width: '100%' }}>
            <button
              className="btn__register"
              type="button"
              onClick={() => window.open('mailto:tellar.solucoes@gmail.com')}
            >
              Solicitar acesso
            </button>
          </Link>
        </form>
      </div>
      {recoverPassword && (
        <ModalRecoverPassword fechar={() => setRecoverPassword(false)} />
      )}
    </Container>
  );
}

export default Login;
