import { createContext, useState, useContext, useEffect } from 'react';
import api from '../services/api';

import { useAuth } from './AuthContext';

const UserDataContext = createContext(null);

export function UserDataProvider({ children }) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  const [loginData, setLoginData] = useState(null);
  const [person, setPerson] = useState(null);
  const [addressPerson, setAddressPerson] = useState(null);
  const [company, setCompany] = useState(null);
  const [addressCompany, setAddressCompany] = useState(null);
  const [numbersContact, setNumbersContact] = useState(null);
  const [completedData, setCompletedData] = useState(false);

  useEffect(() => {
    user && getDadosUsuario(user);
  }, [user]);

  const getDadosUsuario = async (user2) => {
    var config = {
      headers: {
        Authorization: `Bearer ${user2 ? user2.token : user.token}`,
        'Content-Type': 'application/json',
      },
    };

    await api
      .get(
        `users/${
          user2 ? user2.user_id : user.user_id
        }?relations=["login_data","person","person.address","person.company","person.company.address", "person.numbers_contact"]`,
        config
      )
      .then((res) => {
        const data = res.data;

        if (
          data &&
          data.login_data &&
          data.person &&
          data.person?.address &&
          data.person?.company &&
          data.person?.company?.address &&
          data.person?.numbers_contact?.length > 0
        ) {
          setCompletedData(true);
        }

        setAddressCompany(data.person.company.address);
        delete data.person.company.address;

        setCompany(data.person.company);
        delete data.person.company;

        setAddressPerson(data.person.address);
        delete data.person.address;

        setNumbersContact(data.person.numbers_contact);
        delete data.person.numbers_contract;

        setPerson(data.person);
        setLoginData(data.login_data);

        setLoading(false);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  let value = {
    loginData,
    person,
    addressPerson,
    company,
    addressCompany,
    completedData,
    getDadosUsuario,
    numbersContact,
    loading,
  };

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
}

export function useUserData() {
  return useContext(UserDataContext);
}
