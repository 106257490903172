import React, { useState } from 'react';

// Estilização
import { Container, Page, Cardreceipts } from './styles';

// Componente de card para novos projetos
import CardNovosProjetos from '../../../components/CardNovosProjetos';

// mascara
import { mask } from 'remask';

// icones
import { BsSortAlphaDown, BsSortAlphaUpAlt } from 'react-icons/bs';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PhoneIcon from '@material-ui/icons/Phone';

// // confirmacao
import Dialogoconfirmacao from '../../../components/Dialogoconfirmacao';

// iconbutton
import IconButton from '@material-ui/core/IconButton';

// Chamadas da API
import { GET_RECEIPTS, SET_PAYMENT_ID } from '../../../services/endpoints';
import SimpleBackdrop from '../../../components/Backdrop';

// tooltip
import Tooltip from '@material-ui/core/Tooltip';

// popover
import Popover from '@material-ui/core/Popover';

function Novoscreditos() {
  const [showbackdrop, setShowbackdrop] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(6);
  const [totalpage, setTotalpage] = useState();

  // const [teste, setTeste] = useState(null);
  const [listreceipts, setListreceipts] = useState([]);

  const [filter, setFilter] = useState({
    type: '',
    value: '',
    dig: '',
  });

  const [listidx, setListidx] = useState(6);

  // popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [datapop, setDatapop] = useState();
  const handleClickPop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };

  const openPop = Boolean(anchorEl);
  const idPop = openPop ? 'simple-popover' : undefined;

  const [order, setOrder] = useState('');

  const handleFilter = (dado) => {
    if (filter.type) {
      if (filter.type === 'integrador') {
        if (
          dado.company.social_reason
            .toLowerCase()
            .indexOf(filter.value.toLowerCase()) > -1
        )
          return true;
      } else if (filter.type === 'cliente') {
        if (
          dado?.project?.client_name
            ?.toLowerCase()
            .indexOf(filter.value.toLowerCase()) > -1
        )
          return true;
      }
    } else {
      return true;
    }
    // if (filter.type === 'name') {
    //   if (dado.name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1)
    //     return true;
    // } else if (filter.type === 'cpf') {
    //   if (
    //     dado.cpf
    //       .replace(/[^0-9]/g, '')
    //       .indexOf(filter.value.replace(/[^0-9]/g, '')) > -1
    //   )
    //     return true;
    // } else if (filter.type === 'cnpj') {
    //   if (
    //     dado?.cnpj
    //       .replace(/[^0-9]/g, '')
    //       .indexOf(filter.value.replace(/[^0-9]/g, '')) > -1
    //   )
    //     return true;
    // } else if (filter.type === 'suspended_projects') {
    //   if (dado.suspended) return true;
    // } else if (filter.type === 'state') {
    //   if (dado.state.toLowerCase().indexOf(filter.value.toLowerCase()) > -1)
    //     return true;
    // } else if (filter.type === 'delayed_projects') {
    //   console.log('Entrando aqui: ', dado.max_process);
    //   if (dado.process >= dado.max_process && dado.suspended === false) {
    //     return true;
    //   }
    // } else {
    //   return true;
    // }
  };

  const handleFilterValue = (event) => {
    if (filter.type === 'cpf') {
      setFilter({
        ...filter,
        value: mask(event.target.value, '999.999.999-99'),
      });
    } else if (filter.type === 'cnpj') {
      setFilter({
        ...filter,
        value: mask(event.target.value, '99.999.999/9999-99'),
      });
    } else {
      setFilter({ ...filter, value: event.target.value });
    }
  };

  // Dialogo de confirmação
  const [showconf, setShowconf] = useState({
    id: null,
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente suspender este projeto?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
  });

  const currency = function (number) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(number);
  };

  let filterReceipts = listreceipts
    ?.filter((data) => data.is_paid === false && !data.project)
    .filter(handleFilter)
    .sort((a, b) => {
      if (order === 'AZ') {
        return a.company.social_reason > b.company.social_reason
          ? 1
          : b.company.social_reason > a.company.social_reason
          ? -1
          : 0;
      } else if (order === 'ZA') {
        return a.company.social_reason < b.company.social_reason
          ? 1
          : b.company.social_reason < a.company.social_reason
          ? -1
          : 0;
      } else if (order === 'primeiros') {
        return new Date(b.created_at) - new Date(a.created_at);
      } else if (order === 'ultimos') {
        return new Date(a.created_at) - new Date(b.created_at);
      }
    });

  React.useEffect(() => {
    GET_RECEIPTS(setListreceipts, setShowbackdrop, page, rows, setTotalpage);
  }, []);

  return (
    <Container>
      <Page>
        <header>
          <h1>Novos Créditos</h1>
          <span>Confira os novos créditos, analise, visualize e aceite.</span>
        </header>
        <content>
          <div className="div__fieldFilter">
            <div className="div__filters">
              <select
                onChange={(event) =>
                  setFilter({
                    ...filter,
                    type: event.target.value,
                    value: '',
                    dig: '',
                  })
                }
                value={filter.type}
                style={{ maxWidth: 200 }}
              >
                <option value="" selected>
                  {' '}
                  Filtro{' '}
                </option>
                <option value="integrador">Integrador</option>
                <option value="cliente">Cliente</option>
                {/* <option value="cnpj">CNPJ</option> */}
              </select>

              {/* autocomplete */}

              <input
                placeholder={
                  filter.type === 'integrador' || filter.type === 'cliente'
                    ? 'Digite o nome'
                    : filter.type === 'cpf'
                    ? 'Digite o CPF'
                    : filter.type === 'cnpj'
                    ? 'Digite o CNPJ'
                    : ' - '
                }
                value={filter.value}
                disabled={!filter.type ? true : false}
                onChange={handleFilterValue}
                style={{
                  display:
                    filter.type === 'integrador' ||
                    filter.type === 'cliente' ||
                    filter.type === 'cpf' ||
                    filter.type === 'cnpj'
                      ? 'block'
                      : 'none',
                }}
              />

              <select
                required
                name="state"
                style={{
                  flex: 1,
                  display: filter.type === 'state' ? 'block' : 'none',
                }}
                value={filter.value}
                onChange={handleFilterValue}
              >
                <option value="" disabled selected>
                  Escolha o estado
                </option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="RC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>

              {/* fim de autocomplete */}
            </div>
            <div className="div__order">
              <button
                className={order === 'AZ' ? 'btn__esq btn__ativ' : 'btn__esq'}
                onClick={() => (order === 'AZ' ? setOrder('') : setOrder('AZ'))}
              >
                <BsSortAlphaDown style={{ fontSize: '1.4rem' }} />
              </button>
              <button
                className={order === 'ZA' ? 'btn_meio btn__ativ' : 'btn_meio'}
                onClick={() => (order === 'ZA' ? setOrder('') : setOrder('ZA'))}
              >
                <BsSortAlphaUpAlt style={{ fontSize: '1.4rem' }} />
              </button>

              <button
                className={
                  order === 'primeiros' ? 'btn_meio btn__ativ' : 'btn_meio'
                }
                onClick={() =>
                  order === 'primeiros' ? setOrder('') : setOrder('primeiros')
                }
              >
                Recentes
              </button>
              <button
                className={
                  order === 'ultimos' ? 'btn__dir btn__ativ' : 'btn__dir'
                }
                onClick={() =>
                  order === 'ultimos' ? setOrder('') : setOrder('ultimos')
                }
              >
                Antigos
              </button>
            </div>
          </div>

          <div className="div__deck">
            <Cardreceipts
              style={{
                color: 'rgba(var(--black_rgb), 0.8)',
                fontSize: '0.8rem',
              }}
            >
              <div style={{ flex: 0.5 }}>
                <b>Data</b>
              </div>
              <div style={{ flex: 2 }}>
                <b>Integrador</b>
              </div>
              <div style={{ flex: 2 }}>
                <b>Tipo</b>
              </div>
              <div style={{ flex: 1 }}>
                <b>Valor</b>
              </div>
              <div style={{ flex: 1 }}>
                <b>Contato</b>
              </div>
              <div style={{ flex: 1 }}>
                <b>Pagamento</b>
              </div>
            </Cardreceipts>
            {filterReceipts?.slice(0, listidx).map((proj, idx) => (
              <Cardreceipts>
                <div style={{ flex: 0.5 }}>
                  <small>
                    {new Date(proj.created_at).toLocaleDateString('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}
                  </small>
                </div>
                <div style={{ flex: 2 }}>{proj?.company?.social_reason}</div>
                <div style={{ flex: 2 }}>Crédito</div>
                <div style={{ flex: 1 }}>
                  {currency(proj.final_price)}{' '}
                  <InfoOutlinedIcon
                    className="icon__info"
                    aria-describedby={idPop}
                    onClick={(event) => {
                      handleClickPop(event);
                      setDatapop(proj);
                    }}
                  />
                </div>
                <div style={{ display: 'flex', gap: '0.25rem', flex: 1 }}>
                  <Tooltip
                    title={
                      proj?.company?.owner?.numbers_contact[0]?.is_whatsapp
                        ? 'Whatsapp'
                        : 'Telefone'
                    }
                  >
                    <a
                      href={
                        proj?.company?.owner?.numbers_contact[0]?.is_whatsapp
                          ? 'https://wa.me/55' +
                            proj?.company?.owner?.numbers_contact[0]?.number.replace(
                              /[^0-9]/g,
                              ''
                            )
                          : 'tel:55' +
                            proj?.company?.owner?.numbers_contact[0]?.number.replace(
                              /[^0-9]/g,
                              ''
                            )
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconButton aria-label="whatsapp" className="btn__icon">
                        {proj?.company?.owner?.numbers_contact[0]
                          ?.is_whatsapp ? (
                          <WhatsAppIcon />
                        ) : (
                          <PhoneIcon />
                        )}
                      </IconButton>
                    </a>
                  </Tooltip>
                  <Tooltip title="E-mail">
                    <a
                      href={`mailto:${proj?.company?.owner?.user?.login_data?.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconButton aria-label="email" className="btn__icon">
                        <MailOutlineOutlinedIcon />
                      </IconButton>
                    </a>
                  </Tooltip>
                </div>
                <div style={{ flex: 1 }}>
                  <Tooltip
                    placement="bottom-start"
                    title={
                      proj.receipt?.is_paid
                        ? 'Cancelar pagamento'
                        : 'Confirmar pagamento'
                    }
                  >
                    <button
                      className={
                        proj.receipt?.is_paid
                          ? 'button_payment button_cancel_payment'
                          : 'button_payment'
                      }
                      onClick={() =>
                        setShowconf({
                          ...showconf,
                          status: true,
                          width: 'sm',
                          title: 'Confirmar',
                          description: `Deseja realmente confirmar o pagamento do crédito de ${proj.company.social_reason}?`,
                          btn_conf: 'Sim',
                          btn_cancel: 'Não',
                          delete: false,
                          dados: proj,
                          type: 'SET_PAYMENT_ID',
                        })
                      }
                    >
                      <AttachMoneyIcon />{' '}
                      {proj.receipt?.is_paid ? 'Cancelar' : 'Confirmar'}
                    </button>
                  </Tooltip>
                </div>
              </Cardreceipts>
            ))}
          </div>
        </content>

        {filterReceipts.length > listidx ? (
          <div
            className="div__viewMore"
            onClick={() => {
              setListidx(listidx + 6);
              setRows(rows + 6);
              GET_RECEIPTS(
                setListreceipts,
                setShowbackdrop,
                page,
                rows + 6,
                setTotalpage
              );
            }}
          >
            Ver mais
          </div>
        ) : filterReceipts.length === 0 && filter.type ? (
          <div style={{ margin: '2rem 1rem' }}>Nenhum crédito encontrado</div>
        ) : filterReceipts.length === 0 && !filter.type ? (
          <div style={{ margin: '2rem 1rem' }}>Nenhum crédito pendente</div>
        ) : (
          <div style={{ margin: '2rem 1rem' }}>Sem mais créditos</div>
        )}
      </Page>

      <Popover
        id={idPop}
        open={openPop}
        anchorEl={anchorEl}
        onClose={(event) => {
          handleClosePop(event);
          setTimeout(() => {
            setDatapop();
          }, 200);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div
          style={{
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span>
            + {currency(datapop?.original_price)} <small>(projeto)</small>
          </span>

          {datapop?.credits_discount > 0 ? (
            <span>
              - {currency(datapop?.credits_discount)} <small>(créditos)</small>{' '}
            </span>
          ) : (
            ''
          )}

          {datapop?.percentage_discount > 0 ? (
            <span>
              {' '}
              -{' '}
              {currency(
                ((datapop?.original_price - datapop?.credits_discount) *
                  datapop?.percentage_discount) /
                  100
              )}{' '}
              <small>(pontos)</small>{' '}
            </span>
          ) : (
            ''
          )}

          {datapop?.coupon_discount > 0 ? (
            <span>
              - {currency(datapop?.coupon_discount)} <small>(cupom)</small>
            </span>
          ) : (
            ''
          )}
          <span
            style={{
              borderTop: '1px dotted rgba(var(--black_rgb), 0.5)',
              color: 'var(--primary2)',
            }}
          >
            • {currency(datapop?.final_price)}
          </span>
        </div>
      </Popover>

      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({
              status: false,
              width: 'sm',
              title: 'Confirmar',
              description: 'Deseja realmente suspender este projeto?',
              btn_conf: 'Sim',
              btn_cancel: 'Não',
              delete: true,
              dados: '',
            })
          }
          confirmar={() => {
            SET_PAYMENT_ID(showconf?.dados?.id, () => {
              GET_RECEIPTS(
                setListreceipts,
                setShowbackdrop,
                page,
                rows,
                setTotalpage
              );
              setShowconf({
                status: false,
                width: 'sm',
                title: 'Confirmar',
                description: 'Deseja realmente suspender este projeto?',
                btn_conf: 'Sim',
                btn_cancel: 'Não',
                delete: true,
                dados: '',
              });
            });
          }}
        />
      )}

      {showbackdrop && <SimpleBackdrop />}
    </Container>
  );
}

export default Novoscreditos;
