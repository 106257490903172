import { ReactNode, useState } from 'react';
import Dialogotrocarsenha from '../../../components/Dialogotrocarsenha';
import Dialogoalterardados from '../../../components/Dialogoalterardados';
import { mask } from 'remask';

import { Container, Page, Card } from './styles';

// icones
import { BsSortAlphaDown, BsSortAlphaUpAlt } from 'react-icons/bs';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';

// confirmacao
import Dialogoconfirmacao from '../../../components/Dialogoconfirmacao';
import { toast } from 'react-toastify';

import { GET_COUPONS } from '../../../services/endpoints';
import { useEffect } from 'react';
import { isBefore } from 'date-fns';
import api from '../../../services/api';

// cupom add
import Dialogoaddcupom from '../../../components/Dialogoaddcupom';
import SimpleBackdrop from '../../../components/Backdrop';

function Cupons(props) {
  const [showbackdrop, setShowbackdrop] = useState(false);
  const [showcupom, setShowcupom] = useState({
    open: false,
  });

  const [showaltdados, setShowaltdados] = useState(false);
  const [editaccessdata, setEditaccessdata] = useState(false);
  const [order, setOrder] = useState('');
  const [showconf, setShowconf] = useState({
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente suspender este usuário?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
  });

  const [filter, setFilter] = useState({
    type: '',
    value: '',
    dig: '',
  });

  const [listcupons, setListcupons] = useState([]);

  const handleFilter = (dado) => {
    if (filter.type) {
      if (filter.type === 'name') {
        if (dado.code.toLowerCase().indexOf(filter.value.toLowerCase()) > -1)
          return true;
      }
      //   if (dado.full_name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1)
      //     return true;
      // } else if (filter.type === 'cpf') {
      //   if (
      //     dado.cpf
      //       .replace(/[^0-9]/g, '')
      //       .indexOf(filter.value.replace(/[^0-9]/g, '')) > -1
      //   )
      //     return true;
      // } else if (filter.type === 'cnpj') {
      //   if (
      //     dado?.cnpj
      //       .replace(/[^0-9]/g, '')
      //       .indexOf(filter.value.replace(/[^0-9]/g, '')) > -1
      //   )
      //     return true;
      // } else if (filter.type === 'suspended_projects') {
      //   if (dado.suspended) return true;
      // } else if (filter.type === 'state') {
      //   if (dado.state.toLowerCase().indexOf(filter.value.toLowerCase()) > -1)
      //     return true;
      // } else if (filter.type === 'delayed_projects') {
      //   console.log('Entrando aqui: ', dado.max_process);
      //   if (dado.process >= dado.max_process && dado.suspended === false) {
      //     return true;
      //   }
    } else {
      return true;
    }
  };

  const handleFilterValue = (event) => {
    setFilter({ ...filter, value: event.target.value });
  };

  let listcupons2 = listcupons.filter(handleFilter).sort((a, b) => {
    if (order === 'AZ') {
      return a.code > b.code ? 1 : b.code > a.code ? -1 : 0;
    } else if (order === 'ZA') {
      return a.code < b.code ? 1 : b.code < a.code ? -1 : 0;
    } else if (order === 'primeiros') {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (order === 'ultimos') {
      return new Date(a.created_at) - new Date(b.created_at);
    }
  });

  const deleteCoupon = async (coupon_delete) => {
    setShowbackdrop(true);
    await api
      .delete(`/coupons/${coupon_delete}`)
      .then((res) => {
        GET_COUPONS(setListcupons);
        toast.success(`Cupom ${showconf.dados.code} deletado com sucesso!`);
        setShowconf({
          status: false,
          width: 'sm',
          title: 'Confirmar',
          description: 'Deseja realmente deletar este cupom?',
          btn_conf: 'Sim',
          btn_cancel: 'Não',
          delete: true,
          dados: '',
        });
        setShowbackdrop(false);
      })
      .catch((error) => {
        toast.error(error.response.data.messages);
      });
  };

  useEffect(() => {
    GET_COUPONS(setListcupons, setShowbackdrop);
  }, []);

  return (
    <Container>
      <Page>
        <header>
          <h1>Cupons</h1>
          <span>Gerencie os cupons.</span>
        </header>
        <content>
          <div className="div__fieldFilter">
            <div className="div__filters">
              <select
                onChange={(event) =>
                  setFilter({
                    ...filter,
                    type: event.target.value,
                    value: '',
                    dig: '',
                  })
                }
                value={filter.type}
                style={{ maxWidth: 200 }}
              >
                <option value="" selected>
                  {' '}
                  Filtro{' '}
                </option>
                <option value="name">Nome</option>
              </select>

              {/* autocomplete */}

              <input
                placeholder={
                  filter.type === 'name'
                    ? 'Digite o nome'
                    : filter.type === 'cpf'
                    ? 'Digite o CPF'
                    : filter.type === 'cnpj'
                    ? 'Digite o CNPJ'
                    : ' - '
                }
                value={filter.value}
                disabled={!filter.type ? true : false}
                onChange={handleFilterValue}
                style={{
                  display:
                    filter.type === 'name' ||
                    filter.type === 'cpf' ||
                    filter.type === 'cnpj'
                      ? 'block'
                      : 'none',
                }}
              />

              {/* fim de autocomplete */}
            </div>
            <div className="div__order">
              <button
                className={order === 'AZ' ? 'btn__esq btn__ativ' : 'btn__esq'}
                onClick={() => (order === 'AZ' ? setOrder('') : setOrder('AZ'))}
              >
                <BsSortAlphaDown style={{ fontSize: '1.4rem' }} />
              </button>
              <button
                className={order === 'ZA' ? 'btn_meio btn__ativ' : 'btn_meio'}
                onClick={() => (order === 'ZA' ? setOrder('') : setOrder('ZA'))}
              >
                <BsSortAlphaUpAlt style={{ fontSize: '1.4rem' }} />
              </button>

              <button
                className={
                  order === 'primeiros' ? 'btn_meio btn__ativ' : 'btn_meio'
                }
                onClick={() =>
                  order === 'primeiros' ? setOrder('') : setOrder('primeiros')
                }
              >
                Recentes
              </button>
              <button
                className={
                  order === 'ultimos' ? 'btn__dir btn__ativ' : 'btn__dir'
                }
                onClick={() =>
                  order === 'ultimos' ? setOrder('') : setOrder('ultimos')
                }
              >
                Antigos
              </button>
            </div>
          </div>

          <div className="div__field_actions">
            <div className="div__fiel_filter"></div>
            <div className="conteudo">
              <button
                type="submit"
                className="btn__finish"
                onClick={() => setShowcupom({ ...showcupom, open: true })}
              >
                + Novo cupom
              </button>
            </div>
          </div>

          <div className="div__deck">
            <div className="head_list">
              <div className="div__name" style={{ flex: 2 }}>
                Nome
              </div>
              <div className="div__value">Valor</div>
              <div className="div__has_used" style={{ textAlign: 'center' }}>
                Já usado
              </div>
              <div
                className="div__used_limited"
                style={{ textAlign: 'center' }}
              >
                Limite de uso
              </div>
              <div className="div__expired_in" style={{ textAlign: 'center' }}>
                Expira em
              </div>
              <div className="div__status" style={{ textAlign: 'center' }}>
                Status
              </div>
              <div className="div__status">Opções</div>
            </div>
            {listcupons2.map((coupon, idx) => (
              <Card key={idx}>
                <div className="div__name" style={{ flex: 2 }}>
                  {coupon.code}
                </div>
                <div className="div__value">{coupon.percentage}%</div>
                <div className="div__has_used" style={{ textAlign: 'center' }}>
                  {coupon.times_used}
                </div>
                <div
                  className="div__used_limited"
                  style={{ textAlign: 'center' }}
                >
                  {coupon.times_can_be_used}
                </div>
                <div
                  className="div__expired_in"
                  style={{ textAlign: 'center' }}
                >
                  {new Date(coupon.expired_at).toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  })}
                </div>
                <div className="div__status" style={{ textAlign: 'center' }}>
                  {isBefore(new Date(), new Date(coupon.expired_at)) ? (
                    coupon.times_used < coupon.times_can_be_used ? (
                      <div className="div__tag_ativo">Ativo</div>
                    ) : (
                      <div className="div__tag_esgotado">Esgotado</div>
                    )
                  ) : (
                    <div className="div__tag_expirado">Expirado</div>
                  )}
                </div>
                <div className="div__status">
                  {' '}
                  <button
                    className="button_delete"
                    onClick={() =>
                      setShowconf({
                        status: true,
                        width: 'sm',
                        title: 'Confirmar',
                        description: 'Deseja realmente deletar este cupom?',
                        btn_conf: 'Sim',
                        btn_cancel: 'Não',
                        delete: true,
                        dados: coupon,
                      })
                    }
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </Card>
            ))}

            {listcupons2.length === 0 && filter.type ? (
              <div style={{ padding: '1rem' }}>Nenhum cupom encontrado</div>
            ) : listcupons2.length > 0 ? (
              ''
            ) : (
              <div style={{ padding: '1rem' }}>Nenhum cupom cadastrado</div>
            )}
          </div>
          <br />
        </content>
      </Page>
      {showaltdados && (
        <Dialogoalterardados
          // dados={dados}
          fechar={() => setShowaltdados(false)}
        />
      )}
      {showcupom.open && (
        <Dialogoaddcupom
          fechar={() => setShowcupom({ ...showcupom, open: false })}
          atualizar={() => GET_COUPONS(setListcupons)}
        />
      )}
      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({
              status: false,
              width: 'sm',
              title: 'Confirmar',
              description: 'Deseja realmente deletar este cupom?',
              btn_conf: 'Sim',
              btn_cancel: 'Não',
              delete: true,
              dados: '',
            })
          }
          confirmar={() => {
            deleteCoupon(showconf.dados.id);
          }}
        />
      )}
      {showbackdrop && <SimpleBackdrop />}
    </Container>
  );
}

export default Cupons;
