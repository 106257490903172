export const KEY_ACCESS =
  '@62d94c0b0af5d930514a03329528bb7266759e6374d6f3a9f6aeaccc0cbf072a17d3414a2e5c63923b3f07ba836c451ecc10538927033a37fbd85c56ebe7f390-SATS';

export const TOKEN_KEY = '@STS-SADC-@WWL';

export const getItem = () => {};

export const setItem = () => {};

export const logoff = () => {};
