import { TablePagination } from '@material-ui/core'

export const Pagination = ({
  total,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  return (
    <TablePagination
      component="div"
      count={total}
      page={page}
      labelRowsPerPage="Projetos por página:"
      rowsPerPage={rowsPerPage}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}
