import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 4rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem 1rem;

  .MuiBadge-colorSecondary {
    color: #fff !important;
    /* background-color: #f50057; */
  }
`;

export const Page = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  min-height: 200px;

  header {
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
    font-family: 'Poppins', sans-serif;

    h1 {
      font-weight: 800;
      font-size: 1.5rem;
      color: var(--primary2);
      text-transform: uppercase;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
    span {
      opacity: 0.9;
    }

    ::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 1rem;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    }
  }

  .div__deck {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0rem 2rem;
  }

  .div__fieldFilter {
    margin: 0rem 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    .div__filters {
      display: flex;
      justify-content: flex-start;
      margin: auto 0px;
      width: 100%;
      input,
      select {
        outline-color: var(--primary2);
        padding: 0.75rem 0.5rem;
        border: 1px solid rgba(var(--black_rgb), 0.3);
        border-radius: 0.5rem;
        margin: 0rem 0.25rem;
        width: 100%;
        /* margin: auto; */
        max-width: 350px;
        z-index: 0;
      }
    }

    .div__order {
      /* background: red; */
      margin: auto;
      display: flex;
      button {
        border: 1px solid rgba(var(--black_rgb), 0.2);
        padding: 0.2rem 0.5rem;
        color: var(--primary2);
        cursor: pointer;

        :hover {
          background-color: var(--primary2) !important;
          color: var(--white);
        }
      }
      .btn__esq {
        background-color: transparent !important;
        border-radius: 0.5rem 0rem 0rem 0.5rem;
      }
      .btn__dir {
        background-color: transparent !important;
        border-radius: 0rem 0.5rem 0.5rem 0rem;
        border-left: 0px;
      }

      .btn__ativ {
        background-color: var(--primary2) !important;
        color: var(--white);
      }
      .btn_meio {
        background: var(--white);
        border-left: 0px;
      }
    }
  }

  .div__viewMore {
    border: 1px solid rgba(var(--black_rgb), 0.1);
    text-align: center;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    cursor: pointer;
    font-weight: 500;
    color: rgba(var(--black_rgb), 0.4);

    :hover {
      box-shadow: 0px -10px 20px rgba(var(--black_rgb), 0.1);
    }
  }

  @media screen and (max-width: 675px) {
    .div__fieldFilter {
      flex-direction: column;

      .div__filters {
        flex-direction: column;

        input,
        select {
          width: 100%;
          max-width: 500px !important;
          margin: 0.25rem 0rem;
          height: 3rem;
          padding: 0rem 1rem;
          font-size: 1rem;
        }
      }
      .div__order {
        margin-top: 1rem;
      }
    }
    .div__deck {
      grid-template-columns: 1fr;
      max-width: 100% !important;

      overflow-x: scroll !important;
    }
  }
`;

export const Cardreceipts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  gap: 1rem;
  box-shadow: 0px 0px 5px rgba(var(--black_rgb), 0.2);
  padding: 0.5rem 0.75rem;
  min-width: 750px !important;

  div {
    display: flex;
    /* justify-content: center; */
    align-items: center;

    .button_payment {
      border-radius: 1rem;
      width: auto;
      margin: auto 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(var(--black_rgb), 0.5);
      padding-right: 0.75rem;

      svg {
        font-size: 1.2rem;
        color: rgba(var(--black_rgb), 0.3);
        /* margin-left: -0.16rem; */
        margin-bottom: 0rem;
      }

      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--primary2);
        color: var(--primary2);
        svg {
          color: var(--primary2);
        }
      }
    }
    .button_cancel_payment {
      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--delete) !important;
        color: var(--delete) !important;
        svg {
          color: var(--delete) !important;
        }
      }
    }

    button {
      border-radius: 50%;
      width: 35px;
      margin: 0.2rem;
      height: 35px;
      justify-content: center;
      align-items: center;
      border: 0px solid rgba(var(--black_rgb), 0.3);
      background-color: transparent;
      border: 2px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;

      svg {
        font-size: 1.2rem;
        color: rgba(var(--black_rgb), 0.3);
        /* margin-left: -0.16rem; */
      }

      :hover {
        /* box-shadow: 0px 0px 5px rgba(var(--primary2_rgb), 1); */
        border: 2px solid var(--primary2);
        svg {
          color: var(--primary2);
        }
      }
    }
  }

  .icon__info {
    font-size: 1.1rem;
    margin: auto 0rem;
    color: var(--information);
    cursor: pointer;
  }

  .btn__icon {
    margin: 0rem;
    padding: 0.3rem;
  }
`;
