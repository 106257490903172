import styled from 'styled-components';

export const Container = styled.div`
  background: var(--white);
  box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
  position: fixed;
  right: 0.5rem;
  bottom: 0;
  width: 20rem;
  height: auto;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  background: var(--primary2);
  z-index: 10;

  h3 {
    font-weight: 400;
    margin: 0rem;
    text-align: left;
    padding: 0.4rem 1rem;
    color: var(--white);
  }

  .div__corpo {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: left;
    border-top: 1px solid rgba(var(--black_rgb), 0.2);
    /* border-radius: 0.25rem 0.25rem 0rem 0rem; */
    background: var(--white);
    height: 100%;

    span {
      margin: 0.25rem 0rem;
      display: flex;
      align-items: center;

      svg {
        font-size: 1rem;
        margin-right: 0.2rem;
      }
    }
    label {
      margin: 0.2rem 0rem;
      font-weight: 600;
    }
    input,
    select,
    textarea {
      border: 1px solid rgba(var(--black_rgb), 0.3);
      outline-color: var(--primary2);
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
    textarea {
      max-width: 100%;
      height: 5rem !important;
    }
  }

  .div__moreOptions {
    text-align: right;

    .btn__newDocument {
      font-size: 0.8rem;
      cursor: pointer;
      border: 1px solid var(--primary2);
      color: var(--primary2);
      background-color: transparent;
      border-radius: 1rem;
      padding: 0.3rem 0.7rem;
      margin: 1rem 0 0 0;

      :hover {
        box-shadow: 0px 0px 10px rgba(var(--black_rgb), 0.3);
      }
    }
  }

  .div__protocolo {
    background: rgba(var(--black_rgb), 0.1);
    padding: 1rem;
    margin: 1rem 0rem;
  }
`;

export const FielButtons = styled.div`
  display: flex;
  /* background: red; */
  align-items: center;
  margin-top: 1rem;

  .btn__retornar {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    width: 100%;
    max-width: 180px;
    height: 35px;
    background-color: transparent;
    border: ${(props) =>
      props.delete ? '1px solid var(--delete)' : '1px solid var(--primary2)'};
    border-radius: 1.4rem;
    color: ${(props) => (props.delete ? 'var(--delete)' : 'var(--primary2)')};
    /* margin-top: 1rem; */
    margin-right: 0.75rem;
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
    }
  }
  .btn__register {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;

    width: 320px;
    height: 45px;
    background-color: ${(props) =>
      props.delete ? 'var(--delete)' : 'var(--primary2)'};
    border: 0px;
    border-radius: 1.4rem;
    color: var(--white);
    /* margin-top: 1rem; */
    /* margin-bottom: 0.75rem; */
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
      filter: brightness(1.4);
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column-reverse;
    flex: 1;

    .btn__retornar,
    .btn__register {
      /* margin: auto; */
      width: 100% !important;
      max-width: none;
      margin: 0.5rem;
      font-size: 1rem;
    }
  }
`;
