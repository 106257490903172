import React, { useState } from 'react';

import { Container, Page, Card } from './styles';

// dica
import Tooltip from '@material-ui/core/Tooltip';

// mascara
import { mask } from 'remask';

// icones
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import NotesIcon from '@material-ui/icons/Notes';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { BsSortAlphaDown, BsSortAlphaUpAlt } from 'react-icons/bs';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

// fucoes
import Dialogoverprojeto from '../../../components/Dialogoverprojeto';
import { addDays, differenceInDays } from 'date-fns';

// // confirmacao
import Dialogoconfirmacao from '../../../components/Dialogoconfirmacao';
import Dialogoverificaralteracao from '../../../components/Dialogoverificaralteracao';
import Dialogoadicionarsupervisor from '../../../components/Dialogoadicionarsupervisor';
import { toast } from 'react-toastify';

// // chatbox
import Chatbox from '../../../components/Chatbox';
import { Badge } from '@material-ui/core';

// API de EndPoints
import {
  DELETE_RESPONSIBLES,
  GET_PROJECTS_BY_COMPANY,
  PATCH_PROJECTS_BY_ID,
  SET_PAYMENT_ID,
  SET_CANCEL_PAYMENT_ID,
  PATCH_FINISHED_PROJECT,
} from '../../../services/endpoints';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { listetapas, listStates } from '../../../config';
import SimpleBackdrop from '../../../components/Backdrop';

// contexto
import { useAuth } from '../../../context/AuthContext';
import { useCallback } from 'react';

function Projetos() {
  // Loading
  const [showbackdrop, setShowbackdrop] = useState(false);
  const { user } = useAuth();

  // Dialogo de suspensão - confirmação
  const [showconf, setShowconf] = useState({
    id: null,
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente suspender este projeto?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
  });

  //Dialogo desvincular supervisor
  const [showconfdesvincular, setShowconfdesvincular] = useState({
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente desvincular este supervisor?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
  });

  // Variável de filtro
  const [filter, setFilter] = useState({
    type: '',
    value: '',
    dig: '',
  });

  // Quantidade de listagem de cardes
  const [listidx, setListidx] = useState(3);

  // Ordenador
  const [order, setOrder] = useState('');

  // Solicitar Mudança
  const [solicitarmud, setSolicitarmud] = useState({
    status: false,
    dados: '',
  });

  // Variável de edição
  const [viewedit, setViewedit] = useState({
    status: false,
    dados: '',
    dados_proj: '',
  });

  // Variável de visualizar projeto
  const [viewproject, setViewproject] = useState({
    status: false,
    dados: '',
  });

  // Modal de cadastro de Supervisor
  const [viewcadastrarsupervisor, setViewcadastrarsupervisor] = useState({
    status: false,
    dados: '',
  });

  // Listagem de Projetos
  const [listprojects, setListProjects] = useState(null);

  // Busca do ID da Companhia via url
  const { id } = useParams();

  // ordem cronológica
  const maisatual = (a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  };

  const handleFilter = (dado) => {
    if (filter.type === 'name') {
      if (dado.name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1)
        return true;
    } else if (filter.type === 'cpf') {
      if (
        dado.cpf
          .replace(/[^0-9]/g, '')
          .indexOf(filter.value.replace(/[^0-9]/g, '')) > -1
      )
        return true;
    } else if (filter.type === 'cnpj') {
      if (
        dado?.cnpj
          .replace(/[^0-9]/g, '')
          .indexOf(filter.value.replace(/[^0-9]/g, '')) > -1
      )
        return true;
    } else if (filter.type === 'suspended_projects') {
      if (dado.suspended) return true;
    } else if (filter.type === 'state') {
      if (dado.state.toLowerCase().indexOf(filter.value.toLowerCase()) > -1)
        return true;
    } else if (filter.type === 'delayed_projects') {
      // console.log('Entrando aqui: ', dado.max_process);
      if (dado.process >= dado.max_process && dado.suspended === false) {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleFilterValue = (event) => {
    if (filter.type === 'cpf') {
      setFilter({
        ...filter,
        value: mask(event.target.value, '999.999.999-99'),
      });
    } else if (filter.type === 'cnpj') {
      setFilter({
        ...filter,
        value: mask(event.target.value, '99.999.999/9999-99'),
      });
    } else {
      setFilter({ ...filter, value: event.target.value });
    }
  };

  // filtro de acessoss admin
  const isAdminTellarFilter = (dado) => {
    if (user.user.is_tellar_admin) return true;

    if (dado?.responsibles?.sort(maisatual)[0]?.user.id === user.user.id)
      return true;
  };

  let filterProjects = listprojects
    ?.filter(isAdminTellarFilter)
    ?.filter(handleFilter)
    .sort((a, b) => {
      if (order === 'AZ') {
        return a.client_name > b.client_name
          ? 1
          : b.client_name > a.client_name
          ? -1
          : 0;
      } else if (order === 'ZA') {
        return a.client_name < b.client_name
          ? 1
          : b.client_name < a.client_name
          ? -1
          : 0;
      } else if (order === 'primeiros') {
        return new Date(b.created_at) - new Date(a.created_at);
      } else if (order === 'ultimos') {
        return new Date(a.created_at) - new Date(b.created_at);
      }
    });

  function finterRecentReceipt(a, b) {
    return new Date(a.created_at) - new Date(b.created_at);
  }

  React.useEffect(() => {
    GET_PROJECTS_BY_COMPANY(id, setListProjects, setShowbackdrop);
  }, []);

  function statusProgress(value) {
    switch (true) {
      case !!value?.toLowerCase().includes(listetapas[0]?.toLowerCase()):
        return ((1 * 100) / 6).toFixed(2)
      case !!value?.toLowerCase().includes(listetapas[1]?.toLowerCase()):
        return ((2 * 100) / 6).toFixed(2)
      case !!value?.toLowerCase().includes(listetapas[2]?.toLowerCase()):
        return ((3 * 100) / 6).toFixed(2)
      case !!value?.toLowerCase().includes(listetapas[3]?.toLowerCase()):
        return ((4 * 100) / 6).toFixed(2)
      case !!value?.toLowerCase().includes(listetapas[4]?.toLowerCase()):
        return ((5 * 100) / 6).toFixed(2)
      case !!value?.toLowerCase().includes(listetapas[5]?.toLowerCase()):
        return ((6 * 100) / 6).toFixed(2)
      default:
        return 0
    }
  }

  return (
    <Container>
      <Page>
        <header>
          <h1>Projetos do Integrador</h1>
          <span>
            Confira o andamento dos projetos do Integrador cadastrados no
            sistema.
          </span>
        </header>
        <content>
          <div className="div__fieldFilter">
            <div className="div__filters">
              {/* <select
                onChange={(event) =>
                  setFilter({
                    ...filter,
                    type: event.target.value,
                    value: '',
                    dig: '',
                  })
                }
                value={filter.type}
                style={{ maxWidth: 200 }}
              >
                <option value="" selected>
                  {' '}
                  Filtro{' '}
                </option>
                <option value="name">Nome</option>
                <option value="cpf">CPF</option>
                <option value="cnpj">CNPJ</option>
                <option value="state">Estado</option>
                <option value="concessionaria">Concessionária</option>
                <option value="open_projects">Projetos em aberto</option>
                <option value="delayed_projects">Projetos atrasados</option>
                <option value="completed_projects">Projetos finalizados</option>
                <option value="suspended_projects">Projetos suspensos</option>
              </select> */}

              {/* autocomplete */}

              <input
                placeholder={
                  filter.type === 'name'
                    ? 'Digite o nome'
                    : filter.type === 'cpf'
                    ? 'Digite o CPF'
                    : filter.type === 'cnpj'
                    ? 'Digite o CNPJ'
                    : ' - '
                }
                value={filter.value}
                disabled={!filter.type ? true : false}
                onChange={handleFilterValue}
                style={{
                  display:
                    filter.type === 'name' ||
                    filter.type === 'cpf' ||
                    filter.type === 'cnpj'
                      ? 'block'
                      : 'none',
                }}
              />

              <select
                required
                name="state"
                style={{
                  flex: 1,
                  display: filter.type === 'state' ? 'block' : 'none',
                }}
                value={filter.value}
                onChange={handleFilterValue}
              >
                <option value="" disabled selected>
                  Escolha o estado
                </option>
                {listStates.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>

              {/* fim de autocomplete */}
            </div>
            <div className="div__order">
              <button
                className={order === 'AZ' ? 'btn__esq btn__ativ' : 'btn__esq'}
                onClick={() => (order === 'AZ' ? setOrder('') : setOrder('AZ'))}
              >
                <BsSortAlphaDown style={{ fontSize: '1.4rem' }} />
              </button>
              <button
                className={order === 'ZA' ? 'btn_meio btn__ativ' : 'btn_meio'}
                onClick={() => (order === 'ZA' ? setOrder('') : setOrder('ZA'))}
              >
                <BsSortAlphaUpAlt style={{ fontSize: '1.4rem' }} />
              </button>

              <button
                className={
                  order === 'primeiros' ? 'btn_meio btn__ativ' : 'btn_meio'
                }
                onClick={() =>
                  order === 'primeiros' ? setOrder('') : setOrder('primeiros')
                }
              >
                Recentes
              </button>
              <button
                className={
                  order === 'ultimos' ? 'btn__dir btn__ativ' : 'btn__dir'
                }
                onClick={() =>
                  order === 'ultimos' ? setOrder('') : setOrder('ultimos')
                }
              >
                Antigos
              </button>
            </div>
          </div>
          <div className="div__deck">
            {filterProjects
              ?.slice(0, listidx)
              .map((proj) => (
                <Card
                  key={proj?.id}
                  progress={differenceInDays(
                    new Date(),
                    new Date(proj?.stages.sort(maisatual)[0]?.created_at),
                  )}
                  projetct_process={statusProgress(
                    proj?.stages?.sort(maisatual)[0]?.title,
                  )}
                  max_process={differenceInDays(
                    new Date(proj?.stages.sort(maisatual)[0]?.deadline),
                    new Date(proj?.stages.sort(maisatual)[0]?.created_at),
                  )}
                  suspended={proj?.suspended}
                  finished={proj?.finished_at ? true : false}
                >
                  <div className="div__name">
                    {!proj.finished_at ? (
                      proj.suspended ? (
                        <span
                          style={{
                            textAlign: 'left',
                            color: 'gray',
                            marginBottom: '0.5rem',
                          }}
                        >
                          Projeto suspenso
                        </span>
                      ) : (
                        <span
                          style={{
                            textAlign: 'left',
                            color: 'var(--primary2)',
                            marginBottom: '0.5rem',
                          }}
                        >
                          Projeto em aberto
                        </span>
                      )
                    ) : (
                      <span
                        style={{
                          textAlign: 'left',
                          color: 'gray',
                          marginBottom: '0.5rem',
                        }}
                      >
                        {proj?.stages.sort(maisatual)[0]?.title ===
                        '6 - Projeto Aprovado'
                          ? 'Projeto aprovado'
                          : proj?.stages.sort(maisatual)[0]?.title ===
                            '7 - Projeto Reprovado'
                          ? 'Projeto reprovado'
                          : 'Projeto finalizado'}
                      </span>
                    )}

                    <span className="tag">
                      {proj?.responsibles.length !== 0 ? (
                        <>
                          <p>
                            {proj?.responsibles
                              .sort(maisatual)[0]
                              ?.user?.person?.full_name.split(' ')
                              .map((item, idx) => idx < 2 && item + ' ')}{' '}
                            -{' '}
                            {
                              proj?.responsibles.sort(maisatual)[0]?.user
                                ?.person?.role
                            }
                          </p>
                          {!proj?.finished_at && user.user.is_tellar_admin && (
                            <Tooltip
                              placement="bottom-start"
                              title="Remover supervisor"
                            >
                              <HighlightOffIcon
                                style={{
                                  color: 'var(--delete)',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  setShowconfdesvincular({
                                    ...showconf,
                                    status: true,
                                    width: 'sm',
                                    title: 'Confirmar',
                                    description: `Deseja realmente desvincular o supervidor ${
                                      proj?.responsibles.sort(maisatual)[0]
                                        ?.user?.person?.full_name
                                    } do projeto ${proj.client_name}?`,
                                    btn_conf: 'Sim',
                                    btn_cancel: 'Não',
                                    delete: false,
                                    dados: proj,
                                    type: 'DELETE_RESPONSIBLES',
                                  })
                                }
                              />
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        <p
                          style={{
                            backgroundColor: 'var(--gray)',
                            color: 'var(--primary)',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            setViewcadastrarsupervisor({
                              status: true,
                              dados: proj,
                            })
                          }
                        >
                          Adicinar Supervisor
                        </p>
                      )}
                    </span>
                    <h4>
                      <AssignmentOutlinedIcon /> {proj?.client_name}
                    </h4>
                    {proj?.client_cpf && <span>CPF: {proj?.client_cpf}</span>}
                    {proj?.client_cnpj && (
                      <span>CNPJ: {proj?.client_cnpj}</span>
                    )}
                    <span>
                      {proj?.dealer?.name} - {proj?.dealer?.state}
                    </span>

                    <div className="div__options">
                      {!proj?.finished_at && (
                        <Tooltip
                          placement="bottom-start"
                          title="Confirmar edição"
                        >
                          <Badge
                            overlap="circular"
                            badgeContent={
                              proj?.change_requests?.filter(
                                (change) => change.resolved === false,
                              ).length
                            }
                            // showZero
                            style={{ marginTop: '-0.4rem' }}
                            color="secondary"
                          >
                            <button
                              onClick={() =>
                                setViewedit({
                                  status: true,
                                  dados: proj.change_requests,
                                  dados_proj: proj,
                                })
                              }
                            >
                              <AssignmentTurnedInIcon />
                            </button>
                          </Badge>
                        </Tooltip>
                      )}
                      {!proj?.finished_at && !proj.suspended && (
                        <Tooltip
                          placement="bottom-start"
                          title="Adicionar etapa ao projeto"
                        >
                          <button
                            onClick={() =>
                              setSolicitarmud({ status: true, dados: proj })
                            }
                          >
                            <NotesIcon />
                          </button>
                        </Tooltip>
                      )}
                      <Tooltip
                        placement="bottom-start"
                        title="Visualizar informações de projeto"
                      >
                        <Badge
                          overlap="circular"
                          badgeContent={
                            proj?.pendencies?.filter(
                              (change) =>
                                change.customer_comment &&
                                change.resolved === false,
                            ).length
                          }
                          // showZero
                          style={{ marginTop: '-0.4rem' }}
                          color="secondary"
                        >
                          <button
                            onClick={() =>
                              setViewproject({ status: true, dados: proj })
                            }
                          >
                            <VisibilityOutlinedIcon />
                          </button>
                        </Badge>
                      </Tooltip>
                      {!proj?.finished_at && proj.suspended && (
                        <Tooltip
                          placeholder="bottom-start"
                          title="Retomar projeto"
                        >
                          <button
                            onClick={() =>
                              setShowconf({
                                ...showconf,
                                status: true,
                                width: 'sm',
                                title: 'Confirmar',
                                description: `Deseja realmente retomar o projeto de ${proj.client_name}?`,
                                btn_conf: 'Sim',
                                btn_cancel: 'Não',
                                delete: false,
                                dados: proj,
                                type: 'PATCH_PROJECTS_BY_ID',
                              })
                            }
                          >
                            <PlayArrowIcon />
                          </button>
                        </Tooltip>
                      )}
                      {!proj?.finished_at && !proj.suspended && (
                        <Tooltip
                          placement="bottom-start"
                          title="Suspender projeto"
                        >
                          <button
                            className="button_delete"
                            onClick={() =>
                              setShowconf({
                                ...showconf,
                                status: true,
                                width: 'sm',
                                title: 'Confirmar',
                                description: `Deseja realmente suspender o projeto de ${proj.client_name}?`,
                                btn_conf: 'Sim',
                                btn_cancel: 'Não',
                                delete: false,
                                dados: proj,
                                type: 'PATCH_PROJECTS_BY_ID',
                              })
                            }
                          >
                            <StopIcon />
                          </button>
                        </Tooltip>
                      )}

                      {!proj?.finished_at && !proj.suspended && (
                        <Tooltip
                          placement="bottom-start"
                          title={
                            proj.receipts.sort(finterRecentReceipt)[0]?.is_paid
                              ? 'Cancelar pagamento'
                              : 'Confirmar pagamento'
                          }
                        >
                          <button
                            className={
                              proj.receipts.sort(finterRecentReceipt)[0]
                                ?.is_paid
                                ? 'button_payment button_cancel_payment'
                                : 'button_payment'
                            }
                            onClick={() =>
                              proj.receipts.sort(finterRecentReceipt)[0]
                                ?.is_paid
                                ? setShowconf({
                                    ...showconf,
                                    status: true,
                                    width: 'sm',
                                    title: 'Confirmar',
                                    description: `Deseja realmente cancelar o pagamento do projeto de ${proj.client_name}?`,
                                    btn_conf: 'Sim',
                                    btn_cancel: 'Não',
                                    delete: true,
                                    dados: proj,
                                    type: 'SET_CANCEL_PAYMENT_ID',
                                  })
                                : setShowconf({
                                    ...showconf,
                                    status: true,
                                    width: 'sm',
                                    title: 'Confirmar',
                                    description: `Deseja realmente confirmar o pagamento do projeto de ${proj.client_name}?`,
                                    btn_conf: 'Sim',
                                    btn_cancel: 'Não',
                                    delete: false,
                                    dados: proj,
                                    type: 'SET_PAYMENT_ID',
                                  })
                            }
                          >
                            <AttachMoneyIcon />
                            {proj.receipts.sort(finterRecentReceipt)[0]?.is_paid
                              ? 'Cancelar pagamento'
                              : 'Confirmar pagamento'}
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="div__total_project_power">
                    <h4>{proj.total_project_power}kWp</h4>
                    <div className="div__sub_total_project_power">
                      <span>Início:</span>{' '}
                      <p style={{ margin: '0rem' }}>
                        {new Date(proj.created_at).toLocaleDateString('pt-BR')}
                      </p>
                    </div>
                    <div className="div__sub_total_project_power">
                      {!proj?.finished_at ? (
                        <span>Previsão de Conclusão:</span>
                      ) : (
                        <span>Finalizado em:</span>
                      )}{' '}
                      {!proj.suspended ? (
                        proj.deadline || proj.finished_at ? (
                          <p style={{ margin: '0rem' }}>
                            {new Date(
                              proj.finished_at
                                ? proj.finished_at
                                : proj.deadline,
                            ).toLocaleDateString('pt-BR')}
                          </p>
                        ) : (
                          <p>--/--/----</p>
                        )
                      ) : (
                        <p>--/--/----</p>
                      )}
                    </div>
                    <div className="div__options">
                      {!proj?.suspended && (
                        <Tooltip
                          placement="bottom-start"
                          title={
                            !proj.finished_at
                              ? 'Finalizar projeto'
                              : 'Reativar projeto'
                          }
                        >
                          <button
                            className={
                              !proj.finished_at
                                ? 'button_project button_cancel_project'
                                : 'button_project'
                            }
                            onClick={() =>
                              !proj.finished_at
                                ? setShowconf({
                                    ...showconf,
                                    status: true,
                                    width: 'sm',
                                    title: 'Confirmar',
                                    description: `Deseja realmente finalizar o projeto de ${proj.client_name}?`,
                                    btn_conf: 'Sim',
                                    btn_cancel: 'Não',
                                    delete: true,
                                    dados: proj,
                                    type: 'SET_FINISH_PROJECT',
                                  })
                                : setShowconf({
                                    ...showconf,
                                    status: true,
                                    width: 'sm',
                                    title: 'Confirmar',
                                    description: `Deseja realmente reativar o projeto de ${proj.client_name}?`,
                                    btn_conf: 'Sim',
                                    btn_cancel: 'Não',
                                    delete: false,
                                    dados: proj,
                                    type: 'SET_OPEN_PROJECT',
                                  })
                            }
                          >
                            <AssignmentOutlinedIcon />{' '}
                            {!proj.finished_at ? 'Finalizar' : ' Reabrir'}
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  <div className="div__status">
                    <div className="barra_progresso">
                      <span />{' '}
                      <div>
                        <h4>Status do Projeto</h4>
                      </div>
                    </div>
                    <div className="barra_progresso">
                      <span>
                        PROJETO (
                        {statusProgress(
                          proj?.stages?.sort(maisatual)[0]?.title,
                        )}
                        %)
                      </span>
                      <div className="div__status_project" />
                    </div>

                    {proj.suspended ||
                    proj.finished_at ||
                    proj.stages.length === 0 ? (
                      <div className="barra_progresso">
                        <span style={{ marginTop: '1rem' }}>Situação</span>
                      </div>
                    ) : (
                      <div className="barra_progresso">
                        {proj?.stages && (
                          <span style={{ marginTop: '1rem' }}>
                            {proj?.stages.sort(maisatual)[0].title} <br />
                            (há{' '}
                            {differenceInDays(
                              new Date(),
                              new Date(
                                proj?.stages.sort(maisatual)[0]?.created_at,
                              ),
                            )}{' '}
                            dias)
                            <br />
                            Estimativa:{' '}
                            {differenceInDays(
                              new Date(
                                proj?.stages.sort(maisatual)[0]?.deadline,
                              ),
                              new Date(
                                proj?.stages.sort(maisatual)[0]?.created_at,
                              ),
                            )}{' '}
                            dias
                            {}
                          </span>
                        )}
                        <div className="div__status_process" />
                      </div>
                    )}

                    <div className="barra_progresso">
                      <span />
                      {proj?.finished_at ? (
                        <div className="div__description">
                          {proj?.stages.sort(maisatual)[0]?.title ===
                          '6 - Projeto Aprovado'
                            ? 'PROJETO APROVADO'
                            : proj?.stages.sort(maisatual)[0]?.title ===
                              '7 - Projeto Reprovado'
                            ? 'PROJETO REPROVADO'
                            : 'PROJETO FINALIZADO'}
                        </div>
                      ) : proj.suspended ? (
                        <div className="div__description">PROJETO SUSPENSO</div>
                      ) : !proj.deadline || proj.stages.length === 0 ? (
                        <div className="div__description">
                          PROJETO AGUARDANDO APROVAÇÃO
                        </div>
                      ) : (
                        <div className="div__description">
                          DESCRIÇÃO:{' '}
                          {
                            proj.stages
                              .sort(maisatual)[0]
                              ?.status.sort(maisatual)[0]?.description
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              ))}

            {listidx <
            filterProjects?.filter(
              (proj) => proj.deadline && proj.stages.length !== 0,
              // && proj.responsibles.length !== 0,
            ).length ? (
              <div
                className="div__viewMore"
                onClick={() => setListidx(listidx + 3)}
              >
                Ver mais
              </div>
            ) : filterProjects?.length === 0 && filter.type ? (
              <div style={{ margin: '2rem 1rem' }}>
                Nenhum projeto encontrado
              </div>
            ) : filterProjects?.length === 0 && !filter.type ? (
              <div style={{ margin: '2rem 1rem' }}>
                Nenhum projeto cadastrado
              </div>
            ) : (
              <div style={{ margin: '2rem 1rem' }}>Sem mais Projetos</div>
            )}
          </div>
        </content>
      </Page>

      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({
              status: false,
              width: 'sm',
              title: 'Confirmar',
              description: 'Deseja realmente suspender este projeto?',
              btn_conf: 'Sim',
              btn_cancel: 'Não',
              delete: true,
              dados: '',
            })
          }
          confirmar={() => {
            if (showconf.type === 'PATCH_PROJECTS_BY_ID') {
              if (showconf.dados.suspended) {
                PATCH_PROJECTS_BY_ID(
                  showconf.dados.id,
                  async () => {
                    await GET_PROJECTS_BY_COMPANY(
                      showconf.dados.company_id,
                      setListProjects,
                      setShowbackdrop,
                    );
                  },
                  setShowbackdrop,
                );
                toast.success(
                  `Projeto ${showconf.dados.client_name} retomado com sucesso`,
                );
              } else {
                PATCH_PROJECTS_BY_ID(
                  showconf.dados.id,
                  () => {
                    GET_PROJECTS_BY_COMPANY(
                      showconf.dados.company_id,
                      setListProjects,
                      setShowbackdrop,
                    );
                  },
                  setShowbackdrop,
                );
                toast.info(
                  `Projeto de ${showconf.dados.client_name} suspenso com sucesso`,
                );
              }
            } else if (showconf.type === 'SET_PAYMENT_ID') {
              SET_PAYMENT_ID(
                showconf.dados?.receipts.sort(finterRecentReceipt)[0]?.id,
                () => {
                  GET_PROJECTS_BY_COMPANY(id, setListProjects, setShowbackdrop);
                  setShowconf({
                    status: false,
                    width: 'sm',
                    title: 'Confirmar',
                    description: 'Deseja realmente suspender este projeto?',
                    btn_conf: 'Sim',
                    btn_cancel: 'Não',
                    delete: true,
                    dados: '',
                  });
                },
              );
            } else if (showconf.type === 'SET_CANCEL_PAYMENT_ID') {
              SET_CANCEL_PAYMENT_ID(
                showconf.dados?.receipts.sort(finterRecentReceipt)[0]?.id,
                () => {
                  GET_PROJECTS_BY_COMPANY(id, setListProjects, setShowbackdrop);
                  setShowconf({
                    status: false,
                    width: 'sm',
                    title: 'Confirmar',
                    description: 'Deseja realmente suspender este projeto?',
                    btn_conf: 'Sim',
                    btn_cancel: 'Não',
                    delete: true,
                    dados: '',
                  });
                },
              );
            } else if (showconf.type === 'SET_FINISH_PROJECT') {
              PATCH_FINISHED_PROJECT(
                showconf.dados.id,
                { finished_at: new Date().toISOString() },
                async () => {
                  await GET_PROJECTS_BY_COMPANY(
                    showconf.dados.company_id,
                    setListProjects,
                    setShowbackdrop,
                  );
                },
                setShowbackdrop,
              );
              toast.success('Projeto finalizado com sucesso!');
              setShowconf({
                status: false,
                width: 'sm',
                title: 'Confirmar',
                description: 'Deseja realmente suspender este projeto?',
                btn_conf: 'Sim',
                btn_cancel: 'Não',
                delete: true,
                dados: '',
                type: '',
              });
            } else if (showconf.type === 'SET_OPEN_PROJECT') {
              PATCH_FINISHED_PROJECT(
                showconf.dados.id,
                { finished_at: null },
                async () => {
                  await GET_PROJECTS_BY_COMPANY(
                    showconf.dados.company_id,
                    setListProjects,
                    setShowbackdrop,
                  );
                },
                setShowbackdrop,
              );
              toast.success('Projeto reaberto com sucesso!');
              setShowconf({
                status: false,
                width: 'sm',
                title: 'Confirmar',
                description: 'Deseja realmente suspender este projeto?',
                btn_conf: 'Sim',
                btn_cancel: 'Não',
                delete: true,
                dados: '',
                type: '',
              });
            }
          }}
        />
      )}

      {showconfdesvincular.status && (
        <Dialogoconfirmacao
          status={showconfdesvincular.status}
          width={showconfdesvincular.width}
          delete={showconfdesvincular.delete}
          description={showconfdesvincular.description}
          fechar={() =>
            setShowconfdesvincular({
              status: false,
              width: 'sm',
              title: 'Confirmar',
              description: 'Deseja realmente suspender este projeto?',
              btn_conf: 'Sim',
              btn_cancel: 'Não',
              delete: true,
              dados: '',
              type: 'DELETE_RESPONSIBLES',
            })
          }
          confirmar={async () => {
            await DELETE_RESPONSIBLES(
              showconfdesvincular.dados.responsibles[0].id,
              async () =>
                await GET_PROJECTS_BY_COMPANY(
                  showconfdesvincular.dados.company_id,
                  setListProjects,
                  setShowbackdrop,
                ),
              setShowbackdrop,
            );
          }}
        />
      )}

      {viewcadastrarsupervisor.status && (
        <Dialogoadicionarsupervisor
          dados={viewcadastrarsupervisor.dados}
          fechar={() =>
            setViewcadastrarsupervisor({ status: false, dados: '' })
          }
          atualizar={async () =>
            await GET_PROJECTS_BY_COMPANY(
              viewcadastrarsupervisor.dados.company_id,
              setListProjects,
              setShowbackdrop,
            )
          }
        />
      )}

      {viewedit.status && (
        <Dialogoverificaralteracao
          dados={viewedit.dados}
          dados_proj={viewedit.dados_proj}
          fechar={() =>
            setViewedit({ status: false, dados: '', dados_proj: '' })
          }
          edit={setViewproject}
          atualizar={async () =>
            await GET_PROJECTS_BY_COMPANY(
              viewedit.dados_proj.company_id,
              setListProjects,
              setShowbackdrop,
            )
          }
        />
      )}

      {viewproject.status && (
        <Dialogoverprojeto
          dados={viewproject.dados}
          dados_edit={viewproject?.dados_edit}
          fechar={() => setViewproject({ status: false, dados: '' })}
          atualizar={async () =>
            await GET_PROJECTS_BY_COMPANY(
              viewproject.dados.company_id,
              setListProjects,
            )
          }
        />
      )}

      {solicitarmud.status && (
        <Chatbox
          fechar={() => setSolicitarmud({ status: false, dados: '' })}
          dados={solicitarmud.dados}
          atualizar={async () =>
            await GET_PROJECTS_BY_COMPANY(
              solicitarmud.dados.company_id,
              setListProjects,
              setShowbackdrop,
            )
          }
        />
      )}

      {showbackdrop && <SimpleBackdrop />}
    </Container>
  );
}

export default Projetos;
